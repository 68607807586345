import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePods = () =>
  useUpdatedData({
    selector: selectors.settings.pods,
    action: actions.settings.pods.fetchData(),
  });

export default usePods;
