import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useSecurityPolicy = () => {
  const shieldSettings = useSelector(
    selectors.settings.securityPolicy.shieldSettings
  );

  const securityPolicyData = useUpdatedData({
    selector: selectors.settings.securityPolicy.securityPolicy,
    action: actions.settings.securityPolicy.fetchData(),
  });

  const locationSecurityAppliesToAllDevices = useSelector(
    selectors.settings.securityPolicy.locationSecurityAppliesToAllDevices
  );

  return {
    securityPolicy: securityPolicyData,
    shieldSettings,
    locationSecurityAppliesToAllDevices,
  };
};

export default useSecurityPolicy;
