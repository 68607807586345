import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './style.module.css';

import { memo } from 'react';

export type ContentLeftProps = {
  style?: React.CSSProperties;
  className?: string;
  verticalAlign?: string;
  children?: React.ReactNode;
};

export const ContentLeft: FunctionComponent<ContentLeftProps> = ({
  style,
  className,
  verticalAlign = 'center',
  children,
}) => {
  return (
    <div
      style={style}
      className={cn(
        styles.left,
        {
          [styles.verticalAlignCenter]:
            verticalAlign === 'center' || verticalAlign !== 'top',
          [styles.verticalAlignTop]: verticalAlign === 'top',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export type ContentMainProps = {
  className?: string;
  horizontalAlign?: string;
  textWrap?: boolean;
  children?: React.ReactNode;
};

export const ContentMain: FunctionComponent<ContentMainProps> = ({
  className,
  horizontalAlign = 'left',
  textWrap = false,
  children,
}) => {
  return (
    <div
      className={cn(
        styles.main,
        {
          [styles.horizontalAlignLeft]:
            horizontalAlign === 'left' || horizontalAlign !== 'top',
          [styles.horizontalAlignCenter]: horizontalAlign === 'center',
          [styles.whiteSpaceWrap]: textWrap,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export type ContentRightProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
};

export const ContentRight: FunctionComponent<ContentRightProps> = ({
  style,
  className,
  children,
}) => {
  return (
    <div style={style} className={cn(styles.right, className)}>
      {children}
    </div>
  );
};

export type InlineContentProps = {
  className?: string;
  noGap?: boolean;
  children?: React.ReactNode;
};

export const InlineContent: FunctionComponent<InlineContentProps> = ({
  className,
  noGap = false,
  children,
}) => {
  const classNames = cn(
    styles.inline,
    {
      [styles.noGap]: noGap,
    },
    className
  );
  return <div className={classNames}>{children}</div>;
};

export const HorizontalDivider: FunctionComponent = () => {
  return <div className={cn(styles.divider)}></div>;
};

export type ContentProps = {
  className?: string;
  left?: React.ReactNode;
  leftVerticalAlign?: string;
  leftFixedWidthPixels?: number;
  main: React.ReactNode;
  mainHorizontalAlign?: string;
  mainTextWrap?: boolean;
  right?: React.ReactNode;
  rightFixedWidthPixels?: number;
  rightClassName?: string;
  onClick?: React.MouseEventHandler;
};

export const ContentLayout: FunctionComponent<ContentProps> = ({
  className,
  left,
  leftVerticalAlign,
  leftFixedWidthPixels,
  main,
  mainHorizontalAlign,
  mainTextWrap = true,
  right,
  rightFixedWidthPixels,
  rightClassName,
  onClick,
}) => {
  return (
    <div
      className={cn(className, {
        [styles.content]: !!left,
        [styles.contentWithNoLeft]: !left,
        [styles.interactive]: onClick,
      })}
      onClick={onClick}
    >
      {left && (
        <ContentLeft
          verticalAlign={leftVerticalAlign}
          className={cn(styles.leftContent)}
          style={
            leftFixedWidthPixels
              ? { width: `${leftFixedWidthPixels}px` }
              : undefined
          }
        >
          {left && left}
        </ContentLeft>
      )}

      <div
        className={cn(styles.mainAndRightContent)}
      >
        <ContentMain
          horizontalAlign={mainHorizontalAlign}
          textWrap={mainTextWrap}
        >
          {main}
        </ContentMain>
        <ContentRight
          className={rightClassName}
          style={
            rightFixedWidthPixels
              ? { width: `${rightFixedWidthPixels}px` }
              : undefined
          }
        >
          {right && right}
        </ContentRight>
      </div>
    </div>
  );
};
export default memo(ContentLayout);
