import React, { FunctionComponent } from 'react';
import styles from './style.module.css';
import HeaderOnboarding from 'UI/Components/Headers/Header onboarding';
import { useTranslation } from 'react-i18next';
import StandardListItem from 'UI/Components/Lists/List standard';
import { IconNames } from 'UI/Elements/Icon';
import Button from 'UI/Elements/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Consts/routes';

import { memo } from 'react';

const SsidConfigurationInfo: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate(ROUTES.ssidConfiguration.secure);
  };

  return (
    <div className={styles.ssidConfigurationInfo}>
      <HeaderOnboarding
        label={t('ssidConfiguration.label')}
        subLabel={t('ssidConfiguration.subLabel')}
      />
      <div className={styles.lowerContainer}>
        <div className={styles.ssidInformation}>
          <StandardListItem
            className={styles.ssidListItem}
            L1Props={{ iconProps: { name: IconNames.Badge1 } }}
            L2Props={{
              label: t('ssidConfiguration.information.secureWifiZone'),
              paragraph: t('ssidConfiguration.information.secureWifiInfo'),
            }}
            ariaLabel={t('ssidConfiguration.information.secureWifiZone')}
          />
          <StandardListItem
            className={styles.ssidListItem}
            L1Props={{ iconProps: { name: IconNames.Badge2 } }}
            L2Props={{
              label: t('ssidConfiguration.information.employeeWifiZone'),
              paragraph: t('ssidConfiguration.information.employeeWifiInfo'),
            }}
            ariaLabel={t('ssidConfiguration.information.employeeWifiZone')}
          />
          <StandardListItem
            className={styles.ssidListItem}
            L1Props={{ iconProps: { name: IconNames.Badge3 } }}
            L2Props={{
              label: t('ssidConfiguration.information.guestWifiZone'),
              paragraph: t('ssidConfiguration.information.guestWifiInfo'),
            }}
            ariaLabel={t('ssidConfiguration.information.guestWifiZone')}
          />
        </div>
        <Button label={t('common.next')} onClick={handleNextClick} />
      </div>
    </div>
  );
};
export default memo(SsidConfigurationInfo);
