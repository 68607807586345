import React, { useEffect, memo, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { ROUTES } from 'Consts/routes';
import { PageLoading } from 'UI/Layout/Page';
import { setupStore, AppDispatch } from 'State/store';
import useAuth from 'State/hooks/useAuth';
import * as actions from 'State/actions';
import useLocations from 'State/hooks/useLocations';
import useQuery from 'Utils/hooks/useQueryParams';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useAuth0 } from '@auth0/auth0-react';
import { getPostAuthRoute } from 'Utils/postAuthRedirectUtils';
import { ErrorAlertModal } from 'UI/Elements/Modals/Alerts';
import AppContainer from './AppContainer';
import 'normalize.css';
import './i18n';
import './Styles/index.css';
import { routeConfig } from 'routeConfig';
import NotFound from 'Pages/Not found';

const LoginRoute = memo<{ children: JSX.Element }>(({ children }) => {
  const { isLoading, token } = useAuth();

  if (isLoading) return <PageLoading />;

  if (token) {
    const postAuthRoute = getPostAuthRoute();
    return <Navigate to={postAuthRoute} replace />;
  }

  return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
});
LoginRoute.displayName = 'LoginRoute';

const useTokenRefresh = (token: string | null) => {
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const checkAccessToken = async (previousToken: string) => {
      try {
        const newToken = 'Bearer ' + (await getAccessTokenSilently());
        if (previousToken && newToken !== previousToken) {
          dispatch(actions.auth.saveJustAuthToLocalStorage(newToken));
        }
        timeoutId = setTimeout(() => checkAccessToken(newToken), 60000);
      } catch (error) {
        console.error('Token refresh error:', error);
      }
    };

    if (token?.startsWith('Bearer')) {
      timeoutId = setTimeout(() => checkAccessToken(token), 5000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [token]);
};

const ProtectedRoute = memo<{ children: JSX.Element }>(({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { locations, availableLocationCount } = useLocations();
  const { isLoading, token, onAuthFailure } = useAuth();
  const query = useQuery();
  const location = useLocation();

  useTokenRefresh(token);

  // Handle debug mode
  useEffect(() => {
    const debugValue = query.get('debug');
    if (debugValue === 'true' || debugValue === 'false') {
      dispatch(actions.debug.setDebug(debugValue === 'true'));
    }
  }, [query]);

  // Handle auth failure
  useEffect(() => {
    if (locations.errorMessage && token) {
      onAuthFailure(token);
    }
  }, [locations.errorMessage, token, onAuthFailure]);

  // Handle no available locations
  useEffect(() => {
    if (
      !locations.isLoading &&
      Array.isArray(locations.data) &&
      availableLocationCount === 0
    ) {
      localStorage.setItem('onboardingAlert', 'noWorkPass');
      dispatch(actions.auth.logout());
    }
  }, [locations.isLoading, locations.data, availableLocationCount]);

  if (isLoading) return <PageLoading />;

  if (!token) {
    localStorage.setItem('redirectRoute', location.pathname);
    return <Navigate to={ROUTES.onboarding.index} replace />;
  }

  return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
});
ProtectedRoute.displayName = 'ProtectedRoute';

const initializeApp = () => {
  const store = setupStore();
  const uiVersion = `${process.env.REACT_APP_NPM_VERSION} (${(
    process.env.REACT_APP_SOURCE_VERSION ||
    process.env.REACT_APP_GIT_HASH ||
    ''
  ).substring(0, 7)})`;

  localStorage.setItem('settingsExpanded', 'false');
  console.log(`UI Version: ${uiVersion}`);

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  return { store, root };
};

const App = memo(() => (
  <Provider store={store}>
    <SingletonHooksContainer />
    <AppContainer>
      <BrowserRouter>
        <Routes>
          {routeConfig.map(
            ({ path, component: Component, protected: isProtected, props }) => (
              <Route
                key={path}
                path={path}
                element={
                  isProtected ? (
                    <ProtectedRoute>
                      <Component {...props} />
                    </ProtectedRoute>
                  ) : (
                    <LoginRoute>
                      <Component {...props} />
                    </LoginRoute>
                  )
                }
              />
            )
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ErrorAlertModal />
      </BrowserRouter>
    </AppContainer>
  </Provider>
));
App.displayName = 'App';

// Initialize and render app
const { store, root } = initializeApp();
root.render(<App />);

// PWA configuration
serviceWorkerRegistration.unregister();

export default memo(App);
