import { Maybe } from 'Consts/types';
import { PARTNERLOGOS } from './partnerLogos';

export const getPartnerLogoFileName = (
  partnerId: Maybe<string>,
  logos = PARTNERLOGOS
): string | undefined => {
  if (!partnerId) {
    return undefined; // normal
  }
  const found = logos.topbarLogo[partnerId];

  if (found) {
    return found;
  }

  return undefined;
};
