import { DataDailyToday } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<DataDailyToday>('employeeDailyDataUsage');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const employeeDailyDataUsage = getState().dataUsage.employee.daily;

    if (
      employeeDailyDataUsage.isLoading ||
      !customerId ||
      !token ||
      !activeLocation?.id
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } =
      await api.getEmployeeNetworkDailyDataUsageCategories({
        customerId,
        locationId: activeLocation.id,
        token,
        cloud,
      });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching data usage'));

      return;
    }

    dispatch(actions.set(data.dailyToday));
  };
};

export const selectors = (state: RootState) => state.dataUsage.employee.daily;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
