import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from '../utils';
import { PubnubConfiguration } from 'Consts/types';

const slice = createStandardSlice<PubnubConfiguration>('pubNub');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const pubNubData = getState().pubNub;

    if (pubNubData.isLoading || !customerId || !token || !activeLocation?.id) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getPubnubSubscribe({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
      pubnubChannels: [activeLocation.id + '.topologyAlert'],
    });

    if (error || !data) {
      dispatch(
        actions.error(
          error?.message || 'Error fetching PubNub notifications data'
        )
      );

      return;
    }

    dispatch(actions.set({ locationId: activeLocation.id, pubNub: data }));
  };
};

export const selectors = (state: RootState) => state.pubNub;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
