import React, { FunctionComponent } from 'react';

import styles from './style.module.css';

import { memo } from 'react';

const Loader: FunctionComponent = () => {
  return <div className={styles.loader} />;
};
export default memo(Loader);
