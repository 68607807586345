import cn from 'classnames';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'Consts/routes';

import TopBar from 'UI/Layout/Top bar';

import Loader from 'UI/Elements/Loader';
import { MiniBannerWrapper } from 'UI/Elements/Mini banner';

import styles from './style.module.css';
import { LocationId, Nullable } from '../../../Consts/types';
import { isMobileIos } from 'Utils/isMobileIos';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import RestrictedNavPanel from '../RestrictedNavPanel/RestrictedNavPanel';
import RestrictedMobileNavPanel from '../RestrictedMobileNavPanel/RestrictedMobileNavPanel';

import { memo } from 'react';

type RestrictedViewPageProps = {
  noHorizontalScroll?: boolean;
  noVerticalScroll?: boolean;
} & JSX.IntrinsicElements['div'];

const RestrictedViewPage: FunctionComponent<
  PropsWithChildren<RestrictedViewPageProps>
> = ({
  noHorizontalScroll = false,
  noVerticalScroll = false,
  className,
  children,
}) => {
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const pageWrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  let currentRoute = routerLocation.pathname;
  const [configViewActive, setConfigViewActive] = useState(
    currentRoute === ROUTES.ssidConfiguration.configuration
  );

  useEffect(() => {
    setConfigViewActive(
      [
        ROUTES.ssidConfiguration.configuration,
        ROUTES.ssidConfiguration.secure,
        ROUTES.ssidConfiguration.employee,
        ROUTES.ssidConfiguration.guest,
      ].includes(currentRoute)
    );
  }, []);

  const handleNavigate = (route: string) => {
    setConfigViewActive(
      ![
        ROUTES.ssidConfiguration.pods,
        ROUTES.ssidConfiguration.support,
        ROUTES.ssidConfiguration.account,
      ].includes(route)
    );

    navigate(route);
  };

  const handleHomeNavigate = () => {
    setSettingsExpanded(false);
    setConfigViewActive(true);
    navigate(ROUTES.ssidConfiguration.configuration);
  };

  const handleSwitchToLocation = useCallback(
    (locationId: LocationId) => {
      navigate(ROUTES.home.index);
      currentRoute = '/';
      dispatch(actions.locations.switchLocation({ locationId }));
    },
    [dispatch]
  );

  return (
    <div
      className={cn(styles.page, className, {
        [styles.pageMobileIos]: isMobileIos(),
      })}
    >
      <TopBar showSearchAndNotifications={false} />

      <div ref={pageWrapperRef} className={styles.pageWrapper}>
        <RestrictedNavPanel
          onHomeNavigate={handleHomeNavigate}
          settingsExpanded={settingsExpanded}
          onSettingsExpand={setSettingsExpanded}
          onNavigate={handleNavigate}
          onSwitchLocation={handleSwitchToLocation}
        />
        <div
          role="main"
          className={cn(styles.pageContent, styles.rightWrapper, {
            [styles.pageContentNoHorizontalScroll]: noHorizontalScroll,
            [styles.pageContentNoVerticalScroll]: noVerticalScroll,
            [styles.rightWrapper]: !configViewActive,
            [styles.configWrapper]: configViewActive,
          })}
        >
          {children}
        </div>

        <MiniBannerWrapper />
      </div>

      <RestrictedMobileNavPanel
        currentRoute={currentRoute}
        onHomeNavigate={handleHomeNavigate}
        onNavigate={handleNavigate}
      />
    </div>
  );
};

export const RestrictedViewPageLoading: FunctionComponent = () => (
  <RestrictedViewPage>
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  </RestrictedViewPage>
);
export default memo(RestrictedViewPage);
