import * as api from 'Api/endpoints';
import { DEFAULTCUSTOMERSUPPORTCONFIGURATIONS } from 'Consts/data examples/customerSupportConfigurations';
import { CustomerSupportConfiguration } from 'Consts/types';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from '../utils';

const slice = createStandardSlice<CustomerSupportConfiguration>(
  'customerSupportConfigurations'
);

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;

    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const customerSupportData = getState().customerSupportConfigurations;

    if (
      customerSupportData.isLoading ||
      !customerId ||
      !token ||
      !activeLocation?.id
    ) {
      return;
    }

    dispatch(actions.isLoading(true));
    const { data, error } = await api.getCustomerSupportConfigurations({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(
          error?.message || 'Error fetching customerSupportConfiguration data'
        )
      );
      dispatch(actions.set(DEFAULTCUSTOMERSUPPORTCONFIGURATIONS));
      return;
    }

    dispatch(actions.set(data));
  };
};

export const selectors = (state: RootState) =>
  state.customerSupportConfigurations;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
