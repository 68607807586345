import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployees = () =>
  useUpdatedData({
    selector: selectors.employees,
    action: actions.employees.fetchData(),
  });

export default useEmployees;
