import { createSlice } from '@reduxjs/toolkit';

import { UIVersion } from 'Consts/types';

import { Data, updateData } from 'State/utils';

import { RootState } from 'State/store';

export type BuildState = {
  data: Data<string>;
  uiVersion: UIVersion;
};

const initialState: BuildState = {
  data: updateData(null),
  uiVersion: (process.env.REACT_APP_NPM_VERSION +
    ` (` +
    (
      process.env.REACT_APP_GIT_HASH ||
      process.env.REACT_APP_SOURCE_VERSION ||
      ''
    ).substring(0, 7) +
    `)`) as UIVersion,
};

const slice = createSlice({
  name: 'build',
  initialState,
  reducers: {},
});

export const selectors = {
  uiVersion: (state: RootState) => state.build.uiVersion,
};

export const actions = slice.actions;

export default slice.reducer;
