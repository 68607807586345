import React, { FunctionComponent, memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import type { LocationId } from 'Consts/types';
import { ROUTES } from 'Consts/routes';

import { IconNames } from 'UI/Elements/Icon';

import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import useLocations from 'State/hooks/useLocations';

import MobileNavPanelUI from './Components/Mobile';
import DesktopNavPanelUI from './Components/Desktop';

export const getIconFromRoute = (route: string, selected?: boolean) => {
  const map = {
    [ROUTES.home.index]: IconNames.Home,
    [ROUTES.ssidConfiguration.configuration]: IconNames.Home,
    [ROUTES.zones.secure.index]: IconNames.Devices,
    [ROUTES.zones.employee.index]: IconNames.Devices,
    [ROUTES.zones.guest.index]: IconNames.Devices,
    [ROUTES.shield]: IconNames.Shield,
    [ROUTES.network]: IconNames.Topology,
    [ROUTES.settings.secure]: IconNames.Settings,
    [ROUTES.ssidConfiguration.pods]: IconNames.Settings,
    [ROUTES.ssidConfiguration.support]: IconNames.Settings,
    [ROUTES.ssidConfiguration.account]: IconNames.Settings,
    [ROUTES.insurance.index]: IconNames.Guard,
  };

  const selectedMap = {
    [ROUTES.home.index]: IconNames.HomeFill,
    [ROUTES.ssidConfiguration.configuration]: IconNames.HomeFill,
    [ROUTES.zones.secure.index]: IconNames.DevicesFill,
    [ROUTES.zones.employee.index]: IconNames.DevicesFill,
    [ROUTES.zones.guest.index]: IconNames.DevicesFill,
    [ROUTES.shield]: IconNames.ShieldFill,
    [ROUTES.network]: IconNames.Topology,
    [ROUTES.settings.secure]: IconNames.SettingsFill,
    [ROUTES.ssidConfiguration.pods]: IconNames.Settings,
    [ROUTES.ssidConfiguration.support]: IconNames.Settings,
    [ROUTES.ssidConfiguration.account]: IconNames.Settings,
    [ROUTES.insurance.index]: IconNames.Guard,
  };

  return selected ? selectedMap[route] : map[route];
};

export type NavPanelItemProps = {
  currentRoute: string;
  route: string;
  additionalRoutesForSelection?: string[];
  onNavigate: (route: string) => void;
};

export type Props = {
  className?: string;
  currentRoute: string;
  onNavigate: (route: string) => void;
};

type MobileNavPanelProps = {
  onNavigate: () => void;
};

export const MobileNavPanel: FunctionComponent<MobileNavPanelProps> = ({
  onNavigate,
}) => {
  const routerLocation = useLocation();
  const navigate = useNavigate();

  const currentRoute = routerLocation.pathname;

  const handleNavigation = useCallback(
    (route: string) => {
      navigate(route);

      if (onNavigate) {
        onNavigate();
      }
    },
    [navigate, onNavigate]
  );

  return (
    <MobileNavPanelUI
      currentRoute={currentRoute}
      onNavigate={handleNavigation}
    />
  );
};

export const DesktopNavPanel = memo(
  ({
    className,
    onNavigate,
  }: {
    className?: string;
    onNavigate: () => void;
  }) => {
    const dispatch = useDispatch<AppDispatch>();
    const routerLocation = useLocation();
    const navigate = useNavigate();

    const { activeLocation } = useLocations();

    const locationsForMenu = useSelector(selectors.locations.mergedLocations);
    const navPanelHidden = useSelector(selectors.ui.page.navPanelHidden);

    let currentRoute = routerLocation.pathname;

    const handleNavigation = useCallback(
      (route: string) => {
        navigate(route);

        if (onNavigate) {
          onNavigate();
        }
      },
      [navigate, onNavigate]
    );

    const handleSwitchToLocation = useCallback(
      (locationId: LocationId) => {
        navigate(ROUTES.home.index);
        currentRoute = '/';
        dispatch(actions.locations.switchLocation({ locationId }));
      },
      [dispatch]
    );

    return (
      <DesktopNavPanelUI
        isOpen={!navPanelHidden}
        className={className}
        currentRoute={currentRoute}
        onNavigate={handleNavigation}
        currentLocation={activeLocation.data}
        locations={locationsForMenu}
        onSwitchToLocation={handleSwitchToLocation}
      />
    );
  }
);
