import * as api from 'Api/endpoints';
import { NETWORK_IDS } from 'Consts/defintions';
import { NetworkAccessData } from 'Consts/types';

import type { RootState, Action } from 'State/store';

import { createStandardSlice } from 'State/utils';

type State = {
  default: NetworkAccessData;
  employee: NetworkAccessData;
};

const slice = createStandardSlice<State>('networkAccess');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    const networkAccessData = getState().settings.networkAccess;

    if (
      networkAccessData.isLoading ||
      !customerId ||
      !activeLocation?.id ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getNetworkAccesses({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message ?? 'Error fetching network access data')
      );

      return;
    }

    const secure = data.find((el) => el.networkId === NETWORK_IDS.default);
    const employee = data.find((el) => el.networkId === NETWORK_IDS.employee);

    if (!secure || !employee) {
      dispatch(actions.error('Error fetching network access data'));

      return;
    }

    dispatch(actions.set({ default: secure, employee }));
  };
};

const update = ({
  networkId,
  purgatory,
}: {
  purgatory: boolean;
  networkId: 'default' | 'employee';
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const networkAccessData = getState().settings.networkAccess;

    if (!customerId || !activeLocationId || !token || !networkAccessData.data) {
      return;
    }

    dispatch(actions.isLoading(true));

    const apiArgs = {
      customerId,
      locationId: activeLocationId,
      networkId,
      purgatory,
      token,
      cloud,
    };

    const { data, error } = await api.updateNetworkAccesses(apiArgs);

    if (error || !data) {
      dispatch(
        actions.error(error?.message ?? 'Error updating network access data')
      );

      return;
    }

    dispatch(
      actions.set({
        ...networkAccessData.data,
        [networkId]: {
          ...networkAccessData.data[networkId],
          purgatory: data.purgatory,
        },
      })
    );
  };
};

export const selectors = (state: RootState) => state.settings.networkAccess;

export const actions = {
  ...slice.actions,
  fetchData,
  update,
};

export default slice.reducer;
