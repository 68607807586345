import * as api from 'Api/endpoints';
import { createStandardSlice } from 'State/utils';
import type { RootState, Action } from 'State/store';

type MagicLinkData = { token: string; customerId: string };

const slice = createStandardSlice<MagicLinkData>('magicLink');

const sendMagicLink = (email: string): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.clear());
    dispatch(actions.isLoading(true));

    const { error: checkError } = await api.checkEmailExists({
      email,
      cloud,
    });

    if (checkError) {
      dispatch(actions.error(checkError?.message));
      return;
    }

    const { data, error } = await api.loginWithMagicLink({ email, cloud });

    if (error || !data) {
      dispatch(
        actions.error(
          error?.message || 'Something went wrong. Please try again later.'
        )
      );

      return;
    }

    const { id: token, userId: customerId } = data;

    dispatch(actions.set({ token, customerId }));
  };
};

export const selectors = (state: RootState) => state.magicLink;

export const actions = {
  sendMagicLink,
  ...slice.actions,
};

export default slice.reducer;
