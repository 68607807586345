import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export const useOnWindowResize = (cb: () => void) => {
  useEffect(() => {
    const debouncedCb = debounce(cb, 200);

    window.addEventListener('resize', debouncedCb);

    return () => window.removeEventListener('resize', debouncedCb);
  }, [cb]);
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useOnWindowResize(() => {
    setWindowDimensions(getWindowDimensions());
  });

  return windowDimensions;
};

export default useWindowDimensions;
