import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useConditionalUpdate from './utils/useConditionalUpdate';

const useCustomer = () => {
  const customer = useSelector(selectors.customer.data);

  useConditionalUpdate({
    data: customer,
    dependencies: [],
    action: actions.customer.fetchData(),
  });

  return customer;
};

export default useCustomer;
