export const onEnterKeydown = (
  e: React.KeyboardEvent,
  onClick?: (e: any) => void
) => {
  const enterOrSpace =
    e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar';

  if (enterOrSpace) {
    e.preventDefault();
    if (onClick) onClick(e);
  }
};

export const onEscapeKeyDown = (
  e: KeyboardEvent | React.KeyboardEvent,
  onClose?: () => void
) => {
  if (e.key === 'Escape' && onClose) {
    e.preventDefault();
    onClose();
  }
};
