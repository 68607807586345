import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  FOUR_COLUMNS_MIN_WIDTH,
  THREE_COLUMNS_MIN_WIDTH,
  TWO_COLUMNS_MIN_WIDTH,
} from 'Consts/config';

import * as selectors from 'State/selectors';

import { useOnWindowResize } from 'Utils/hooks/useWindowSize';

type AvailableColumns = 1 | 2 | 3;
type UseLayoutColumnsReturn<R> = [AvailableColumns, RefObject<R>];

const useLayoutColumns = <
  R extends HTMLDivElement
>(): UseLayoutColumnsReturn<R> => {
  const ref = useRef<R>(null);
  const navPanelHidden = useSelector(selectors.ui.page.navPanelHidden);
  const sidePanelOpen = useSelector(selectors.ui.page.sidepanelOpen);
  const [columns, setColumns] = useState<1 | 2 | 3>(1);

  const checkColumns = useCallback(() => {
    const width = ref.current?.clientWidth;

    if (width) {
      if (width >= FOUR_COLUMNS_MIN_WIDTH) {
        setColumns(3);
        return;
      } else if (width >= THREE_COLUMNS_MIN_WIDTH) {
        const targetNumberOfColumns = sidePanelOpen ? 2 : 3;
        setColumns(targetNumberOfColumns);
        return;
      } else if (width >= TWO_COLUMNS_MIN_WIDTH) {
        setColumns(2);
        return;
      }
    }

    setColumns(1);
  }, [ref.current?.clientWidth, sidePanelOpen]);

  useOnWindowResize(checkColumns);
  useEffect(checkColumns, [
    ref.current,
    checkColumns,
    navPanelHidden,
    sidePanelOpen,
  ]);

  return [columns, ref];
};

export default useLayoutColumns;
