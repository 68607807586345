import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Customer, Location, LocationId } from 'Consts/types';

import Alert from 'UI/Components/Alert';
import SettingsSectionHeader from 'UI/Components/Headers/SettingsSectionHeader';
import CenterListItem from 'UI/Components/Lists/List center';
import StandardListItem from 'UI/Components/Lists/List standard';
import Menu, { MenuItemProps } from 'UI/Components/Menu';

import { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';

import * as actions from 'State/actions';
import useCustomer from 'State/hooks/useCustomer';
import useLocations from 'State/hooks/useLocations';
import { AppDispatch } from 'State/store';

import { useAuth0 } from '@auth0/auth0-react';
import { ROUTES } from 'Consts/routes';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { useNavigate } from 'react-router-dom';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import { useTrackEvent } from '../../../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../../../trackingAnalytics/mixPanelEvents';
import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

import { memo } from 'react';

type GetMenuItemsParams = {
  currentLocation: Location | null;
  onOpenRenameAlert: React.MouseEventHandler;
  onSwitchToLocation: (locationId: LocationId) => void;
  t: TFunction<'translation', undefined>;
};

const getMenuItems = ({
  currentLocation,
  onOpenRenameAlert,
  onSwitchToLocation,
  t,
}: GetMenuItemsParams) => {
  const items: MenuItemProps[] = [];

  if (currentLocation) {
    items.push({
      label:
        t('settings.switchTo', { name: currentLocation.name }) ||
        `Switch to ${currentLocation.name}`,
      iconName: IconNames.Map,
      onClick: () => onSwitchToLocation(currentLocation.id),
    });
  }

  items.push({
    label: t('settings.renameLocation'),
    iconName: IconNames.Edit,
    onClick: onOpenRenameAlert,
  });

  return items;
};

type AccountUIProps = {
  isLoading?: boolean;
  errorMessage?: string;
  customer: Customer | null;
  activeLocation: Location | null;
  otherLocations: Location[];
  onLogoutClick: React.MouseEventHandler;
  onRenameLocation: (location: Location, name: string) => void;
  onCreateLocation: (name: string) => void;
  onSwitchToLocation: (locationId: LocationId) => void;
};

const AccountUI: FunctionComponent<AccountUIProps> = ({
  isLoading,
  errorMessage: apiErrorMessage,
  customer,
  activeLocation,
  otherLocations,
  onLogoutClick,
  onRenameLocation,
  onCreateLocation,
  onSwitchToLocation,
}) => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const namespace = useCspTranslationNamespace();

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();

  const [openAlert, setOpenAlert] = useState<
    'signout' | 'rename' | 'create' | null
  >(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [menuLocation, setMenuLocation] = useState<Location | null>(null);
  const [newLocationName, setNewLocationName] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const parentRef = useRef<Element | null>(null);

  const resetTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const viewAsCoAdmin = activeLocation?.accessedAsCoAdminLocation === true;
  const environment = getConfigurationFromDomain();

  const resetState = useCallback(() => {
    if (resetTimeoutId.current) {
      return;
    }

    resetTimeoutId.current = setTimeout(() => {
      if (
        !setNewLocationName ||
        !setMenuLocation ||
        !resetTimeoutId ||
        openAlert ||
        isMenuOpen
      ) {
        return;
      }

      setNewLocationName('');
      setMenuLocation(null);

      resetTimeoutId.current = null;
    }, 200);
  }, [openAlert, isMenuOpen]);

  useEffect(() => {
    if (!openAlert && !isMenuOpen) {
      resetState();
    }
  }, [resetState, openAlert, isMenuOpen]);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(null);
  }, []);

  const handleOpenSignoutAlert = useCallback(() => setOpenAlert('signout'), []);
  const handleOpenRenameAlert = useCallback(() => setOpenAlert('rename'), []);
  const handleOpenCreateAlert = useCallback(() => setOpenAlert('create'), []);

  const handleMenuLocationChange = useCallback((location: Location) => {
    setNewLocationName(location.name);
    setMenuLocation(location);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleOpenMenu = useCallback(
    (ev: React.MouseEvent, location: Location) => {
      handleTriggerEvent(ev);

      parentRef.current = ev.currentTarget;

      handleMenuLocationChange(location);
      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    },
    [handleMenuLocationChange]
  );

  const handleRenameLocation = useCallback(() => {
    if (!menuLocation) {
      return;
    }

    if (!newLocationName) {
      setErrorMessage(
        t('settings.validationNotEmpty', { field: t('settings.name') }) ||
          "Name can't be blank."
      );

      return;
    }

    onRenameLocation(menuLocation, newLocationName);
    handleCloseAlert();
  }, [handleCloseAlert, menuLocation, newLocationName, onRenameLocation, t]);

  const handleCreateLocation = useCallback(() => {
    if (!newLocationName) {
      setErrorMessage(
        t('settings.validationNotEmpty', { field: t('settings.name') }) ||
          "Name can't be blank."
      );

      return;
    }

    onCreateLocation(newLocationName);
    handleCloseAlert();
  }, [handleCloseAlert, newLocationName, onCreateLocation, t]);

  const menuItems = getMenuItems({
    currentLocation:
      menuLocation && menuLocation?.id !== activeLocation?.id
        ? menuLocation
        : null,
    onOpenRenameAlert: handleOpenRenameAlert,
    onSwitchToLocation,
    t,
  });

  useEffect(() => {
    setErrorMessage('');
  }, [newLocationName]);

  if (isLoading || apiErrorMessage) {
    return (
      <Card
        isLoading={isLoading}
        errorMessage={apiErrorMessage}
        noBottomPadding
      />
    );
  }

  return (
    <div
      role="region"
      aria-labelledby={t('settings.account')}
      className={styles.accountSettingsSection}
    >
      {activeLocation && (
        <Card noBottomPadding>
          <StandardListItem
            L1Props={{ iconProps: { name: IconNames.Map } }}
            L2Props={{
              label: activeLocation?.name,
              paragraph:
                t(
                  activeLocation?.summary?.gatewayConnectionState ===
                    'connected'
                    ? 'common.online'
                    : 'common.offline'
                ) || '',
            }}
            RProps={{
              icon1Props: {
                name: IconNames.OverflowVertical,
                tooltipLabel: t('common.moreActions'),
                ariaLabel: t('common.moreActions'),
                onClick: (ev) => handleOpenMenu(ev, activeLocation),
              },
            }}
            ariaLabel={
              t(
                activeLocation?.summary?.gatewayConnectionState === 'connected'
                  ? 'common.online'
                  : 'common.offline'
              ) || ''
            }
          />
        </Card>
      )}

      {!!otherLocations.length && (
        <SettingsSectionHeader
          LProps={{
            smallLabel: t('settings.switchLocation'),
          }}
        />
      )}

      {otherLocations?.map((location) => (
        <Card noBottomPadding key={location.id}>
          <StandardListItem
            L1Props={{ iconProps: { name: IconNames.Map } }}
            L2Props={{
              className: styles.l2,
              label: location?.name,
              paragraph:
                t(
                  location?.networkModeRealized
                    ? 'common.online'
                    : 'common.offline'
                ) || '',
            }}
            RProps={{
              icon1Props: {
                name: IconNames.OverflowVertical,
                tooltipLabel: t('common.moreActions'),
                ariaLabel: t('common.moreActions'),
                onClick: (ev) => handleOpenMenu(ev, location),
              },
            }}
            ariaLabel={
              t(
                location?.networkModeRealized
                  ? 'common.online'
                  : 'common.offline'
              ) || ''
            }
          />
        </Card>
      ))}

      {false &&
        !viewAsCoAdmin && ( // feature needs API support of full onboarding
          <Card noBottomPadding>
            <StandardListItem
              L1Props={{ iconProps: { name: IconNames.HomeAdd } }}
              L2Props={{
                label: t('settings.setupNewLocation'),
              }}
              onClick={handleOpenCreateAlert}
              ariaLabel={t('settings.setupNewLocation')}
            />
          </Card>
        )}

      {!viewAsCoAdmin &&
        customerSupportConfigurations?.customerFeatureEnablement
          ?.manageAccountEnabled && (
          <Card noBottomPadding>
            <StandardListItem
              L1Props={{ iconProps: { name: IconNames.Person } }}
              L2Props={{ label: t('settings.manageAccount') }}
              onClick={() =>
                window.open(
                  customerSupportConfigurations?.customerFeatureEnablement
                    ?.partnerAccountEnabled
                    ? customerSupportConfigurations?.customerConfigurations
                        ?.partnerAccountUrl
                    : customerSupportConfigurations?.customerConfigurations
                        ?.manageYourDataUrl ||
                        'https://preferences.plume.com/privacy'
                )
              }
              ariaLabel={t('settings.manageAccount')}
            />
          </Card>
        )}

      {customer && (
        <Card noBottomPadding>
          <CenterListItem
            label={t('settings.signOut', { ns: namespace })}
            paragraph={environment?.hideEmail ? undefined : customer.email}
            className={styles.logout}
            onClick={handleOpenSignoutAlert}
          />
        </Card>
      )}

      <AlertModal isOpen={openAlert === 'signout'} onClose={handleCloseAlert}>
        <Alert
          topProps={{
            label: t('settings.signingOut', { ns: namespace }),
            paragraph: t('settings.signingOutText', { ns: namespace }),
            className: styles.modalBody,
          }}
          bottomProps={{
            button1Props: {
              label: t('common.yes'),
              onClick: onLogoutClick,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: handleCloseAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>

      <AlertModal isOpen={openAlert === 'create'} onClose={handleCloseAlert}>
        <Alert
          topProps={{ label: t('settings.enterName') }}
          middleProps={{
            listInputProps: {
              value: newLocationName,
              onChange: setNewLocationName,
              hasError: !!errorMessage,
              paragraph: errorMessage,
              placeholder: t(
                'settings.locationAdmin.locationCreatePlaceholder'
              ),
              onSubmit: handleCreateLocation,
            },
          }}
          bottomProps={{
            button1Props: {
              label: t('common.save'),
              onClick: handleCreateLocation,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: handleCloseAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>

      <AlertModal isOpen={openAlert === 'rename'} onClose={handleCloseAlert}>
        <Alert
          topProps={{ label: t('settings.enterName') }}
          middleProps={{
            listInputProps: {
              value: newLocationName,
              onChange: setNewLocationName,
              hasError: !!errorMessage,
              paragraph: errorMessage,
              placeholder: t('settings.locationAdmin.locationEditPlaceholder'),
              onSubmit: handleRenameLocation,
            },
          }}
          bottomProps={{
            button1Props: {
              label: t('common.save'),
              onClick: handleRenameLocation,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: handleCloseAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>

      <Menu
        isOpen={isMenuOpen}
        parent={parentRef.current}
        items={menuItems}
        onClose={handleCloseMenu}
        openTriggerEventType={menuOpenTrigger}
      />
    </div>
  );
};

const Account = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const customerData = useCustomer();
  const { activeLocation, locations } = useLocations();
  const trackEvent = useTrackEvent();
  const { isAuthenticated, logout } = useAuth0();

  const otherLocations = useMemo(() => {
    return (
      locations.data?.filter((location) => {
        return location.id !== activeLocation.data?.id;
      }) || []
    );
  }, [locations.data, activeLocation.data?.id]);

  const handleSignOut = useCallback(() => {
    dispatch(actions.auth.logout());

    if (isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: window.location.origin + '/onboarding/global-auth',
        },
      });
    }

    trackEvent({
      eventName: MixPanelEvents.LOGOUT,
    });
  }, [dispatch]);

  const handleCreateLocation = useCallback(
    (name: string) => {
      dispatch(actions.locations.createLocation({ name }));
    },
    [dispatch]
  );

  const handleRenameLocation = useCallback(
    (location: Location, name: string) => {
      dispatch(
        actions.locations.renameLocation({ name, locationId: location.id })
      );
    },
    [dispatch]
  );

  const handleSwitchToLocation = useCallback(
    (locationId: LocationId) => {
      navigate(ROUTES.home.index);
      dispatch(actions.locations.switchLocation({ locationId }));
    },
    [dispatch]
  );

  return (
    <AccountUI
      isLoading={locations.isLoading || customerData.isLoading}
      errorMessage={locations.errorMessage || customerData.errorMessage}
      onLogoutClick={handleSignOut}
      onCreateLocation={handleCreateLocation}
      onRenameLocation={handleRenameLocation}
      onSwitchToLocation={handleSwitchToLocation}
      customer={customerData.data}
      activeLocation={activeLocation.data}
      otherLocations={otherLocations}
    />
  );
};
export default memo(Account);
