import { AutoCompleteFilter } from '../AutoComplete';
import { AutoCompleteResultsItem } from '../AutoCompleteResults';

export type FilteredListType = {
  id: string;
  label: string;
  type: string;
};

export const filteredList = (
  items?: AutoCompleteResultsItem[],
  availableFilters?: AutoCompleteFilter[]
) => {
  if (!availableFilters || !items) return;
  let results: FilteredListType[] = [];

  availableFilters.map((filter) => {
    return items.forEach((item) => {
      if (filter.name === item.type) {
        results.push({
          ...item,
          type: filter.name,
        });
      }
    });
  });

  return results;
};
