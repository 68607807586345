import { OnlineTimeAppLast12Months } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<OnlineTimeAppLast12Months>(
  'defaultLast12MonthsAppsOnlineTime'
);

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const defaultLast12MonthsOnlineTime =
      getState().appsOnlineTime.default.last12months;

    if (
      defaultLast12MonthsOnlineTime.isLoading ||
      !customerId ||
      !token ||
      !activeLocation?.id
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDefaultLast12MonthsAppsOnlineTime({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching Apps online time')
      );

      return;
    }

    dispatch(actions.set(data.last12Months));
  };
};

export const selectors = (state: RootState) =>
  state.appsOnlineTime.default.last12months;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
