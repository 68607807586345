import { CloudBackend } from 'Consts/types';

export type SubDomainId =
  | 'local'
  | 'web'
  | 'none'
  | 'lcpr'
  | 'cci'
  | 'testcsp'
  | 'cogeco'
  | 'tds'
  | 'flowbusiness'
  | 'bell'
  | 'gci';

export type authenticationMethods =
  | 'partnerSso'
  | 'magicLinkAndPassword'
  | 'bellSso'
  | 'globalAuth';

export type Domain = {
  id: SubDomainId;
  authenticationsMethodsPermitted: authenticationMethods[];
  partnerId?: string;
  cloudProduction?: CloudBackend;
  cloudDev?: CloudBackend;
  customLandingLogo?: boolean;
  hideEmail?: boolean;
  ssoAppId?: string;
};

const noDomain: Domain = {
  id: 'none',
  authenticationsMethodsPermitted: ['magicLinkAndPassword'],
};

const subDomains: Domain[] = [
  noDomain,
  {
    id: 'web',
    authenticationsMethodsPermitted: ['globalAuth'],
    hideEmail: false,
  },
  {
    id: 'lcpr',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '618001dee113cf2a52f84752',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'cci',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '5f6c9292a3747425ace8863c',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'testcsp',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: 'qa-sso-partner1',
    cloudProduction: 'DOGFOOD',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: false,
  },
  {
    id: 'cogeco',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '5ea1d947a02352248c4829e4',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'tds',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '6051060eeb01964ff68fc9b3',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'bell',
    authenticationsMethodsPermitted: ['bellSso'],
    cloudProduction: 'DELTA',
    cloudDev: 'ETNA',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'flowbusiness',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '6410c832735697000adc7d0b',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
  },
  {
    id: 'gci',
    authenticationsMethodsPermitted: ['partnerSso'],
    partnerId: '5dac87414940aa0579303925',
    cloudProduction: 'GAMMA',
    cloudDev: 'DOGFOOD',
    customLandingLogo: true,
    hideEmail: true,
    ssoAppId: 'workpass',
  },
];

export const isDevDomain = (): boolean => {
  if (
    window.location.hostname.includes('workpass.plume.com') ||
    window.location.hostname.includes('businesswifi.bell.ca')
  ) {
    return false;
  }
  return true;
};

export const isStoredCloudAllowed = (cloud: CloudBackend): boolean => {
  if (isDevDomain()) {
    return ['CI', 'DOGFOOD', 'THETADEV', 'OSYNC', 'ETNA', 'OPENSYNC'].includes(
      cloud
    );
  }

  return [
    'GAMMA',
    'BETA',
    'KAPPA',
    'THETA',
    'THETASTAGE',
    'DELTA',
    'PHI',
    'IOTA',
  ].includes(cloud);
};

export const isPureDevDomain = (): boolean => {
  if (window.location.hostname.includes('workpass-dev.plume.com')) {
    return true;
  }
  return false;
};

export const getConfigurationFromDomain = (): Domain => {
  if (window.location.hostname === 'businesswifi.bell.ca') {
    return subDomains.find((e) => e.id === 'bell') || noDomain;
  }
  const hostnameBits = window.location.hostname.split('.');

  if (hostnameBits.length === 1) {
    return noDomain; //localhost
  }

  if (hostnameBits.length > 3) {
    const subdomainFromUrl = hostnameBits[hostnameBits.length - 4];
    const env = subDomains.find((e) => e.id === subdomainFromUrl);
    return env || noDomain;
  } else {
    return noDomain;
  }
};
