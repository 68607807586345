import type { DataUnits } from 'Consts/types';

const OneGB = 1073741824;
const OneMB = 1048576;
const OneKB = 1024;

export const bytesToKB = (value: number) => value / OneKB;
export const bytesToMB = (value: number) => value / OneMB;
export const bytesToGB = (value: number) => value / OneGB;

export const bytesToUnit = (value: number, unit: DataUnits) => {
  switch (unit) {
    case 'gb':
      return bytesToGB(value);
    case 'mb':
      return bytesToMB(value);
    case 'kb':
      return bytesToKB(value);
    default:
      return value;
  }
};

export const normalizeToBytes = (value: number, unit?: DataUnits) => {
  switch (unit) {
    case 'gb':
      return value * OneGB;
    case 'mb':
      return value * OneMB;
    case 'kb':
      return value * OneKB;
    default:
      return value;
  }
};

export const getUnitFromMaxValue = (values: number[]): DataUnits => {
  const maxValue = Math.max(...values);

  if (maxValue >= OneGB) {
    return 'gb';
  }
  if (maxValue >= OneMB) {
    return 'mb';
  }
  if (maxValue >= OneKB) {
    return 'kb';
  }

  return 'bytes';
};

export const displayBytes = (value: number, unit?: DataUnits) => {
  const normalizedValue = normalizeToBytes(value, unit);

  if (normalizedValue < OneKB) {
    return `${normalizedValue.toFixed(0)} bytes`;
  }

  if (normalizedValue < OneMB) {
    return `${bytesToKB(normalizedValue).toFixed(0)} KB`;
  }

  if (normalizedValue < OneGB) {
    return `${bytesToMB(normalizedValue).toFixed(0)} MB`;
  }

  return `${bytesToGB(normalizedValue).toFixed(2)} GB`;
};

export const isNumericValue = (value: string) => {
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value !== 'string') {
    return false;
  }
  return !isNaN(value as unknown as number) && !isNaN(parseFloat(value));
};
