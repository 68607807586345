import { atWorkEvent } from 'Consts/types';
import { createStandardSlice } from 'State/utils';
import type { Action, RootState } from 'State/store';
import * as api from 'Api/endpoints';

const slice = createStandardSlice<atWorkEvent[]>('locationAtWorkEvents');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const atWorkEvents = getState().locationAtWorkEvents;

    if (atWorkEvents.isLoading || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));
    const { data, error } = await api.getLocationAtWorkEvents({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching Home Away Events data')
      );

      return;
    }

    dispatch(actions.set(data));
    dispatch(actions.isLoading(false));
  };
};

export const selectors = (state: RootState) => state.locationAtWorkEvents;

export const actions = {
  fetchData,
  ...slice.actions,
};

export default slice.reducer;
