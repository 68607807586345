import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as selectors from 'State/selectors';

import type { AppDispatch, Action } from 'State/store';

import type { Data } from 'State/utils';
import { checkRefresh } from 'State/hooks/utils';

const useConditionalUpdate = <T>({
  data,
  dependencies,
  action,
  dataDependencyKey,
  enableRefresh = true,
  forceUpdate = false,
}: {
  data: Data<T>;
  dependencies: unknown[];
  action: Action;
  dataDependencyKey?: string;
  enableRefresh?: boolean;
  forceUpdate?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const ERROR_THROTTLE_TIME = 10000; // in ms - don't call again for 10seconds

  const customerId = useSelector(selectors.customer.id);
  const { data: token } = useSelector(selectors.auth.locationToken);
  const cloud = useSelector(selectors.auth.cloud);

  const needsRefresh = checkRefresh(
    data,
    dataDependencyKey,
    enableRefresh,
    forceUpdate
  );
  useEffect(() => {
    if (
      !token ||
      !customerId ||
      !needsRefresh ||
      data.isLoading ||
      (data.errorMessage &&
        Date.now() - data.lastAttempt! < ERROR_THROTTLE_TIME) ||
      !!dependencies.find((dependency) => !dependency)
    ) {
      return;
    }
    dispatch(action);
  }, [
    action,
    cloud,
    customerId,
    data.errorMessage,
    data.isLoading,
    dependencies,
    dispatch,
    needsRefresh,
    token,
  ]);
};

export default useConditionalUpdate;
