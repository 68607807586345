import { useRef } from 'react';
import { FilteredListType } from '../helpers/filteredList.helper';

export const useListItemRefs = (
  itemsListForRefs: FilteredListType[]
): React.MutableRefObject<(HTMLDivElement | null)[]> => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  if (itemRefs.current.length !== itemsListForRefs.length) {
    itemRefs.current = Array(itemsListForRefs.length).fill(null);
  }

  return itemRefs;
};
