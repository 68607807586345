import { combineReducers } from '@reduxjs/toolkit';

import debug from './slices/debugSlice';

import build from './slices/buildSlice';
import auth from './slices/authSlice';
import magicLink from './slices/magicLinkSlice';
import customer from './slices/customerSlice';
import employees from './slices/employeesSlice';
import employee from './slices/employeeSlice';
import device from './slices/deviceSlice';
import speedTest from './slices/speedTestSlice';
import locations from './slices/locationsSlice';
import locationAppTime from './slices/locationAppTimeSlice';
import deviceSecurityPolicy from './slices/deviceSecurityPolicySlice';
import personSecurityPolicy from './slices/personSecurityPolicySlice';
import guestNetworkUsage from './slices/guestNetworkUsageSlice';
import guests from './slices/guestsSlice';
import locationAtWorkEvents from './slices/locationAtWorkEventsSlice';
import securityEvents from './slices/securityEvents';

import topology from './slices/topologySlice';
import pubNub from './slices/pubNubSlice';

import globalAuthLoginOptions from './slices/globalAuthLoginOptionsSlice';

import guestEmailCollection from './slices/guestEmailCollectionSlice';

import customerSupportConfigurations from './slices/customerSupportConfigurations';

/* DATA USAGE */
import defaultDailyDataUsage from './slices/dataUsage/default/dailySlice';
import employeeDailyDataUsage from './slices/dataUsage/employee/dailySlice';
import guestDailyDataUsage from './slices/dataUsage/guest/dailySlice';

import defaultMonthlyDataUsage from './slices/dataUsage/default/monthlySlice';
import employeeMonthlyDataUsage from './slices/dataUsage/employee/monthlySlice';
import guestMonthlyDataUsage from './slices/dataUsage/guest/monthlySlice';

import deviceDailyDataUsage from './slices/dataUsage/device/dailySlice';
import deviceMonthlyDataUsage from './slices/dataUsage/device/monthlySlice';

import guestDailyOnlineTime from './slices/onlineTime/guest/dailyOnlineTimeSlice';
import personsDailyOnlineTime from './slices/onlineTime/persons/dailyOnlineTimeSlice';
import personsMonthlyOnlineTime from './slices/onlineTime/persons/monthlyOnlineTimeSlice';
import personsMonthlyAppsOnlineTime from './slices/onlineTime/persons/monthlyAppsOnlineTimeSlice';
import personsLast12MonthsAppsOnlineTime from './slices/onlineTime/persons/last12monthsAppsOnlineTimeSlice';

import personDailyDataUsage from './slices/dataUsage/person/dailySlice';
import personMonthlyDataUsage from './slices/dataUsage/person/monthlySlice';
import deviceDailyOnlineTime from './slices/onlineTime/device/dailyDeviceOnlineTimeSlice';
import deviceMonthlyOnlineTime from './slices/onlineTime/device/monthlyDeviceOnlineTimeSlice';

import guestDailyAppsOnlineTime from './slices/appsOnlineTime/guest/dailyAppsOnlineTimeSlice';
import guestMonthlyAppsOnlineTime from './slices/appsOnlineTime/guest/monthlyAppsOnlineTimeSlice';
import employeeMonthlyAppsOnlineTime from './slices/appsOnlineTime/employee/monthlyAppsOnlineTimeSlice';
import defaultMonthlyAppsOnlineTime from './slices/appsOnlineTime/default/monthlyAppsOnlineTimeSlice';
import guestLast12MonthsAppsOnlineTime from './slices/appsOnlineTime/guest/last12MonthsAppsOnlineTimeSlice';
import employeeLast12MonthsAppsOnlineTime from './slices/appsOnlineTime/employee/last12MonthsAppsOnlineTimeSlice';
import defaultLast12MonthsAppsOnlineTime from './slices/appsOnlineTime/default/last12MonthsAppsOnlineTimeSlice';

/* SETTINGS */
import wifi from './slices/settings/wifiSlice';
import networkAccess from './slices/settings/networkAccessSlice';
import pods from './slices/settings/podsSlice';
import securityPolicy from './slices/settings/securityPolicySlice';
import employeeLogin from './slices/settings/employeeLoginSlice';
import locationSecurityAppliesToAllDevices from './slices/settings/securityPolicySlice';
import blockedEmployees from './slices/settings/blockedEmployeesSlice';

/* ZONES */
import devices from './slices/zones/devicesSlice';
import deviceGroups from './slices/zones/deviceGroupsSlice';
import employeeGroupShareAccess from './slices/zones/shareAccessSlice';

/* UI */
import devicesAndGroups from './slices/ui/devicesAndGroupsSlice';
import page from './slices/ui/pageSlice';
import miniBanner from './slices/ui/miniBanner';
import errorAlert from './slices/ui/errorAlertSlice';
import deviceSecurityEvents from './slices/deviceSecurityEventsSlice';
import employeeSecurityEvents from './slices/employeeSecurityEventsSlice';

import applicationInfo from './slices/applicationInfoSlice';
import locationCapabilities from './slices/locationCapabilitiesSlice';

const reducers = combineReducers({
  debug,
  build,
  auth,
  magicLink,
  customer,
  employees,
  device,
  employee,
  speedTest,
  locations,
  locationAppTime,
  locationAtWorkEvents,
  deviceSecurityPolicy,
  personSecurityPolicy,
  securityEvents,
  deviceSecurityEvents,
  employeeSecurityEvents,
  topology,
  pubNub,
  customerSupportConfigurations,
  guestEmailCollection,
  globalAuthLoginOptions,
  applicationInfo,
  locationCapabilities,

  settings: combineReducers({
    wifi,
    networkAccess,
    pods,
    securityPolicy,
    locationSecurityAppliesToAllDevices,
    employeeLogin,
    blockedEmployees,
  }),

  zones: combineReducers({
    devices,
    deviceGroups,
    employeeGroupShareAccess,
  }),

  guestNetworkUsage,
  guests,

  ui: combineReducers({
    devicesAndGroups,
    page,
    miniBanner,
    errorAlert,
  }),

  dataUsage: combineReducers({
    default: combineReducers({
      daily: defaultDailyDataUsage,
      monthly: defaultMonthlyDataUsage,
    }),
    employee: combineReducers({
      daily: employeeDailyDataUsage,
      monthly: employeeMonthlyDataUsage,
    }),
    person: combineReducers({
      daily: personDailyDataUsage,
      monthly: personMonthlyDataUsage,
    }),
    guest: combineReducers({
      daily: guestDailyDataUsage,
      monthly: guestMonthlyDataUsage,
    }),
    device: combineReducers({
      daily: deviceDailyDataUsage,
      monthly: deviceMonthlyDataUsage,
    }),
  }),

  onlineTime: combineReducers({
    persons: combineReducers({
      daily: personsDailyOnlineTime,
      monthly: personsMonthlyOnlineTime,
    }),
    device: combineReducers({
      daily: deviceDailyOnlineTime,
      monthly: deviceMonthlyOnlineTime,
    }),
    guest: combineReducers({
      daily: guestDailyOnlineTime,
    }),
  }),

  appsOnlineTime: combineReducers({
    persons: combineReducers({
      monthly: personsMonthlyAppsOnlineTime,
      last12months: personsLast12MonthsAppsOnlineTime,
    }),
    // device: combineReducers({
    //   daily: deviceDailyOnlineTime,
    //   monthly: deviceMonthlyOnlineTime,
    // }),
    guest: combineReducers({
      daily: guestDailyAppsOnlineTime,
      monthly: guestMonthlyAppsOnlineTime,
      last12months: guestLast12MonthsAppsOnlineTime,
    }),
    employee: combineReducers({
      // daily: guestDailyAppsOnlineTime,
      monthly: employeeMonthlyAppsOnlineTime,
      last12months: employeeLast12MonthsAppsOnlineTime,
    }),
    default: combineReducers({
      monthly: defaultMonthlyAppsOnlineTime,
      last12months: defaultLast12MonthsAppsOnlineTime,
    }),
  }),
});

export default reducers;
