import { TimeDailyToday } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<Record<string, TimeDailyToday>>(
  'deviceDailyOnlineTime'
);

const fetchData = (mac: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !token || !activeLocation?.id) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDeviceDailyOnlineTimeCategories({
      mac,
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching online time'));

      return;
    }

    dispatch(
      actions.update({
        [mac]: data.dailyToday,
      })
    );
  };
};

export const selectors = (state: RootState) => state.onlineTime.device.daily;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
