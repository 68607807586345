import * as api from 'Api/endpoints';

import { createStandardSlice } from 'State/utils';
import type { RootState, Action } from 'State/store';
import { GuestEmailCollectionInfo } from 'Consts/types';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { MixpanelService } from 'trackingAnalytics/services/mixPanel.service';
import * as globalActions from '../../State/actions';

const slice = createStandardSlice<GuestEmailCollectionInfo>(
  'guestEmailCollection'
);

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const guestEmailCollection = getState().guestEmailCollection;

    if (
      guestEmailCollection.isLoading ||
      !customerId ||
      !activeLocation?.id ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.guestEmailCollectionInfo({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message ?? 'Error fetching guests data'));

      return;
    }

    dispatch(actions.set(data));
  };
};

const enableEmailCapture = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    let errResp = null;

    const { error } = await api.enableGuestEmailCollection({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error) {
      errResp = error;
    }

    if (errResp) {
      dispatch(actions.error(errResp.message));
      MixpanelService.getInstance().storeEvent(
        MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_FAILURE,
        {
          errorMessage: errResp.message,
        }
      );
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: errResp.message,
          state: 'unset',
        })
      );
      return;
    }
    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_SUCCESS
    );

    // reload the data
    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.guestEmailCollection;

export const actions = {
  fetchData,
  ...slice.actions,
  enableEmailCapture,
};

export default slice.reducer;
