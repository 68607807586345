import type {
  RecursivePartial,
  SecurityContentTypes,
  SecurityPolicy,
  Website,
  WebsiteType,
} from 'Consts/types';
import { SECURITY_CONTENT } from 'Consts/defintions';

import * as api from 'Api/endpoints';

import type { Action, RootState } from 'State/store';
import { createStandardSlice } from 'State/utils';
import * as globalActions from '../actions';
import {
  PolicyItemsProps,
  LocationItemsProps,
} from './settings/securityPolicySlice';
import { METHODS, Method } from 'Api';
import i18n from 'i18n';

export type PersonShieldSettingsProps = PolicyItemsProps & LocationItemsProps;

const slice = createStandardSlice<SecurityPolicy>('personSecurityPolicy');

const fetchData = (personId: string): Action => {
  return async (dispatch, getState) => {
    const { customerId } = getState().customer;
    const { activeLocationId } = getState().locations;
    const { data: token } = getState().auth.data;
    const cloud = getState().auth.cloud;
    if (!personId || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }
    if (!data) {
      dispatch(actions.error('no Employee settings data'));

      return;
    }

    dispatch(actions.set(data?.securityPolicy!));
  };
};

const updateActivePersonAppTime = (
  appTimeEnabled: boolean,
  personId: string
): Action => {
  return async (dispatch, getState) => {
    const { customerId } = getState().customer;
    const { activeLocationId } = getState().locations;
    const { data: token } = getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    const { error } = await api.updatePersonAppTime({
      customerId,
      locationId: activeLocationId,
      personId,
      data: { enable: appTimeEnabled },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(globalActions.employee.fetchData(personId));
    dispatch(globalActions.settings.securityPolicy.fetchData());
  };
};

const updatePersonSecurityPolicy = (
  personId: string,
  info: Partial<PolicyItemsProps>
): Action => {
  return async (dispatch, getState) => {
    const { customerId } = getState().customer;
    const { activeLocation } = getState().locations;
    const { data: token } = getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().personSecurityPolicy;
    const securityPolicy = securityPolicyData.data;

    if (
      !customerId ||
      !activeLocation?.id ||
      !activeLocation?.ssid ||
      !token ||
      !personId
    ) {
      return;
    }
    let payload: RecursivePartial<SecurityPolicy> = {};

    if (securityPolicy) {
      if (typeof info.adBlocking === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.adBlocking) {
          content = [...securityPolicy.content, SECURITY_CONTENT.adBlocking];
        } else {
          content = securityPolicy?.content?.filter(
            (el) => el !== SECURITY_CONTENT.adBlocking
          );
        }

        payload = {
          content,
        };
      }

      if (typeof info.workAppropriate === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.workAppropriate) {
          content = [
            ...securityPolicy.content,
            SECURITY_CONTENT.workAppropriate,
          ];
        } else {
          content = securityPolicy?.content?.filter(
            (el) => el !== SECURITY_CONTENT.workAppropriate
          );
        }

        payload = {
          content,
        };
      }

      if (typeof info.secureAndProtect === 'boolean') {
        if (!info.secureAndProtect && securityPolicy.iotProtect) {
          payload = {
            ...info,
            iotProtect: false,
          };
        } else {
          payload = {
            ...info,
          };
        }
      }

      if (typeof info.iotProtect === 'boolean') {
        if (info.iotProtect && !securityPolicy.secureAndProtect) {
          payload = {
            ...info,
            secureAndProtect: true,
          };
        } else {
          payload = { ...info };
        }
      }
    }

    const { data, error } = await api.updatePersonSecurityPolicy({
      customerId,
      locationId: activeLocation.id,
      personId,
      data: payload,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error updating person security policy')
      );

      return;
    }
    dispatch(globalActions.employee.fetchData(personId));
    dispatch(globalActions.settings.securityPolicy.fetchData());
  };
};
const updatePersonPolicyWhitelist = (
  websiteType: WebsiteType,
  website: string,
  method: Method,
  personId: string
): Action => {
  return async (dispatch, getState, t) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const personSecurityPolicyData = getState().personSecurityPolicy;
    const personSecurityPolicy = personSecurityPolicyData.data;

    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    if (
      method === METHODS.POST &&
      personSecurityPolicy?.websites.whitelisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    if (
      method === METHODS.DELETE &&
      !personSecurityPolicy?.websites.whitelisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    let payload: Website = { type: websiteType, value: website };

    dispatch(actions.isLoading(true));

    const { data, error } = await api.updatePersonPolicyWhitelist({
      customerId,
      locationId: activeLocation.id,
      personId,
      data: payload,
      method,
      token,
      cloud,
    });

    if (error || (method === METHODS.POST && !data)) {
      dispatch(
        actions.error(error?.message || 'Error updating whitelisted websites')
      );
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error!.message,
          },
        })
      );
      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(personId));
    dispatch(globalActions.securityEvents.fetchData());

    if (method === METHODS.DELETE) {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.removed', {
            website: website,
          }),
          state: 'set',
        })
      );
    } else {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.approvedWebsite', {
            website: website,
          }),
          state: 'set',
        })
      );
    }
  };
};

const updatePersonPolicyBlacklist = (
  websiteType: WebsiteType,
  website: string,
  method: Method,
  personId: string
): Action => {
  return async (dispatch, getState, t) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const personSecurityPolicyData = getState().personSecurityPolicy;
    const personSecurityPolicy = personSecurityPolicyData.data;

    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    if (
      method === METHODS.POST &&
      personSecurityPolicy?.websites.blacklisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    if (
      method === METHODS.DELETE &&
      !personSecurityPolicy?.websites.blacklisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    let payload: Website = { type: websiteType, value: website };

    dispatch(actions.isLoading(true));

    const { data, error } = await api.updatePersonPolicyBlacklist({
      customerId,
      locationId: activeLocation.id,
      personId,
      data: payload,
      method,
      token,
      cloud,
    });

    if (error || (method === METHODS.POST && !data)) {
      dispatch(
        actions.error(error?.message || 'Error updating whitelisted websites')
      );
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error!.message,
          },
        })
      );
      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(personId));
    dispatch(globalActions.securityEvents.fetchData());

    if (method === METHODS.DELETE) {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.removed', {
            website: website,
          }),
          state: 'set',
        })
      );
    } else {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.blockedWebsite', {
            website: website,
          }),
          state: 'set',
        })
      );
    }
  };
};

export const selectors = {
  personSecurityPolicy: (state: RootState) => state.personSecurityPolicy,

  personShieldSettings: (state: RootState) => {
    const employee = state.employee.data;
    const locations = state.locations.ownedLocations;
    return {
      isLoading: locations.isLoading || employee.isLoading,
      errorMessage: locations.errorMessage || employee.errorMessage,
      data: {
        appTime: employee.data?.appTime || false,
        workAppropriate:
          employee.data?.securityPolicy?.content.includes('workAppropriate') ||
          false,
        secureAndProtect:
          employee.data?.securityPolicy?.secureAndProtect || false,
        iotProtect: employee.data?.securityPolicy?.iotProtect || false,
        adBlocking:
          employee.data?.securityPolicy?.content?.includes('adBlocking') ||
          false,
      },
    };
  },
};

export const actions = {
  ...slice.actions,
  fetchData,
  updatePersonSecurityPolicy,
  updateActivePersonAppTime,
  updatePersonPolicyWhitelist,
  updatePersonPolicyBlacklist,
};

export default slice.reducer;
