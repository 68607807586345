import type { Mac, Person } from 'Consts/types';
import { MORE_PERSONS, PERSONS } from 'Consts/data examples/person';

import * as api from 'Api/endpoints';

import type { Action, RootState } from 'State/store';
import { createStandardSlice } from 'State/utils';
import * as globalActions from '../../State/actions';
import { MixpanelService } from '../../trackingAnalytics/services/mixPanel.service';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';

const slice = createStandardSlice<Person[]>('employees');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;

    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const debugEnabled = getState().debug.debugEnabled;
    const employees = getState().employees;

    if (employees.isLoading || !customerId || !token || !activeLocation?.id) {
      return;
    }

    dispatch(actions.isLoading(true));

    if (debugEnabled) {
      dispatch(actions.set([...PERSONS, ...MORE_PERSONS]));

      return;
    }

    const { data, error } = await api.getEmployees({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching data'));

      return;
    }

    dispatch(actions.set(data));
  };
};

const addEmployee = (
  nickname: string,
  imageId: string,
  assignedDevices?: Mac[]
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;

    const { data: token } = getState().auth.coAdminData.data
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !nickname || !imageId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.addEmployee({
      customerId,
      locationId: activeLocationId,
      nickname,
      imageId,
      assignedDevices,
      primaryDevice: assignedDevices?.[0],
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));
      return;
    }

    dispatch(actions.isLoading(false));
    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.EMPLOYEE_CREATE_SUCCESS,
      {
        nickname,
        imageId,
      }
    );
    dispatch(actions.fetchData());
    dispatch(globalActions.zones.devices.fetchData());
  };
};

export const selectors = (state: RootState) => state.employees;

export const actions = {
  ...slice.actions,
  fetchData,
  addEmployee,
};

export default slice.reducer;
