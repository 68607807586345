import { ZoneDevice, FullDevice, Mac, NetworkType } from 'Consts/types';
import { DEVICES } from 'Consts/data examples/devices';

import * as api from 'Api/endpoints';
import * as globalActions from '../../../State/actions';

import type { RootState, Action } from 'State/store';

import { createStandardSlice } from 'State/utils';

export const sanitizeDevice = (fullDevice: FullDevice): ZoneDevice => {
  const device = {} as ZoneDevice;

  device.connectionStateChangeAt = fullDevice.connectionStateChangeAt;
  device.connectionState = fullDevice.connectionState;

  device.id = fullDevice.id;
  device.nickname = fullDevice.nickname;
  device.name = fullDevice.name;
  device.mac = fullDevice.mac;

  const icon =
    fullDevice.kind?.type?.iconV3 ||
    fullDevice.kind?.iconV3 ||
    fullDevice.iconV3 ||
    fullDevice.icon ||
    'unknown';
  device.health = fullDevice.health;
  device.icon = icon;
  device.channel = fullDevice.channel;
  device.freqBand = fullDevice.freqBand;
  device.ip = fullDevice.ip;
  device.leafToRoot = fullDevice.leafToRoot;
  device.networkId = fullDevice.networkId;
  device.networkAccessMode = fullDevice.quarantine?.enable
    ? 'quarantined'
    : fullDevice.networkAccess.mode;
  device.personId = fullDevice.personId;
  device.category =
    fullDevice.category ||
    fullDevice?.kind?.category ||
    fullDevice?.kind?.type?.category;
  device.medium = fullDevice?.medium;
  device.appTime = fullDevice.appTime;
  device.quarantineAnomalyBlacklistFqdn =
    fullDevice.quarantine?.anomalyBlacklist?.[0]?.fqdn;

  return device;
};

const slice = createStandardSlice<ZoneDevice[]>('devices');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const debugEnabled = getState().debug.debugEnabled;
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;

    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }
    dispatch(actions.isLoading(true));
    if (debugEnabled) {
      dispatch(slice.actions.set(DEVICES.map(sanitizeDevice)));

      return;
    }
    const { data, error } = await api.getDevices({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    if (!data) {
      dispatch(actions.error('no data'));

      return;
    }

    dispatch(actions.set(data.devices.map(sanitizeDevice)));
  };
};

const blockDevices = (macs: Mac[]): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!macs?.length || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.blockDevices({
      customerId,
      locationId: activeLocationId,
      macs,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const unQuarantineDevice = (
  mac: Mac,
  domain: string,
  duration?: number
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!mac || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.unquarantineDevices({
      customerId,
      locationId: activeLocationId,
      mac,
      domain,
      duration,
      token,
      cloud,
    });

    if (error) {
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error!.message,
          },
        })
      );
      dispatch(actions.error(error.message));

      return;
    }
    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const approveDevices = (macs: Mac[], networkId: NetworkType): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!macs?.length || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.approveDevices({
      customerId,
      locationId: activeLocationId,
      networkId,
      macs,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.zones.devices;

export const actions = {
  ...slice.actions,
  fetchData,
  blockDevices,
  approveDevices,
  unQuarantineDevice,
};

export default slice.reducer;
