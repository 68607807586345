import { useSelector } from 'react-redux';

import type { RootState, Action } from 'State/store';

import { Data } from 'State/utils';

import useLocations from '../useLocations';
import useCustomer from '../useCustomer';
import useConditionalUpdate from './useConditionalUpdate';

const useUpdatedData = <T>({
  selector,
  action,
  dataDependencyKey,
  enableRefresh = true,
}: {
  selector: (state: RootState) => Data<T>;
  action: Action;
  dataDependencyKey?: string;
  enableRefresh?: boolean;
}) => {
  const customer = useCustomer();
  const { activeLocation } = useLocations();
  const data: Data<T> = useSelector(selector);

  useConditionalUpdate({
    data,
    dependencies: [activeLocation.data?.id, customer.data?.id],
    action,
    dataDependencyKey,
    enableRefresh,
  });

  return {
    ...data,
    isLoading: data.isLoading || activeLocation.isLoading,
    errorMessage: data.errorMessage || activeLocation.errorMessage,
  };
};

export default useUpdatedData;
