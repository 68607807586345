import { FullDevice } from 'Consts/types';

export const DEVICES: FullDevice[] = [
  {
    mac: '30:07:4d:0f:cf:6b',
    keyId: 0,
    hostName: 'SM-G950F',
    model: 'S8',
    medium: 'wifi',
    connectionState: 'connected',
    connectionStateChangeAt: '2022-11-30T14:34:33.782Z',
    vapType: 'captivePortal',
    networkId: 'guest',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '172.16.217.204',
    ipv6: [],
    channel: 44,
    freqBand: '5GL',
    steering: {},
    name: 'SM-G950F',
    icon: 'smartphone-android',
    iconV2: 'smartphone-samsung-galaxy',
    iconV3: 'smartphone-samsung-galaxy',
    category: 'Smart Phone',
    brand: 'Samsung',
    osName: 'Android',
    health: {
      score: 5,
      status: 'excellent',
      details: null,
    },
    leafToRoot: [
      {
        id: 'EB8620096E',
        nickname: 'Main',
        parentId: 'EB86200286',
        medium: 'wifi',
      },
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'home',
    personId: '63847c5842b6f50052081b90',
    firstConnectedAt: '2022-11-28T09:27:07.085Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'samsungGalaxy',
      type: {
        id: 'samsungGalaxy',
        category: 'Smart Phone',
        brand: 'Samsung',
        name: 'Galaxy',
        model: 'S8',
        icon: 'smartphone-android',
        iconV2: 'smartphone-samsung-galaxy',
        iconV3: 'smartphone-samsung-galaxy',
        osName: 'Android',
        osVersion: '12',
        source: 'rules',
        confidence: 375,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Samsung',
      name: 'SM-G950F',
      model: 'S8',
      icon: 'smartphone-android',
      iconV2: 'smartphone-samsung-galaxy',
      iconV3: 'smartphone-samsung-galaxy',
      osName: 'Android',
      osVersion: '12',
      source: 'rules',
      confidence: 375,
      stationary: false,
      typeIdentified: true,
      ohpCapable: true,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: true,
    },
    groupOfUnassignedDevices: false,
    networkAccess: {
      mode: 'auto',
    },
  },
  {
    mac: 'a6:3d:b0:fc:b8:29',
    keyId: -2,
    hostName: '',
    medium: 'wifi',
    connectionState: 'connected',
    connectionStateChangeAt: '2022-11-30T14:35:47.177Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.21',
    ipv6: ['fe80::184f:ed36:31af:d133'],
    channel: 44,
    freqBand: '5GL',
    steering: {},
    name: 'Apple Macintosh',
    icon: 'laptop-macbook',
    iconV2: 'laptop-apple-macintosh',
    iconV3: 'laptop-apple-macintosh',
    category: 'Laptop',
    brand: 'Apple',
    osName: 'Mac OS X',
    health: {
      score: 5,
      status: 'excellent',
      details: null,
    },
    leafToRoot: [
      {
        id: 'EB8620096E',
        nickname: 'Main',
        parentId: 'EB86200286',
        medium: 'wifi',
      },
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: true,
    accessZone: 'guests',
    personId: '63847c5842b6f50052081b90',
    firstConnectedAt: '2022-11-30T08:44:03.835Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'apple-macintosh',
      type: {
        id: 'apple-macintosh',
        category: 'Laptop',
        brand: 'Apple',
        name: 'Macintosh',
        icon: 'laptop-macbook',
        iconV2: 'laptop-apple-macintosh',
        iconV3: 'laptop-apple-macintosh',
        osName: 'Mac OS X',
        osVersion: '10.15.7',
        source: 'rules',
        confidence: 350,
        stationary: true,
      },
      category: 'Laptop',
      brand: 'Apple',
      name: 'Apple Macintosh',
      icon: 'laptop-macbook',
      iconV2: 'laptop-apple-macintosh',
      iconV3: 'laptop-apple-macintosh',
      osName: 'Mac OS X',
      osVersion: '10.15.7',
      source: 'rules',
      confidence: 350,
      stationary: true,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    groupOfUnassignedDevices: false,
    networkAccess: {
      mode: 'auto',
    },
  },
  {
    mac: '9a:d0:a9:bc:9d:b4',
    keyId: 0,
    hostName: 'Galaxy-S21-Ultra-5G',
    model: 'S21',
    medium: 'wifi',
    connectionState: 'connected',
    connectionStateChangeAt: '2022-11-30T14:41:24.869Z',
    vapType: 'captivePortal',
    networkId: 'guest',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '172.16.217.181',
    ipv6: [],
    channel: 44,
    freqBand: '5GL',
    steering: {},
    name: 'Galaxy-S21-Ultra-5G',
    icon: 'smartphone-android',
    iconV2: 'smartphone-samsung-galaxy',
    iconV3: 'smartphone-samsung-galaxy',
    category: 'Smart Phone',
    brand: 'Samsung',
    osName: 'Android',
    health: {
      score: 5,
      status: 'excellent',
      details: null,
    },
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: true,
    accessZone: 'home',
    firstConnectedAt: '2022-11-30T14:41:24.869Z',
    capabilities: {
      radio24: false,
      radio50: true,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'samsungGalaxy',
      type: {
        id: 'samsungGalaxy',
        category: 'Smart Phone',
        brand: 'Samsung',
        name: 'Galaxy',
        model: 'S21',
        icon: 'smartphone-android',
        iconV2: 'smartphone-samsung-galaxy',
        iconV3: 'smartphone-samsung-galaxy',
        osName: 'Android',
        source: 'rules',
        confidence: 600,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Samsung',
      name: 'Galaxy-S21-Ultra-5G',
      model: 'S21',
      icon: 'smartphone-android',
      iconV2: 'smartphone-samsung-galaxy',
      iconV3: 'smartphone-samsung-galaxy',
      osName: 'Android',
      source: 'rules',
      confidence: 600,
      stationary: false,
      typeIdentified: true,
      ohpCapable: true,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: true,
    },
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
  },
  {
    mac: '58:11:22:3c:b0:9b',
    hostName: 'drc-lux',
    medium: 'ethernet',
    connectionState: 'connected',
    connectionStateChangeAt: '2022-11-30T15:39:43.879Z',
    networkId: 'default',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: false,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'windows',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.19',
    ipv6: ['fe80::ec32:28be:cde3:52d1'],
    steering: {},
    name: 'drc-lux',
    icon: 'laptop',
    iconV2: 'laptop-microsoft-windowscomputer',
    iconV3: 'laptop-microsoft-windowscomputer',
    category: 'Laptop',
    brand: 'Microsoft',
    osName: 'Windows',
    health: {
      status: 'excellent',
      score: 5,
      details: null,
    },
    leafToRoot: [
      {
        id: 'EB8620096E',
        nickname: 'Main',
        parentId: 'EB86200286',
        medium: 'wifi',
      },
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'home',
    firstConnectedAt: '2022-11-30T08:44:03.835Z',
    capabilities: {
      radio24: false,
      radio50: false,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'windows',
      type: {
        id: 'windows',
        category: 'Laptop',
        brand: 'Microsoft',
        name: 'Windows Computer',
        icon: 'laptop',
        iconV2: 'laptop-microsoft-windowscomputer',
        iconV3: 'laptop-microsoft-windowscomputer',
        osName: 'Windows',
        source: 'rules',
        confidence: 375,
        stationary: true,
      },
      category: 'Laptop',
      brand: 'Microsoft',
      name: 'drc-lux',
      icon: 'laptop',
      iconV2: 'laptop-microsoft-windowscomputer',
      iconV3: 'laptop-microsoft-windowscomputer',
      osName: 'Windows',
      source: 'rules',
      confidence: 375,
      stationary: true,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'approved',
    },
    channel: 44,
    freqBand: '5GL',
  },
  {
    mac: 'f0:27:2d:14:5b:ee',
    keyId: -2,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-29T21:54:49.485Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: false,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.15',
    ipv6: [],
    steering: {},
    name: 'Amazon Kindle',
    icon: 'tablet',
    iconV2: 'tablet-amazon-kindle',
    iconV3: 'tablet-amazon-kindle',
    category: 'Tablet',
    brand: 'Amazon',
    osName: 'Android',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-28T08:54:57.244Z',
    capabilities: {
      radio24: true,
      radio50: false,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'amazon-kindle',
      type: {
        id: 'amazon-kindle',
        category: 'Tablet',
        brand: 'Amazon',
        name: 'Kindle',
        icon: 'tablet',
        iconV2: 'tablet-amazon-kindle',
        iconV3: 'tablet-amazon-kindle',
        osName: 'Android',
        source: 'rules',
        confidence: 250,
        stationary: false,
      },
      category: 'Tablet',
      brand: 'Amazon',
      name: 'Amazon Kindle',
      icon: 'tablet',
      iconV2: 'tablet-amazon-kindle',
      iconV3: 'tablet-amazon-kindle',
      osName: 'Android',
      source: 'rules',
      confidence: 250,
      stationary: false,
      typeIdentified: true,
      ohpCapable: true,
    },
    nickname: 'Alexandria',
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: true,
    },
    networkHistory: [
      {
        networkId: 'guest',
        connectionStateChangedAt: '2022-11-28T09:01:20.821Z',
      },
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-29T21:54:46.469Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
    channel: 44,
    freqBand: '5GL',
  },
  {
    mac: 'fe:85:bf:dc:4e:78',
    keyId: -2,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T08:44:03.835Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    hiddenAt: '2022-11-30T08:43:47.767Z',
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.18',
    ipv6: [],
    steering: {},
    name: 'Apple',
    icon: 'laptop-macbook',
    iconV2: 'laptop-apple',
    iconV3: 'laptop-apple',
    category: 'Laptop',
    brand: 'Apple',
    osName: 'Mac',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: true,
    accessZone: 'home',
    personId: '63847c63344742005134b648',
    firstConnectedAt: '2022-11-28T08:53:50.944Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'apple-mac-laptop',
      type: {
        id: 'apple-mac-laptop',
        category: 'Laptop',
        brand: 'Apple',
        icon: 'laptop-macbook',
        iconV2: 'laptop-apple',
        iconV3: 'laptop-apple',
        osName: 'Mac',
        osVersion: '10.15.7',
        source: 'rules',
        confidence: 250,
        stationary: true,
      },
      category: 'Laptop',
      brand: 'Apple',
      name: 'Apple',
      icon: 'laptop-macbook',
      iconV2: 'laptop-apple',
      iconV3: 'laptop-apple',
      osName: 'Mac',
      osVersion: '10.15.7',
      source: 'rules',
      confidence: 250,
      stationary: true,
      typeIdentified: false,
      ohpCapable: false,
    },
    nickname: null,
    roomId: null,
    plumeTypeIdentified: false,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-29T19:35:58.210Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: 'e6:23:9f:5e:2a:2b',
    keyId: -2,
    hostName: 'Galaxy-S21-Ultra-5G',
    model: 'S21',
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T11:33:47.694Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.20',
    ipv6: [],
    steering: {},
    name: 'Galaxy-S21-Ultra-5G',
    icon: 'smartphone-android',
    iconV2: 'smartphone-samsung-galaxy',
    iconV3: 'smartphone-samsung-galaxy',
    category: 'Smart Phone',
    brand: 'Samsung',
    osName: 'Android',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: true,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-30T08:44:03.835Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: true,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'samsungGalaxy',
      type: {
        id: 'samsungGalaxy',
        category: 'Smart Phone',
        brand: 'Samsung',
        name: 'Galaxy',
        model: 'S21',
        icon: 'smartphone-android',
        iconV2: 'smartphone-samsung-galaxy',
        iconV3: 'smartphone-samsung-galaxy',
        osName: 'Android',
        osVersion: '13',
        source: 'rules',
        confidence: 700,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Samsung',
      name: 'Galaxy-S21-Ultra-5G',
      model: 'S21',
      icon: 'smartphone-android',
      iconV2: 'smartphone-samsung-galaxy',
      iconV3: 'smartphone-samsung-galaxy',
      osName: 'Android',
      osVersion: '13',
      source: 'rules',
      confidence: 700,
      stationary: false,
      typeIdentified: true,
      ohpCapable: true,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: true,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-30T08:50:31.154Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: 'da:0c:a4:7a:0f:36',
    keyId: -2,
    hostName: 'Galaxy-S21-Ultra-5G',
    model: 'S21',
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T08:44:03.835Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.14',
    ipv6: [],
    steering: {},
    name: 'Galaxy-S21-Ultra-5G',
    icon: 'smartphone-android',
    iconV2: 'smartphone-samsung-galaxy',
    iconV3: 'smartphone-samsung-galaxy',
    category: 'Smart Phone',
    brand: 'Samsung',
    osName: 'Android',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: true,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-28T08:53:22.384Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: true,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'samsungGalaxy',
      type: {
        id: 'samsungGalaxy',
        category: 'Smart Phone',
        brand: 'Samsung',
        name: 'Galaxy',
        model: 'S21',
        icon: 'smartphone-android',
        iconV2: 'smartphone-samsung-galaxy',
        iconV3: 'smartphone-samsung-galaxy',
        osName: 'Android',
        osVersion: '13',
        source: 'rules',
        confidence: 700,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Samsung',
      name: 'Galaxy-S21-Ultra-5G',
      model: 'S21',
      icon: 'smartphone-android',
      iconV2: 'smartphone-samsung-galaxy',
      iconV3: 'smartphone-samsung-galaxy',
      osName: 'Android',
      osVersion: '13',
      source: 'rules',
      confidence: 700,
      stationary: false,
      typeIdentified: true,
      ohpCapable: true,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: true,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-29T15:45:49.920Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: '00:0a:f5:e3:14:80',
    keyId: -2,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-28T22:01:04.320Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: false,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.5',
    ipv6: [],
    steering: {},
    name: 'Linux',
    icon: 'laptop',
    iconV2: 'laptop-linux',
    iconV3: 'laptop-linux',
    category: 'Laptop',
    osName: 'Linux',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-28T18:01:00.528Z',
    capabilities: {
      radio24: true,
      radio50: false,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'linuxdesktop-laptop',
      type: {
        id: 'linuxdesktop-laptop',
        category: 'Laptop',
        name: 'Linux Desktop',
        icon: 'laptop',
        iconV2: 'laptop-linux',
        iconV3: 'laptop-linux',
        osName: 'Linux',
        osVersion: 'Intel.x86.64',
        source: 'rules',
        confidence: 200,
        stationary: true,
      },
      category: 'Laptop',
      name: 'Linux',
      icon: 'laptop',
      iconV2: 'laptop-linux',
      iconV3: 'laptop-linux',
      osName: 'Linux',
      osVersion: 'Intel.x86.64',
      source: 'rules',
      confidence: 200,
      stationary: true,
      typeIdentified: false,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: false,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-28T20:08:31.747Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: 'b4:8c:9d:5c:99:a5',
    keyId: -2,
    hostName: 'lux',
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T15:39:43.879Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'windows',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ip: '192.168.1.10',
    ipv6: [],
    steering: {},
    name: 'lux',
    icon: 'laptop',
    iconV2: 'laptop-microsoft-windowscomputer',
    iconV3: 'laptop-microsoft-windowscomputer',
    category: 'Laptop',
    brand: 'Microsoft',
    osName: 'Windows',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-28T12:43:05.604Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: true,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'windows',
      type: {
        id: 'windows',
        category: 'Laptop',
        brand: 'Microsoft',
        name: 'Windows Computer',
        icon: 'laptop',
        iconV2: 'laptop-microsoft-windowscomputer',
        iconV3: 'laptop-microsoft-windowscomputer',
        osName: 'Windows',
        osVersion: '10.0',
        source: 'rules',
        confidence: 450,
        stationary: true,
      },
      category: 'Laptop',
      brand: 'Microsoft',
      name: 'lux',
      icon: 'laptop',
      iconV2: 'laptop-microsoft-windowscomputer',
      iconV3: 'laptop-microsoft-windowscomputer',
      osName: 'Windows',
      osVersion: '10.0',
      source: 'rules',
      confidence: 450,
      stationary: true,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'default',
        connectionStateChangedAt: '2022-11-29T21:53:22.829Z',
      },
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-30T14:50:01.956Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: 'dc:68:eb:bc:a0:fe',
    keyId: -2,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T08:44:03.835Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'video',
      },
    },
    ip: '192.168.1.12',
    ipv6: [],
    steering: {},
    name: 'Nintendo Switch',
    icon: 'gamingconsole',
    iconV2: 'gameconsole-nintendo-switch',
    iconV3: 'gameconsole-nintendo-switch',
    category: 'Game Console',
    brand: 'Nintendo',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'guests',
    firstConnectedAt: '2022-11-28T14:56:29.379Z',
    capabilities: {
      radio24: true,
      radio50: true,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'nintendo-switch',
      type: {
        id: 'nintendo-switch',
        category: 'Game Console',
        brand: 'Nintendo',
        name: 'Switch',
        icon: 'gamingconsole',
        iconV2: 'gameconsole-nintendo-switch',
        iconV3: 'gameconsole-nintendo-switch',
        source: 'rules',
        confidence: 400,
        stationary: true,
      },
      category: 'Game Console',
      brand: 'Nintendo',
      name: 'Nintendo Switch',
      icon: 'gamingconsole',
      iconV2: 'gameconsole-nintendo-switch',
      iconV3: 'gameconsole-nintendo-switch',
      source: 'rules',
      confidence: 400,
      stationary: true,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-29T15:45:49.920Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'blocked',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: '04:f1:28:1c:8c:23',
    keyId: 0,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T09:01:38.369Z',
    vapType: 'captivePortal',
    networkId: 'guest',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: false,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    ipv6: [],
    steering: {},
    name: 'Nokia Phone',
    icon: 'smartphone',
    iconV2: 'smartphone-nokia-phone',
    iconV3: 'smartphone-nokia-phone',
    category: 'Smart Phone',
    brand: 'Nokia',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'home',
    firstConnectedAt: '2022-11-30T09:01:30.739Z',
    capabilities: {
      radio24: true,
      radio50: false,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'nokia-phone',
      type: {
        id: 'nokia-phone',
        category: 'Smart Phone',
        brand: 'Nokia',
        name: 'Phone',
        icon: 'smartphone',
        iconV2: 'smartphone-nokia-phone',
        iconV3: 'smartphone-nokia-phone',
        source: 'rules',
        confidence: 320,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Nokia',
      name: 'Nokia Phone',
      icon: 'smartphone',
      iconV2: 'smartphone-nokia-phone',
      iconV3: 'smartphone-nokia-phone',
      source: 'rules',
      confidence: 320,
      stationary: false,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'guest',
        connectionStateChangedAt: '2022-11-30T09:01:37.855Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
  {
    mac: '44:41:28:1c:8c:23',
    keyId: 0,
    medium: 'wifi',
    connectionState: 'disconnected',
    connectionStateChangeAt: '2022-11-30T09:01:38.369Z',
    vapType: 'fronthaul',
    networkId: 'employee',
    favorite: false,
    appTime: true,
    bandSteering: {
      _version: '1.0.0',
      enable: false,
      auto: true,
    },
    clientSteering: {
      _version: '1.0.0',
      enable: true,
      auto: true,
      configSource: 'default',
    },
    qos: {
      prioritization: {
        mode: 'auto',
        realizedState: 'ignore',
      },
    },
    quarantine: {
      enable: true,
    },
    ipv6: [],
    steering: {},
    name: 'Nokia Phone new',
    icon: 'smartphone',
    iconV2: 'smartphone-nokia-phone',
    iconV3: 'smartphone-nokia-phone',
    category: 'Car',
    brand: 'Nokia',
    health: null,
    alerts: [],
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    locallyAdministeredShifty: false,
    locallyAdministeredShiftyExpired: false,
    locallyAdministeredMac: false,
    accessZone: 'home',
    firstConnectedAt: '2022-11-30T09:01:30.739Z',
    capabilities: {
      radio24: true,
      radio50: false,
      radio60: false,
    },
    features: {},
    isPartnerComponent: false,
    kind: {
      id: 'nokia-phone',
      type: {
        id: 'nokia-phone',
        category: 'Smart Phone',
        brand: 'Nokia',
        name: 'Phone',
        icon: 'smartphone',
        iconV2: 'smartphone-nokia-phone',
        iconV3: 'smartphone-nokia-phone',
        source: 'rules',
        confidence: 320,
        stationary: false,
      },
      category: 'Smart Phone',
      brand: 'Nokia',
      name: 'Nokia Phone',
      icon: 'smartphone',
      iconV2: 'smartphone-nokia-phone',
      iconV3: 'smartphone-nokia-phone',
      source: 'rules',
      confidence: 320,
      stationary: false,
      typeIdentified: true,
      ohpCapable: false,
    },
    nickname: null,
    plumeTypeIdentified: true,
    customerTypeIdentified: false,
    ohp: {
      capable: false,
    },
    networkHistory: [
      {
        networkId: 'employee',
        connectionStateChangedAt: '2022-11-30T09:01:37.855Z',
      },
    ],
    groupOfUnassignedDevices: true,
    networkAccess: {
      mode: 'auto',
    },
    channel: 44,
    freqBand: '5GL',
    leafToRoot: [
      {
        id: 'EB86200286',
        nickname: 'Extender',
      },
    ],
  },
];
