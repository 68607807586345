import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CloudBackend } from 'Consts/types';

import { CLOUDS } from 'Api/index';
import * as api from 'Api/endpoints';

import { Data, updateData } from 'State/utils';
import type { Action, RootState } from 'State/store';

import { actions as customerActions } from './customerSlice';
import { actions as locationActions } from './locationsSlice';
import { MixpanelService } from '../../trackingAnalytics/services/mixPanel.service';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';
import { exchangeTokenBell, loginWithBellPassword } from 'Api/bellEndpoints';
import { BellLoginResponse } from 'Consts/bellTypes';
import { isDevDomain, isStoredCloudAllowed } from 'subDomainConfiguration';
import { t } from 'i18next';

export type AuthState = {
  data: Data<string>;
  cloud: CloudBackend;
  coAdminData: Data<string>;
  bellAuth: Data<BellLoginResponse>;
};

let cloud =
  ((localStorage.getItem('cloud') as CloudBackend) &&
    isStoredCloudAllowed(localStorage.getItem('cloud') as CloudBackend) &&
    CLOUDS[localStorage.getItem('cloud') as CloudBackend]) ||
  (isDevDomain() ? CLOUDS.DOGFOOD : CLOUDS.GAMMA);

const initialState: AuthState = {
  data: updateData(null),
  cloud,
  coAdminData: updateData(null),
  bellAuth: updateData(null),
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: updateData(action.payload),
      };
    },
    clear(state) {
      return {
        ...state,
        data: updateData(null),
        coAdminData: updateData(null),
        bellAuth: updateData(null),
      };
    },
    setCloud(state, action: PayloadAction<CloudBackend>) {
      localStorage.setItem('cloud', action.payload);
      return {
        ...state,
        cloud: action.payload,
      };
    },
    isLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: action.payload,
          errorMessage: '',
          lastAttempt: Date.now(),
        },
      };
    },
    error(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          errorMessage: action.payload,
        },
      };
    },
    setCoAdminToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        coAdminData: updateData(action.payload),
      };
    },
    setBellAuth(state, action: PayloadAction<BellLoginResponse>) {
      return {
        ...state,
        bellAuth: updateData(action.payload),
      };
    },
  },
});

const saveJustAuthToLocalStorage = (
  token: string,
  oldCustomerId?: string,
  refreshToken?: string
): Action => {
  return async (dispatch, getState) => {
    const customerIdToSave = oldCustomerId || getState().customer.customerId;
    dispatch(actions.set(token));
    saveAuthToLocalStorage(token, customerIdToSave || '', refreshToken);
  };
};

const saveAuthToLocalStorage = (
  token: string,
  customerId: string,
  refreshToken?: string
) => {
  localStorage.setItem(
    'auth',
    JSON.stringify({ customerId, token, refreshToken })
  );
};

const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.isLoading(true));

    const { data: checkData, error: checkError } = await api.checkEmailExists({
      email,
      cloud,
    });

    if (checkError) {
      dispatch(actions.error(checkError?.message));

      return;
    }

    if (!checkData?.emailVerified) {
      dispatch(actions.error(t('onboarding.errorUnverifiedEmail')));

      return;
    }

    const { data, error } = await api.loginWithPassword({
      email,
      password,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));
      MixpanelService.getInstance().storeEvent(
        MixPanelEvents.AUTHENTICATION_FAILURE,
        {
          errorMessage: error.message,
        }
      );
      return;
    }

    if (!data) {
      dispatch(actions.error('No data'));

      return;
    }

    const { id: token, userId: customerId } = data;

    dispatch(actions.set(token));
    dispatch(customerActions.setCustomerId(customerId));
    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.AUTHENTICATION_SUCCESS,
      {
        authenticationType: 'Password',
      }
    );

    saveAuthToLocalStorage(token, customerId);
  };
};

const bellLogin = ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.isLoading(true));

    const { data, error } = await loginWithBellPassword({
      cloud,
      username: username,
      password: password,
    });

    if (error) {
      dispatch(actions.error(error.message));
      MixpanelService.getInstance().storeEvent(
        MixPanelEvents.AUTHENTICATION_FAILURE,
        {
          errorMessage: error.message,
        }
      );
      return;
    }
    if (!data || !data?.sections?.internetAccounts?.length) {
      dispatch(actions.error('No data'));

      return;
    }
    dispatch(actions.setBellAuth(data));
    if (data.sections.internetAccounts.length === 1) {
      dispatch(
        actions.loginWithExchangeToken({
          fonseLoginResponse: data,
          index: 0,
        })
      );
    }
  };
};

const loginWithExchangeToken = ({
  fonseLoginResponse,
  index = 0,
}: {
  fonseLoginResponse: BellLoginResponse;
  index?: number;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.isLoading(true));

    const { data, error } = await exchangeTokenBell({
      cloud,
      validationToken: fonseLoginResponse?.validationToken,
      b1: {
        validationToken: fonseLoginResponse?.validationToken,
        sections: fonseLoginResponse?.sections,
      },
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    if (!data) {
      dispatch(actions.error('No data'));

      return;
    }

    if (data.accounts[index].plume.reason) {
      dispatch(actions.error(data.accounts[index].plume.reason));

      return;
    }

    const token = data.accounts[index].plume.accessToken;
    const customerId = data.accounts[index].plume.customerId;
    dispatch(actions.set(token));
    dispatch(customerActions.setCustomerId(customerId));

    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.AUTHENTICATION_SUCCESS,
      {
        authenticationType: 'BellTokenExchange',
      }
    );

    saveAuthToLocalStorage(token, customerId);
  };
};

const loginSSOCallback = ({
  code,
  state,
  appId,
  partnerId,
}: {
  code: string;
  state: string;
  appId?: string;
  partnerId: string;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.isLoading(true));
    const params = {
      code: code,
      state: state,
      ...(appId ? { appId: appId } : {}),
    };

    const { data, error } = await api.provisioningPartnersCallback({
      cloud,
      partnerId,
      params,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    if (!data) {
      dispatch(actions.error('No data'));

      return;
    }

    const { sessionToken: token, refreshToken, customerId } = data;

    dispatch(actions.set(token));
    dispatch(customerActions.setCustomerId(customerId));

    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.AUTHENTICATION_SUCCESS,
      {
        authenticationType: 'External IdP',
      }
    );

    saveAuthToLocalStorage(token, customerId, refreshToken);
  };
};

const loginWithMagicLink = ({
  token,
  customerId,
}: {
  token: string;
  customerId: string;
}): Action => {
  return async (dispatch) => {
    if (!token || !customerId) {
      dispatch(actions.error('No data'));

      return;
    }

    dispatch(actions.isLoading(true));

    dispatch(token ? slice.actions.set(token) : slice.actions.clear());
    dispatch(customerActions.setCustomerId(customerId ?? null));
    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.AUTHENTICATION_SUCCESS,
      {
        authenticationType: 'Magic Link',
      }
    );
    saveAuthToLocalStorage(token, customerId);
  };
};

const loginWithSSO = ({
  partnerId,
  appId,
}: {
  partnerId: string;
  appId?: string;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.isLoading(true));

    const { data, error } = await api.loginWithSSO({
      partnerId,
      cloud,
      appId,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    if (!data) {
      dispatch(actions.error('No data'));

      return;
    }

    return data;
  };
};

const getCoAdminTokenAndSetActiveLocation = ({
  customerId,
  locationId,
  ownerId,
}: {
  customerId: string;
  locationId: string;
  ownerId: string;
}): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;
    const { data: token } = getState().auth.data;

    dispatch(actions.isLoading(true));
    if (!token) {
      return null;
    }

    const { data: coAdmintoken, error: tokenError } =
      await api.getTokenForCoAdminLocation({
        customerId,
        accessId: locationId,
        token,
        cloud,
      });

    if (tokenError) {
      dispatch(actions.error(tokenError.message));

      return;
    }

    if (coAdmintoken) {
      dispatch(actions.setCoAdminToken(coAdmintoken.id));

      const { data, error } = await api.getLocation({
        customerId: ownerId,
        locationId: locationId,
        token: coAdmintoken.id || '',
        cloud,
      });

      if (error) {
        dispatch(actions.error(error.message));
      }
      if (data) {
        data.accessedAsCoAdminLocation = true;
        dispatch(locationActions.setCoAdminLocationAsActive(data));
        return data;
      }
    }
  };
};

//loginWithSSO

const logout = (): Action => {
  return async (dispatch) => {
    dispatch(slice.actions.clear());

    localStorage.removeItem('activeLocationId');
    localStorage.removeItem('auth');
    localStorage.removeItem('customerEmail');
    localStorage.removeItem('businessInfoFeatureAnnouncementShownThisSession');
    localStorage.removeItem('appMonitoringConsentShownThisSession');

    // TODO clear store
    // hack, remove when store cleared fixed
    window.location.reload();
  };
};

export const selectors = {
  token: (state: RootState) => state.auth.data,
  cloud: (state: RootState) => state.auth.cloud,
  authError: (state: RootState) => state.auth.data.errorMessage,
  coAdminToken: (state: RootState) => state.auth.coAdminData,
  bellAuth: (state: RootState) => state.auth.bellAuth,
  locationToken: (state: RootState) => {
    return state.auth.coAdminData.data
      ? state.auth.coAdminData
      : state.auth.data;
  },
};

export const actions = {
  login,
  logout,
  loginWithMagicLink,
  loginWithSSO,
  loginSSOCallback,
  bellLogin,
  loginWithExchangeToken,
  getCoAdminTokenAndSetActiveLocation,
  saveAuthToLocalStorage,
  saveJustAuthToLocalStorage,
  ...slice.actions,
};

export default slice.reducer;
