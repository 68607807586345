const colors = {
  still50: '#F7F8FA',
  still100: '#F0F3F5',
  still200: '#DEE0E2',
  still300: '#C6C6C6',
  still400: '#8F9397',
  still500: '#5C5C5C',
  still600: '#333333',
  still700: '#212121',
  still800: '#111111',
  still900: '#0A0A0A',

  good50: '#F6FEFC',
  good100: '#E8FDF7',
  good200: '#B5F7E6',
  good300: '#87F3D7',
  good400: '#58EEC7',
  good500: '#17E3AE',
  good600: '#01A982',
  good700: '#0E8B6A',
  good800: '#095843',
  good900: '#033A2C',

  goodX50: '#F3F7F7',
  goodX100: '#DDE9E9',
  goodX200: '#C7DBDB',
  goodX300: '#AAC9C9',
  goodX400: '#8EB7B7',
  goodX500: '#72A5A5',
  goodX600: '#567C7C',
  goodX700: '#395353',
  goodX800: '#324242',
  goodX900: '#1C2929',

  sore50: '#FFF5F9',
  sore100: '#FEE6F1',
  sore200: '#FEC3DD',
  sore300: '#FD91C0',
  sore400: '#FC5FA3',
  sore500: '#FA1478',
  sore600: '#DC0562',
  sore700: '#A00347',
  sore800: '#64022D',
  sore900: '#390119',

  freeze50: '#FAF7FD',
  freeze100: '#F2EAFA',
  freeze200: '#DFCDF4',
  freeze300: '#C4A4EA',
  freeze400: '#A97AE1',
  freeze500: '#803BD2',
  freeze600: '#6327AA',
  freeze700: '#461C78',
  freeze800: '#301056',
  freeze900: '#1E0837',

  cold50: '#F5FEFF',
  cold100: '#E5FCFF',
  cold200: '#B3F5FF',
  cold300: '#80EEFF',
  cold400: '#4CE7FF',
  cold500: '#0FDCFF',
  cold600: '#00BEDB',
  cold700: '#0092A8',
  cold800: '#005D6B',
  cold900: '#012F36',

  warm50: '#FFFBEF',
  warm100: '#FFF5D5',
  warm200: '#FFEEB3',
  warm300: '#FFE280',
  warm400: '#FFD64C',
  warm500: '#FFC500',
  warmX500: '#B17395',
  warm600: '#E5B100',
  warm700: '#B28A00',
  warm800: '#664F00',
  warm900: '#3C2F01',

  hot50: '#FAFAFF',
  hot100: '#F5F5FF',
  hot200: '#E5E7FF',
  hot300: '#CCCEFF',
  hot400: '#999EFF',
  hot500: '#656CFF',
  hot600: '#575BCF',
  hot700: '#4A4A9F',
  hot800: '#3C396F',
  hot900: '#1A1A40',

  white: '#FFFFFF',
  black: '#000000',
};

export default colors;
