import { t } from 'i18next';
import { IconNames } from 'UI/Elements/Icon';
import { AutoCompleteConfiguration } from '../AutoComplete';

export const getDefaultConfiguration = (
  config?: Partial<AutoCompleteConfiguration>
): AutoCompleteConfiguration => {
  return {
    icon: IconNames.Search,
    noResultsText: t('search.noResults'),
    placeholderText: t('search.searchPlaceholder'),
    closeOnSelectItem: true,
    minCharactersToSearch: 0,
    displayFilters: true,
    ...config,
  };
};
