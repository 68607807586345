import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployeeLoginDatabase = () =>
  useUpdatedData({
    selector: selectors.settings.employeeLogin,
    action: actions.settings.employeeLogin.fetchData(),
  });

export default useEmployeeLoginDatabase;
