import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { CustomerId, Customer } from 'Consts/types';
import * as api from 'Api/endpoints';
import { Data, updateData } from 'State/utils';
import type { RootState, Action } from 'State/store';

export type CustomerState = {
  customerId: CustomerId | null;
  data: Data<Customer>;
};

const initialState: CustomerState = {
  customerId: null,
  data: updateData(null),
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    set(state, action: PayloadAction<Customer>) {
      return {
        ...state,
        data: updateData(action.payload),
        customerId: action.payload.id,
      };
    },
    setCustomerId(state, action: PayloadAction<CustomerId | null>) {
      return {
        ...state,
        customerId: action.payload,
      };
    },
    isLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: action.payload,
          errorMessage: '',
          lastAttempt: Date.now(),
        },
      };
    },
    error(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          errorMessage: action.payload,
        },
      };
    },
  },
});

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { customerId, data: customerData } = getState().customer;
    const { data: tokenData, cloud } = getState().auth;
    const token = tokenData.data;

    if (customerData.isLoading || !customerId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    try {
      const { data, error } = await api.getCustomer({
        customerId,
        token,
        cloud,
      });

      if (error || !data) {
        dispatch(actions.error(error?.message || 'Error fetching customer'));
        return;
      }

      dispatch(actions.set(data));
    } catch (error) {
      dispatch(actions.error('Unexpected error fetching customer'));
    }
  };
};

export const selectors = {
  id: (state: RootState) => state.customer.customerId,
  data: (state: RootState) => state.customer.data,
};

export const actions = {
  fetchData,
  ...slice.actions,
};

export default slice.reducer;
