import type {
  BandwidthLimit,
  NetworkType,
  WifiNetwork,
  FronthaulNetwork,
  CaptivePortalNetwork,
  CustomerId,
  LocationId,
  Token,
  CloudBackend,
} from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';
import { isDevDomain } from 'subDomainConfiguration';

export type WifiData = {
  networkId: NetworkType;
  enable: boolean;
  ssid: string;
  encryptionKey: string;
};

export type GuestWifiData = {
  networkId: NetworkType;
  enable: boolean;
  ssid: string;
  encryptionKey?: string;
  bandwidthLimit?: BandwidthLimit;
};

export type WifiSettingsData = {
  default: WifiData;
  employee: WifiData | null;
  guest: GuestWifiData | null;
};

export type GuestWifiUpdateProps = {
  enable?: boolean;
  ssid?: string;
  bandwidthLimit?: BandwidthLimit;
};

const slice = createStandardSlice<WifiSettingsData>('wifi');

const parseDefaultWifiData = (wifi: WifiNetwork): WifiData => ({
  networkId: 'default',
  enable: true,
  ssid: wifi.ssid,
  encryptionKey: wifi.encryptionKey,
});

const parseEmployeeWifiData = (wifi: FronthaulNetwork): WifiData => ({
  networkId: wifi.networkId,
  enable: wifi.enable,
  ssid: wifi.ssid,
  encryptionKey: wifi.encryptionKey,
});

const parseGuestWifiData = (wifi: CaptivePortalNetwork): GuestWifiData => ({
  networkId: wifi.networkId,
  enable: wifi.enable,
  ssid: wifi.ssid,
  bandwidthLimit: wifi.bandwidthLimit,
  encryptionKey: wifi.encryptionKey,
});

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const wifiData = getState().settings.wifi;

    if (
      wifiData.isLoading ||
      !customerId ||
      !activeLocation?.id ||
      !activeLocation?.ssid ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    Promise.all([
      api.getDefaultWifiNetwork({
        customerId,
        locationId: activeLocation.id,
        token,
        cloud,
      }),
      api.getEmployeeWifiNetworks({
        customerId,
        locationId: activeLocation.id,
        token,
        cloud,
      }),
      api.getGuestWifiNetworks({
        customerId,
        locationId: activeLocation.id,
        token,
        cloud,
      }),
    ])
      .then(([wifi, employee, guest]) => {
        const { data: wifiNetworkData, error: wifiNetworkError } = wifi;
        const { data: employeeData, error: employeeError } = employee;
        const { data: guestData, error: guestError } = guest;

        if (
          wifiNetworkError ||
          employeeError ||
          guestError ||
          !wifiNetworkData
        ) {
          dispatch(
            actions.error(
              wifiNetworkError?.message ||
                employeeError?.message ||
                guestError?.message ||
                ''
            )
          );

          return;
        }

        const data = {
          default: parseDefaultWifiData(wifiNetworkData.wifiNetwork),
          employee: employeeData?.[0]
            ? parseEmployeeWifiData(employeeData[0])
            : null,
          guest: guestData?.[0] ? parseGuestWifiData(guestData[0]) : null,
        };

        dispatch(actions.set(data));
      })
      .catch((err) => dispatch(actions.error(err)));
  };
};

const updateDefaultWifiData = (
  {
    encryptionKey,
    ssid,
  }: {
    encryptionKey?: string;
    ssid?: string;
  },
  onSuccess?: () => void,
  onError?: (msg?: string) => void
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const wifiData = getState().settings.wifi.data;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !customerId ||
      !activeLocation?.id ||
      !activeLocation?.ssid ||
      !token ||
      (!ssid && !encryptionKey) ||
      !wifiData
    ) {
      return;
    }

    const { data, error } = await api.updateDefaultWifiNetwork({
      customerId,
      locationId: activeLocation.id,
      ssid: ssid ?? wifiData.default.ssid,
      encryptionKey: encryptionKey ?? wifiData?.default.encryptionKey,
      token,
      cloud,
    });

    if (error || !data?.wifiNetwork) {
      onError && onError(error?.message);

      return;
    }

    dispatch(
      actions.set({
        default: parseDefaultWifiData(data.wifiNetwork),
        employee: wifiData.employee || null,
        guest: wifiData.guest || null,
      })
    );
    onSuccess && onSuccess();
  };
};

const updateEmployeeWifiData = (
  {
    encryptionKey,
    ssid,
  }: {
    encryptionKey?: string;
    ssid?: string;
  },
  onSuccess?: () => void,
  onError?: (msg?: string) => void
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const wifiData = getState().settings.wifi.data;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !customerId ||
      !activeLocation?.id ||
      !activeLocation?.ssid ||
      !token ||
      (!ssid && !encryptionKey) ||
      !wifiData
    ) {
      return;
    }

    const args: {
      customerId: CustomerId;
      locationId: LocationId;
      token: Token;
      ssid?: string;
      encryptionKey?: string;
      cloud: CloudBackend;
    } = {
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    };

    if (ssid) {
      args.ssid = ssid;
    }

    if (encryptionKey) {
      args.encryptionKey = encryptionKey;
    }

    const { data, error } = await api.updateEmployeeWifiNetwork(args);

    if (error || !data) {
      onError && onError(error?.message);

      return;
    }

    dispatch(
      actions.set({
        default: wifiData.default,
        employee: parseEmployeeWifiData(data),
        guest: wifiData.guest || null,
      })
    );
    onSuccess && onSuccess();
    dispatch(actions.fetchData());
  };
};

const updateGuestsWifiData = (
  {
    ssid,
    enable,
    bandwidthLimit,
  }: {
    ssid?: string;
    enable?: boolean;
    bandwidthLimit?: BandwidthLimit;
  },
  onSuccess?: () => void,
  onError?: (msg?: string) => void
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: wifiData } = getState().settings.wifi;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !customerId ||
      !activeLocation?.id ||
      !activeLocation?.ssid ||
      !token ||
      !wifiData
    ) {
      return;
    }

    const saveData: {
      ssid?: string;
      enable?: boolean;
      bandwidthLimit?: BandwidthLimit;
    } = {};

    if (ssid) {
      saveData.ssid = ssid;
    }

    if (enable !== undefined) {
      saveData.enable = enable;
    }

    if (bandwidthLimit) {
      saveData.bandwidthLimit = bandwidthLimit;
    }

    const { data, error } = await api.updateGuestWifiNetwork({
      customerId,
      locationId: activeLocation.id,
      data: saveData,
      token,
      cloud,
    });

    if (error || !data) {
      onError && onError(error?.message);

      return;
    }

    dispatch(
      actions.set({
        default: wifiData.default,
        employee: wifiData.employee || null,
        guest: parseGuestWifiData(data),
      })
    );
    onSuccess && onSuccess();
  };
};

const getCaptivePortalUrl = (state: RootState): string | undefined => {
  const { activeLocation } = state.locations;
  const customerId = activeLocation?.customerId;
  const { data: token } = state.locations.activeLocation
    ?.accessedAsCoAdminLocation
    ? state.auth.coAdminData
    : state.auth.data;
  const cloud = state.auth.cloud.toLowerCase();

  if (!customerId || !activeLocation?.id || !token) {
    return;
  }

  const workpassCaptivePortalEditorDomain = isDevDomain()
    ? 'https://workpass-dev.plume.com'
    : 'https://workpass.plume.com';
  return `${workpassCaptivePortalEditorDomain}/captiveportal/prepare?cloud=${cloud}&token=${token}&customerid=${customerId}&locationid=${activeLocation?.id}&networkid=guest`;
};

export const selectors = {
  all: (state: RootState) => state.settings.wifi,
  default: (state: RootState) => state.settings.wifi.data?.default,
  employee: (state: RootState) => state.settings.wifi.data?.employee,
  guest: (state: RootState) => state.settings.wifi.data?.guest,
  captivePortalUrl: (state: RootState) => getCaptivePortalUrl(state),
};

export const actions = {
  ...slice.actions,
  fetchData,
  updateDefaultWifiData,
  updateEmployeeWifiData,
  updateGuestsWifiData,
};

export default slice.reducer;
