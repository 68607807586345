import { useSelector } from 'react-redux';
import * as selectors from '../../State/selectors';
import { MixpanelService } from '../services/mixPanel.service';

export const useMixPanelRegistration = () => {
  const cloudEnv = useSelector(selectors.auth.cloud);
  const customerId = useSelector(selectors.customer.id) || undefined;
  const customerData = useSelector(selectors.customer.data);
  const activeLocationId = useSelector(selectors.locations.activeLocationId);
  const customerEmail = customerData.data?.email || undefined;
  const partnerId = customerData.data?.partnerId || '';
  MixpanelService.getInstance().setUser({
    id: customerId,
    emailAddress: customerEmail,
  });
  if (customerId != null) {
    MixpanelService.getInstance().setCustomerId(customerId);
  }
  if (cloudEnv != null) {
    MixpanelService.getInstance().setEnvironment(cloudEnv);
  }
  if (partnerId != null) {
    MixpanelService.getInstance().setPartnerId(partnerId);
  }
  if (activeLocationId != null) {
    MixpanelService.getInstance().setLocation(activeLocationId || '');
  }
};
