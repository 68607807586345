import { createContext } from 'react';
import { AutoCompleteContextValues } from './types';
import { getDefaultConfiguration } from './helpers/configuration.helper';

const defaultValues = getDefaultConfiguration();

export const AutoCompleteContext = createContext<AutoCompleteContextValues>({
  configuration: defaultValues,
  isLoading: false,
  availableFilters: undefined,
  closeSearchResults: () => {},
});
