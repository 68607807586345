import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Action, RootState } from '../../store';

type Notification = {
  state: 'unset' | 'set' | 'default';
  label: string;
};

type UIDevicesState = {
  notifications: Notification[];
};

const initialState: UIDevicesState = {
  notifications: [],
};

const slice = createSlice({
  name: 'miniBanner',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    shift: (state) => {
      state.notifications.shift();
    },
  },
});

const MINIBANNER_TIMEOUT = 4000;

const notify = (notification: Notification): Action => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.push(notification));

    setTimeout(() => {
      dispatch(slice.actions.shift());
    }, MINIBANNER_TIMEOUT);
  };
};

export const selectors = (state: RootState) =>
  state.ui.miniBanner.notifications;

export const actions = {
  notify,
};

export default slice.reducer;
