import cn from 'classnames';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { disableScroll, enableScroll } from 'Utils/pageScroll';

import { getConfigurationFromDomain } from 'subDomainConfiguration';
import { onEscapeKeyDown } from 'Utils/keyboardEvents';
import { Nullable } from '../../../Consts/types';
import styles from './style.module.css';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

import { memo } from 'react';

const BELL = 'bell';

type ModalProps = {
  overlayClassName?: string;
  wrapperClassName?: string;
  isOpen: boolean;
  dark?: boolean;
  parent?: Nullable<HTMLElement>;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClose: () => void;
};

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      overlayClassName,
      wrapperClassName,
      isOpen,
      children,
      parent,
      onClose,
      style,
      dark,
      ...rest // used by popover
    },
    ref
  ) => {
    const { ref: modalRef, focusTriggerElement } = useTrapFocus<HTMLDivElement>(
      { trapFocus: true }
    );
    const environment = getConfigurationFromDomain();

    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
      setIsMounted(true);
    }, []);

    const theme = useMemo(() => {
      return environment.id === BELL ? 'bell' : '';
    }, [environment]);

    const close = () => {
      onClose();
      focusTriggerElement();
    };

    const onEscape = (e: KeyboardEvent) => onEscapeKeyDown(e, close);

    useEffect(() => {
      if (isOpen) {
        window.addEventListener('keydown', onEscape);
        disableScroll();
      } else {
        window.removeEventListener('keydown', onEscape);
        enableScroll();
      }
      return () => {
        window.removeEventListener('keydown', onEscape);
      };
    }, [isOpen]);

    const modal = (
      <CSSTransition
        nodeRef={modalRef}
        in={isMounted && isOpen}
        className={styles.modal}
        classNames={{
          enter: styles.modalEnter,
          enterActive: styles.modalEnterActive,
          exit: styles.modalExit,
          exitActive: styles.modalExitActive,
        }}
        unmountOnExit
        timeout={200}
      >
        <div
          ref={modalRef}
          color-theme={theme}
          onKeyDown={(e) => {
            if (e.key === 'Escape') close();
          }}
        >
          <div
            className={cn(
              styles.modalOverlay,
              { [styles.dark]: dark },
              overlayClassName
            )}
            onClick={close}
          />

          <div
            ref={ref}
            className={cn(styles.modalContentWrapper, wrapperClassName)}
            style={style}
            {...rest}
          >
            {children}
          </div>
        </div>
      </CSSTransition>
    );

    return createPortal(
      modal,
      parent ?? (document.getElementById('root') as HTMLElement)
    );
  }
);
export default memo(Modal);
