import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { OpenTriggerEventType } from 'Consts/types';

type UIDevicesState = {
  navPanelHidden: boolean;
  sidepanelOpen: boolean;
  sidepanelIsClosing: boolean;
  prevSettingsRoute: string | null;
  sidepanelOpenTriggerType: OpenTriggerEventType | null;
};

const initialState: UIDevicesState = {
  navPanelHidden: false,
  sidepanelOpen: false,
  sidepanelIsClosing: false,
  prevSettingsRoute: null,
  sidepanelOpenTriggerType: null,
};

const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setNavPanelHidden: (state, action: PayloadAction<boolean>) => {
      state.navPanelHidden = action.payload;
    },
    setSidepanelOpen: (state, action: PayloadAction<boolean>) => {
      state.sidepanelOpen = action.payload;
    },
    setSidepanelIsClosing: (state, action: PayloadAction<boolean>) => {
      state.sidepanelIsClosing = action.payload;
    },
    prevSettingsRoute: (state, action: PayloadAction<string | null>) => {
      state.prevSettingsRoute = action.payload;
    },
    setSidepanelOpenTriggerType: (
      state,
      action: PayloadAction<OpenTriggerEventType | null>
    ) => {
      state.sidepanelOpenTriggerType = action.payload;
    },
  },
});

export const selectors = {
  navPanelHidden: (state: RootState) => state.ui.page.navPanelHidden,
  sidepanelOpen: (state: RootState) => state.ui.page.sidepanelOpen,
  sidepanelIsClosing: (state: RootState) => state.ui.page.sidepanelIsClosing,
  prevSettingsRoute: (state: RootState) => state.ui.page.prevSettingsRoute,
  sidepanelOpenTriggerType: (state: RootState) =>
    state.ui.page.sidepanelOpenTriggerType,
};

export const actions = slice.actions;

export default slice.reducer;
