import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';
import { Body2 } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';
import { Nullable } from '../../../Consts/types';
import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

import { memo } from 'react';

export type Props = {
  leftIconName?: IconName;
  label?: Nullable<string>;
  rightIconName?: IconName;
  className?: string;
  isFilterTab?: boolean;
  onClick?: React.MouseEventHandler;
};

const PillLarge: FunctionComponent<Props> = ({
  leftIconName,
  label,
  rightIconName,
  className,
  isFilterTab = false,
  onClick,
}) => {
  const componentClasses = cn(
    styles.pillLarge,
    colorStyles.still800,
    {
      [styles.interactive]: !!onClick,
      [styles.label]: !leftIconName && label && !rightIconName,
      [styles.iconLabel]: leftIconName && label && !rightIconName,
      [styles.labelIcon]: !leftIconName && label && rightIconName,
      [styles.iconLabelIcon]: leftIconName && label && rightIconName,
      [styles.icon]: leftIconName && !label && !rightIconName,
    },
    className
  );

  if (!leftIconName && !label && !rightIconName) {
    return null;
  }

  return (
    <>
      <button
        className={componentClasses}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
        role={isFilterTab ? 'tab' : undefined}
        onKeyDown={(e) => onEnterKeydown(e, onClick)}
      >
        {!!leftIconName && <Icon name={leftIconName} />}
        {!!label && <Body2>{label}</Body2>}
        {!!rightIconName && <Icon name={rightIconName} />}
      </button>
    </>
  );
};
export default memo(PillLarge);
