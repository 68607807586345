import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Body3 } from 'UI/Elements/Typography';

import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

import { memo } from 'react';

type PillSmallProps = {
  className?: string;
  variant?: 'good' | 'poor';
  onClick?: React.MouseEventHandler;
};

const PillSmall: FunctionComponent<PropsWithChildren<PillSmallProps>> = ({
  className,
  children,
  variant,
  onClick,
}) => {
  return (
    <div
      className={cn(
        styles.pillSmall,
        { [styles.interactive]: !!onClick },
        { [styles.good]: variant === 'good' },
        { [styles.poor]: variant === 'poor' },
        className
      )}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      aria-label={children?.toString()}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      <Body3 className={styles.label}>{children}</Body3>
    </div>
  );
};
export default memo(PillSmall);
