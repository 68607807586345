import type {
  RecursivePartial,
  SecurityContentTypes,
  Mac,
  DeviceSecurityPolicy,
  SecurityPolicy,
} from 'Consts/types';
import { SECURITY_CONTENT } from 'Consts/defintions';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';
import * as globalActions from '../../State/actions';
import {
  PolicyItemsProps,
  LocationItemsProps,
} from './settings/securityPolicySlice';

export type DeviceShieldSettingsProps = PolicyItemsProps & LocationItemsProps;

const slice = createStandardSlice<DeviceSecurityPolicy>('deviceSecurityPolicy');

const fetchData = (mac: Mac): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const deviceSecurityPolicyData = getState().deviceSecurityPolicy;

    if (
      deviceSecurityPolicyData.isLoading ||
      !customerId ||
      !activeLocation?.id ||
      !token ||
      !mac
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDeviceSecurityPolicy({
      customerId,
      locationId: activeLocation.id,
      token,
      mac,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching device security policy')
      );

      return;
    }

    dispatch(actions.set(data));
  };
};

const updateActiveDeviceAppTime = (
  appTimeEnabled: boolean,
  mac: Mac
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    const { error } = await api.updateDeviceAppTime({
      customerId,
      locationId: activeLocationId,
      mac,
      data: { enable: appTimeEnabled },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(globalActions.device.fetchData(mac));
    dispatch(globalActions.settings.securityPolicy.fetchData());
  };
};

const updateDeviceSecurityPolicy = (
  mac: Mac,
  info: Partial<PolicyItemsProps>
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().deviceSecurityPolicy;
    const securityPolicy = securityPolicyData.data?.securityPolicy;

    if (!customerId || !activeLocation?.id || !token || !mac) {
      return;
    }

    let payload: RecursivePartial<SecurityPolicy> = {};

    if (securityPolicy) {
      if (typeof info.adBlocking === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.adBlocking) {
          content = [...securityPolicy.content, SECURITY_CONTENT.adBlocking];
        } else {
          content = securityPolicy?.content?.filter(
            (el) => el !== SECURITY_CONTENT.adBlocking
          );
        }

        payload = {
          content,
        };
      }

      if (typeof info.workAppropriate === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.workAppropriate) {
          content = [
            ...securityPolicy.content,
            SECURITY_CONTENT.workAppropriate,
          ];
        } else {
          content = securityPolicy?.content?.filter(
            (el) => el !== SECURITY_CONTENT.workAppropriate
          );
        }

        payload = {
          content,
        };
      }

      if (typeof info.secureAndProtect === 'boolean') {
        if (!info.secureAndProtect && securityPolicy.iotProtect) {
          payload = {
            ...info,
            iotProtect: false,
          };
        } else {
          payload = {
            ...info,
          };
        }
      }

      if (typeof info.iotProtect === 'boolean') {
        if (info.iotProtect && !securityPolicy.secureAndProtect) {
          payload = {
            ...info,
            secureAndProtect: true,
          };
        } else {
          payload = { ...info };
        }
      }
    }

    const { data, error } = await api.updateDeviceSecurityPolicy({
      customerId,
      locationId: activeLocation.id,
      mac,
      data: payload,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error updating security policy')
      );

      return;
    }

    dispatch(actions.fetchData(mac));
    dispatch(globalActions.settings.securityPolicy.fetchData());
  };
};

export const selectors = {
  deviceSecurityPolicy: (state: RootState) => state.deviceSecurityPolicy,
  deviceShieldSettings: (state: RootState) => {
    const locations = state.locations.ownedLocations;
    const deviceSecurityData = state.deviceSecurityPolicy;
    const device = state.device.data;

    return {
      isLoading: locations.isLoading || deviceSecurityData.isLoading,
      errorMessage: locations.errorMessage || deviceSecurityData.errorMessage,
      data: {
        appTime: device.data?.appTime || false,
        workAppropriate:
          deviceSecurityData.data?.securityPolicy.content?.includes(
            'workAppropriate'
          ) || false,
        secureAndProtect:
          deviceSecurityData.data?.securityPolicy.secureAndProtect || false,
        iotProtect: deviceSecurityData.data?.securityPolicy.iotProtect || false,
        adBlocking:
          deviceSecurityData.data?.securityPolicy.content?.includes(
            'adBlocking'
          ) || false,
      },
    };
  },
};

export const actions = {
  ...slice.actions,
  fetchData,
  updateDeviceSecurityPolicy,
  updateActiveDeviceAppTime,
};

export default slice.reducer;
