import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import en_CA from './locales/en_CA.json';
import fr from './locales/fr.json';
import fr_CA from './locales/fr_CA.json';
import ja from './locales/ja.json';
import pt from './locales/pt.json';
import es from './locales/es.json';
import de from './locales/de.json';
import et from './locales/et.json';
import en_bell_overrides from './locales/cspSpecific/bell/en_bell.json';
import en_CA_bell_overrides from './locales/cspSpecific/bell/en_CA_bell.json';
import fr_CA_bell_overrides from './locales/cspSpecific/bell/fr_CA_bell.json';
import nl from './locales/nl.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.REACT_APP_APP_ENV !== 'production',
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'],
    },
    resources: {
      en: {
        translation: en,
        bell: en_bell_overrides,
      },
      en_CA: {
        translation: en_CA,
        bell: en_CA_bell_overrides,
      },
      fr: { translation: fr, bell: fr_CA_bell_overrides },
      'fr-CA': { translation: fr_CA, bell: fr_CA_bell_overrides },
      ja: { translation: ja, bell: ja },
      pt: { translation: pt, bell: pt },
      es: { translation: es, bell: es },
      de: { translation: de, bell: de },
      et: { translation: et, bell: et },
      nl: { translation: nl, bell: nl },
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackNS: 'translation',
    returnNull: false,
  });

export default i18n;
