import React, { FunctionComponent } from 'react';

import { memo } from 'react';

const NotFound: FunctionComponent = () => {
  return (
    <div>
      <h1>Not Found</h1>
      <a href="/">Home</a>
    </div>
  );
};
export default memo(NotFound);
