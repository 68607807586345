import React, { FunctionComponent, useState, useEffect } from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import merge from 'lodash/merge';
import Modal from '../';
import { Nullable } from '../../../../Consts/types';
import { memo } from 'react';

const DEFAULT_CONFIG: Partial<PopperJS.Options> = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [0, 16] },
    },
    {
      name: 'computeStyles',
      options: {
        adaptive: true,
      },
    },
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
      },
    },
  ],
  placement: 'bottom-end',
};

type ActionSheetProps = {
  parent: Nullable<Element>;
  popperOptions?: PopperJS.Options;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
};

const ActionSheet: FunctionComponent<ActionSheetProps> = ({
  parent,
  popperOptions,
  isOpen,
  className,
  children,
  onClose,
}) => {
  const [popperWrapper, setPopperWrapper] = useState<HTMLElement | null>(null);

  const finalOptions = merge({}, DEFAULT_CONFIG, popperOptions);

  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(parent, popperWrapper, finalOptions);

  useEffect(() => {
    if (update && isOpen && parent) {
      requestAnimationFrame(() => {
        update();
      });
    }
  }, [update, isOpen, parent]);

  return (
    <Modal
      wrapperClassName={className}
      isOpen={isOpen}
      onClose={onClose}
      ref={setPopperWrapper}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {children}
    </Modal>
  );
};

export default memo(ActionSheet);
