import type { DeviceGroup, Mac, NetworkType } from 'Consts/types';
import { NETWORK_IDS } from 'Consts/defintions';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

type DeviceGroupsByZone = {
  default: DeviceGroup[];
  employee: DeviceGroup[];
  guest: DeviceGroup[];
};

const slice = createStandardSlice<DeviceGroupsByZone>('deviceGroups');

const fetchData = (networkId: NetworkType = NETWORK_IDS.default): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDeviceGroups({
      customerId,
      locationId: activeLocation.id,
      networkId,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching device groups'));

      return;
    }

    dispatch(actions.update({ [networkId]: data }));
  };
};

const addOrUpdateGroup = ({
  networkId,
  groupId,
  name,
  devices,
}: {
  networkId: NetworkType;
  groupId?: string;
  name: string;
  devices?: Mac[];
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const params = {
      customerId,
      locationId: activeLocationId,
      networkId,
      data: {
        name,
        devices,
      },
      token,
      cloud,
    };

    const { data, error } = await (groupId
      ? api.updateDeviceGroup({ ...params, groupId })
      : api.addDeviceGroup(params));

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error adding or updating device group')
      );

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(networkId));
  };
};

const deleteGroup = ({
  networkId,
  groupId,
}: {
  networkId: NetworkType;
  groupId: string;
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const params = {
      customerId,
      locationId: activeLocationId,
      networkId,
      groupId,
      token,
      cloud,
    };

    const { error } = await api.deleteDeviceGroup(params);

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(networkId));
  };
};

export const selectors = (state: RootState) => state.zones.deviceGroups;

export const actions = {
  ...slice.actions,
  fetchData,
  addOrUpdateGroup,
  deleteGroup,
};

export default slice.reducer;
