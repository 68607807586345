import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Pod } from 'Consts/types';

import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import Alert from 'UI/Components/Alert';
import StandardListItem from 'UI/Components/Lists/List standard';

import AlertModal from 'UI/Elements/Modals/Alerts';
import { BUTTON_THEMES } from 'UI/Elements/Button';

import styles from './style.module.css';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';

type PodInfoProps = {
  pod: Pod;
};

export const PodInfo: FunctionComponent<PodInfoProps> = ({ pod }) => {
  const { t } = useTranslation();

  return (
    <SimpleSidePanel title={t('settings.hardwareInfo')}>
      <SidePanelContentWrapper className={styles.sidepanelContentWrapper}>
        <StandardListItem
          L2Props={{ label: t('settings.modelName') }}
          RProps={{ smallLabel: pod.model }}
          ariaLabel={t('settings.modelName')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.firmware') }}
          RProps={{ smallLabel: pod.firmwareVersion }}
          ariaLabel={t('settings.firmware')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.EthernetMac1') }}
          RProps={{ smallLabel: pod.mac }}
          ariaLabel={t('settings.EthernetMac1')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.EthernetMac2') }}
          RProps={{ smallLabel: pod.ethernet1Mac }}
          ariaLabel={t('settings.EthernetMac2')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.nodeSerial') }}
          RProps={{ smallLabel: pod.serialNumber }}
          ariaLabel={t('settings.nodeSerial')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.sim') }}
          RProps={{ smallLabel: '---' }}
          ariaLabel={t('settings.sim')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.imei') }}
          RProps={{ smallLabel: '---' }}
          ariaLabel={t('settings.imei')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.2.4GMac') }}
          RProps={{ smallLabel: pod.radioMac24 }}
          ariaLabel={t('settings.2.4GMac')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.5GMac1') }}
          RProps={{ smallLabel: pod.radioMac50L }}
          ariaLabel={t('settings.5GMac1')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.5GMac2') }}
          RProps={{ smallLabel: pod.radioMac50U }}
          ariaLabel={t('settings.5GMac2')}
        />

        <StandardListItem
          L2Props={{ label: t('settings.publicIp') }}
          RProps={{ smallLabel: '---' }}
          ariaLabel={t('settings.publicIp')}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

type PodAlertProps = {
  isOpen: boolean;
  pod: Pod | null;
  onClose: () => void;
};

type RenamePodProps = PodAlertProps & {
  onPodChange: (data: { id: string; nickname: string }) => void;
};

type DeletePodProps = PodAlertProps & {
  onPodDelete: (id: string) => void;
};

type LanEtherentPodProps = PodAlertProps & {
  onToggleEthernet: (id: string, lanCurrentlyEnabled: boolean) => void;
  lanCurrentlyEnabled?: boolean;
};

export const RenamePod: FunctionComponent<RenamePodProps> = ({
  isOpen,
  pod,
  onPodChange,
  onClose,
}) => {
  const [name, setName] = useState(pod?.nickname || pod?.defaultName || '');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const handleChange = useCallback((value: string) => {
    setName(value);
  }, []);

  const handleSave = useCallback(() => {
    if (!pod) {
      return;
    }

    setError('');

    if (!name) {
      setError(
        t('settings.validationPodNameNotEmpty', { ns: namespace }) ||
          'Pod must have a name'
      );

      return;
    }

    if (name.length > 18) {
      setError(
        t('settings.validationPodMaxChar') ||
          'The name can be max 18 characters'
      );

      return;
    }

    onPodChange({ id: pod.id, nickname: name });
    onClose();
  }, [name, onClose, onPodChange, pod, t]);

  useEffect(() => {
    if (pod) {
      setName(pod.nickname || pod.defaultName);
    }
  }, [pod]);

  return (
    <AlertModal isOpen={isOpen} onClose={onClose}>
      <Alert
        topProps={{
          label: t('settings.renamePod', { ns: namespace }) || 'Rename pod',
        }}
        middleProps={{
          listInputProps: {
            value: name,
            onChange: handleChange,
            paragraph: error ? error : '',
            onSubmit: handleSave,
            hasError: !!error,
          },
        }}
        bottomProps={{
          button1Props: {
            label: t('common.save'),
            onClick: handleSave,
          },
          button2Props: {
            label: t('common.cancel'),
            onClick: onClose,
            theme: BUTTON_THEMES.white,
          },
        }}
      />
    </AlertModal>
  );
};

export const DeletePod: FunctionComponent<DeletePodProps> = ({
  isOpen,
  onClose,
  pod,
  onPodDelete,
}) => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const onDelete = useCallback(() => {
    if (!pod) {
      return;
    }

    onPodDelete(pod.id);
    onClose();
  }, [onClose, onPodDelete, pod]);

  return (
    <AlertModal isOpen={isOpen} onClose={onClose}>
      <Alert
        topProps={{
          label:
            t('settings.deletePod', { ns: namespace }) + '?' || 'Delete pod?',
          paragraph:
            t('settings.deletePodText', { ns: namespace }) ||
            'Deleting this pod will remove it from this account. It could then be added to any Plume location.',
        }}
        bottomProps={{
          button1Props: {
            label: t('settings.deletePod', { ns: namespace }) || 'Delete pod',
            onClick: onDelete,
          },
          button2Props: {
            label: t('common.cancel'),
            onClick: onClose,
            theme: BUTTON_THEMES.white,
          },
        }}
      />
    </AlertModal>
  );
};

export const LanEthernetPod: FunctionComponent<LanEtherentPodProps> = ({
  isOpen,
  onClose,
  pod,
  onToggleEthernet,
  lanCurrentlyEnabled = true,
}) => {
  const { t } = useTranslation();

  const onToggleEthernetChange = useCallback(() => {
    if (!pod) {
      return;
    }

    onToggleEthernet(pod.id, lanCurrentlyEnabled);
    onClose();
  }, [onClose, onToggleEthernet, pod]);

  return (
    <AlertModal isOpen={isOpen} onClose={onClose}>
      {lanCurrentlyEnabled && (
        <Alert
          topProps={{
            label: t('settings.disableLanTitle'),
            paragraph: t('settings.disableLanText'),
          }}
          bottomProps={{
            button1Props: {
              label: t('common.disable'),
              onClick: onToggleEthernetChange,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: onClose,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      )}
      {!lanCurrentlyEnabled && (
        <Alert
          topProps={{
            label: t('settings.enableLanTitle'),
            paragraph: t('settings.enableLanText'),
          }}
          bottomProps={{
            button1Props: {
              label: t('common.enable'),
              onClick: onToggleEthernetChange,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: onClose,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      )}
    </AlertModal>
  );
};
