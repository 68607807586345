import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'Consts/types';
import type { RootState, Action } from 'State/store';

export type ApplicationInfo = {
  appId: string;
  appName: string;
};

export type ApplicationInfoState = {
  applications: Nullable<ApplicationInfo[]>;
};

const initialState: ApplicationInfoState = {
  applications: null,
};

const slice = createSlice({
  name: 'applicationInfo',
  initialState,
  reducers: {
    update(state, action: PayloadAction<ApplicationInfo>) {
      if (!state.applications) {
        return { applications: [action.payload] };
      } else {
        return { applications: [...state.applications, action.payload] };
      }
    },
  },
});

const updateAppNames = (appId: string, appName: string): Action => {
  return (dispatch) => {
    dispatch(actions.update({ appId, appName }));
  };
};

export const selectors = (state: RootState) => state.applicationInfo;

export const actions = {
  ...slice.actions,
  updateAppNames,
};

export default slice.reducer;
