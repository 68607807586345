import type { AutoRun, SpeedTestResults } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<SpeedTestResults>('speedTest');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const speedTest = getState().speedTest;

    if (speedTest.isLoading || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getIspSpeedTest({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching speed test data')
      );

      return;
    }

    dispatch(actions.set(data));
  };
};

const updateSpeedTest = (info: Partial<AutoRun>): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));
    const { data, error } = await api.updateIspSpeedTest({
      customerId,
      locationId: activeLocationId,
      data: info,
      token,
      cloud,
    });

    if (error || !data?.ispSpeedTestConfiguration) {
      dispatch(
        actions.error(error?.message || 'Error updating speed test data')
      );

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.speedTest;

export const actions = {
  ...slice.actions,
  fetchData,
  updateSpeedTest,
};

export default slice.reducer;
