import { Guest } from 'Consts/types';

export const guestResp: {
  connectedNowCount: number;
  guests: Guest[];
} = {
  connectedNowCount: 3,
  guests: [
    {
      socialNetworkId: '8a:c8:2a:66:b9:48',
      fullName: 'Free WiFi User',
      mac: '8a:c8:2a:66:b9:48',
      txBytes: 3222252223,
      rxBytes: 1254111232,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 1,
      deviceType: {
        category: 'Laptop',
        name: 'Apple Macintosh',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-08T16:44:12.249Z',
    },
    {
      socialNetworkId: '8a:c8:2a:66:b9:41',
      fullName: 'Steve Jobs',
      mac: '8a:c8:2a:66:b9:41',
      txBytes: 632222523,
      rxBytes: 72541112,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 3,
      deviceType: {
        category: 'Smart Phone',
        name: 'Apple iPhone',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-08T12:44:12.249Z',
    },
    {
      socialNetworkId: '8a:c8:2a:66:b2:41',
      fullName: 'Iron Man',
      mac: '8a:c8:2a:66:b2:41',
      txBytes: 362222523,
      rxBytes: 125411132,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 1,
      deviceType: {
        category: 'Car',
        name: 'Tesla',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-08T12:44:12.249Z',
    },
    {
      socialNetworkId: '02:d9:dc:90:db:1f',
      fullName: 'Free WiFi User',
      mac: '02:d9:dc:90:db:1f',
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 1,
      deviceType: {
        category: 'Smart Phone',
        name: 'Galaxy-S21-Ultra-5G',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-08T16:41:49.474Z',
    },
    {
      socialNetworkId: '30:07:4d:0f:cf:6b',
      fullName: 'Free WiFi User',
      mac: '30:07:4d:0f:cf:6b',
      txBytes: 4223152213,
      rxBytes: 11222542,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 8,
      deviceType: {
        category: 'Smart Phone',
        name: 'SM-G950F',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-06T20:47:34.523Z',
    },
    {
      socialNetworkId: '30:07:4d:0f:cf:6a',
      fullName: 'Some Guest',
      mac: '30:07:4d:0f:cf:6a',
      txBytes: 3223522313,
      rxBytes: 11222542,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 11,
      deviceType: {
        category: 'Smart Phone',
        name: 'IPhone 12 Pro Max',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-06T20:27:34.523Z',
    },
    {
      socialNetworkId: '30:07:4d:0f:cf:6c',
      fullName: 'Free WiFi User',
      mac: '30:07:4d:0f:cf:6c',
      txBytes: 3222322323,
      rxBytes: 112543252,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 2,
      deviceType: {
        category: 'Game Console',
        name: 'Nintendo Switch',
      },
      connectionState: 'connected',
      connectionStateChangeAt: '2022-12-06T20:17:34.523Z',
    },
    {
      socialNetworkId: '31:07:4d:0f:cf:6c',
      fullName: 'Free WiFi User',
      mac: '31:07:4d:0f:cf:6c',
      txBytes: 2322232232,
      rxBytes: 1112543252,
      loginType: 'Free WiFi',
      profilePhoto:
        'https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png',
      thirtyDayVisitCount: 2,
      deviceType: {
        category: 'Tablet',
        name: 'iPad',
      },
      connectionState: 'disconnected',
      connectionStateChangeAt: '2022-12-06T20:17:34.523Z',
    },
    {
      socialNetworkId: '96:ec:42:43:0c:9d',
      mac: '96:ec:42:43:0c:9d',
      txBytes: 322235223,
      rxBytes: 12234542,
      thirtyDayVisitCount: 1,
      deviceType: {
        name: '96:ec:42:43:0c:9d',
      },
      connectionState: 'disconnected',
      connectionStateChangeAt: '2022-12-08T11:33:00.925Z',
    },
  ],
};
