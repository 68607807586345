import type { Mac, ZoneDevice } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { Action, RootState } from 'State/store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, updateData } from 'State/utils';
import { sanitizeDevice } from './zones/devicesSlice';

import * as globalActions from '../../State/actions';
import { ROUTES } from 'Consts/routes';

export type DeviceState = {
  data: Data<ZoneDevice>;
};

const initialState: DeviceState = {
  data: updateData(null),
};
const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    set(state, action: PayloadAction<ZoneDevice>) {
      return {
        ...state,
        data: updateData(action.payload),
      };
    },
    isLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: action.payload,
          errorMessage: '',
          lastAttempt: Date.now(),
        },
      };
    },
    error(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          errorMessage: action.payload,
        },
      };
    },
  },
});

const fetchData = (mac: Mac): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!mac || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDevice({
      customerId,
      locationId: activeLocationId,
      mac,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error?.message || 'Error fetching device data',
            redirectRoute: ROUTES.home.index,
          },
        })
      );
      return;
    }
    if (!data) {
      dispatch(actions.error('no data'));

      return;
    }

    dispatch(actions.set(sanitizeDevice(data)));
  };
};

const updateDevice = (mac: Mac, newNickname: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!mac || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.updateDevice({
      customerId,
      locationId: activeLocationId,
      mac,
      newNickname,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(mac));
    dispatch(globalActions.zones.devices.fetchData());
  };
};

export const selectors = (state: RootState) => state.device.data;

export const actions = {
  fetchData,
  updateDevice,
  ...slice.actions,
};

export default slice.reducer;
