import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import * as actions from 'State/actions';
import * as api from 'Api/endpoints';
import { ROUTES } from 'Consts/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'State/store';
import { CloudBackend } from 'Consts/types';

export type SsidType = 'secure' | 'employee' | 'guest';

export type SsidConfigurationProps = {
  setSsidName: (name: string) => void;
  setSsidPassword: (password: string) => void;
  setCtaDisabled: (disabled: boolean) => void;
  setPasswordError: (error: string) => void;
};

export const useSsidConfiguration = ({
  setSsidName,
  setSsidPassword,
  setCtaDisabled,
  setPasswordError,
}: SsidConfigurationProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const routerLocation = useLocation();
  let currentRoute = routerLocation.pathname;

  const navigateToRoute = useMemo(() => {
    return currentRoute === ROUTES.ssidConfiguration.secure
      ? ROUTES.ssidConfiguration.employee
      : currentRoute === ROUTES.ssidConfiguration.employee
      ? ROUTES.ssidConfiguration.guest
      : ROUTES.home.index;
  }, [currentRoute]);

  const onSuccess = useCallback(() => {
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('settings.updateText', { field: t('common.network') }),
        state: 'set',
      })
    );

    setTimeout(
      (redirectRoute: string) => {
        setSsidName('');
        setSsidPassword('');

        setCtaDisabled(false);
        navigate(redirectRoute);
      },
      4100,
      navigateToRoute
    );
  }, [dispatch, t, navigateToRoute]);

  const onError = useCallback(
    (msg?: string) => {
      if (msg) {
        setPasswordError(msg);
      }
      setCtaDisabled(false);
    },
    [dispatch]
  );

  const updateSecureWifiConfiguration = async (
    customerId: string,
    locationId: string,
    ssidName: string,
    ssidPassword: string,
    token: string,
    cloud: CloudBackend
  ) => {
    if (!customerId || !locationId || !token || !cloud) {
      return;
    }

    setCtaDisabled(true);
    const { data: defaultNetworkData, error: defaultNetworkError } =
      await api.updateDefaultWifiNetwork({
        customerId,
        locationId: locationId,
        ssid: ssidName,
        encryptionKey: ssidPassword,
        token,
        cloud,
      });

    if (defaultNetworkError) {
      onError(defaultNetworkError.message);
    }

    if (defaultNetworkData) {
      onSuccess();
    }
  };

  const updateEmployeeWifiConfiguration = async (
    customerId: string,
    locationId: string,
    ssidName: string,
    ssidPassword: string,
    token: string,
    cloud: CloudBackend
  ) => {
    // first check to see if there is a secondary/employee network
    setCtaDisabled(true);
    const { data: employeeNetworkData, error: employeeNetworkError } =
      await api.getEmployeeWifiNetworks({
        token,
        cloud,
        customerId,
        locationId,
      });

    if (employeeNetworkError) {
      onError(employeeNetworkError.message);
    }

    if (employeeNetworkData && employeeNetworkData.length) {
      // PATCH to update the existing network
      const {
        data: updatedEmployeeNetworkData,
        error: updatedEmployeeNetworkError,
      } = await api.updateEmployeeWifiNetwork({
        token,
        cloud,
        customerId,
        locationId,
        ssid: ssidName,
        encryptionKey: ssidPassword,
      });
      if (updatedEmployeeNetworkError) {
        onError(updatedEmployeeNetworkError.message);
      }

      if (updatedEmployeeNetworkData) {
        onSuccess();
      }
    } else {
      // POST to create a new network
      const { data: newEmployeeNetworkData, error: newEmployeeNetworkError } =
        await api.createEmployeeWifiNetwork({
          token,
          cloud,
          locationId,
          customerId,
          ssid: ssidName,
          encryptionKey: ssidPassword,
        });

      if (newEmployeeNetworkError) {
        onError(newEmployeeNetworkError.message);
      }

      if (newEmployeeNetworkData) {
        onSuccess();
      }
    }
  };

  const updateGuestWifiConfiguration = async (
    customerId: string,
    locationId: string,
    ssidName: string,
    enable: boolean,
    token: string,
    cloud: CloudBackend
  ) => {
    // first check to see if there is a guest network
    setCtaDisabled(true);
    const { data: guestNetworkData, error: guestNetworkError } =
      await api.getGuestWifiNetworks({
        token,
        cloud,
        customerId,
        locationId,
      });

    if (guestNetworkError) {
      onError(guestNetworkError.message);
    }

    if (guestNetworkData && guestNetworkData.length) {
      // PATCH to update the existing network
      const { data: guestNetworkData, error: guestNetworkError } =
        await api.updateGuestWifiNetwork({
          token,
          cloud,
          customerId,
          locationId,
          data: { ssid: ssidName, enable },
        });

      if (guestNetworkError) {
        onError(guestNetworkError.message);
      }

      if (guestNetworkData) {
        onSuccess();
        dispatch(actions.locations.fetchData());
      }
    } else {
      // POST to create a new network
      const { data: newGuestNetworkData, error: newGuestNetworkError } =
        await api.createGuestWifiNetwork({
          token,
          cloud,
          locationId,
          customerId,
          ssid: ssidName,
          enable,
        });

      if (newGuestNetworkError) {
        onError(newGuestNetworkError.message);
      }

      if (newGuestNetworkData) {
        onSuccess();
        dispatch(actions.locations.fetchData());
      }
    }
  };

  return {
    updateSecureWifiConfiguration,
    updateEmployeeWifiConfiguration,
    updateGuestWifiConfiguration,
  };
};
