import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useCustomerSupportConfigurations = () =>
  useUpdatedData({
    selector: selectors.customerSupportConfigurations,
    action: actions.customerSupportConfigurations.fetchData(),
  });

export default useCustomerSupportConfigurations;
