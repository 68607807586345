import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'State/store';

export type ErrorAlertState = {
  errorState: {
    message: string;
    redirectRoute?: string;
  };
};

const initialState: ErrorAlertState = {
  errorState: {
    message: '',
    redirectRoute: '',
  },
};

const slice = createSlice({
  name: 'errorAlert',
  initialState,
  reducers: {
    set(state, action: PayloadAction<ErrorAlertState>) {
      state.errorState = action.payload.errorState;
    },
  },
});

export const selectors = {
  errorState: (state: RootState) => state.ui.errorAlert.errorState,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
