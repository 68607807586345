import cn from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';

import Icon, { IconNames } from 'UI/Elements/Icon';
import { Body1 } from 'UI/Elements/Typography';

import { getConfigurationFromDomain } from 'subDomainConfiguration';
import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

import { memo } from 'react';

export type Props = {
  label: string;
  ariaLabel: string;
  onClick: React.MouseEventHandler;
  className?: string;
};

const BELL = 'bell';

const CardFooter: FunctionComponent<Props> = ({
  label,
  ariaLabel,
  onClick,
  className,
}) => {
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  return (
    <button
      onClick={onClick}
      className={cn(styles.cardFooter, className, {
        [styles.cardFooterBell]: isBell,
      })}
      aria-label={ariaLabel}
      tabIndex={0}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      <Body1>{label}</Body1>
      <Icon className={styles.chevron} name={IconNames.ChevronRight} />
    </button>
  );
};
export default memo(CardFooter);
