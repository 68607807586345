import { OnlineTimeAppLast30Days } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<OnlineTimeAppLast30Days>(
  'defaultMonthlyAppsOnlineTime'
);

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const defaultMonthlyOnlineTime = getState().appsOnlineTime.default.monthly;

    if (
      defaultMonthlyOnlineTime.isLoading ||
      !customerId ||
      !token ||
      !activeLocation?.id
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getDefaultMonthlyAppsOnlineTime({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching Apps online time')
      );

      return;
    }

    dispatch(actions.set(data.last30Days));
  };
};

export const selectors = (state: RootState) =>
  state.appsOnlineTime.default.monthly;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
