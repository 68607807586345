import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  memo,
} from 'react';
import { useMixPanelRegistration } from './trackingAnalytics/hooks/useMixPanelRegistration';
import usePubNubRegistration from 'State/hooks/usePubNubRegistration';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import * as selectors from 'State/selectors';
import { useSelector } from 'react-redux';
import { environments } from 'Consts/environments';

const PLUME_TITLE = 'WorkPass Web - Plume';
const BELL_TITLE = 'Bell Business Wi-Fi';
const BELL = 'bell';

const useDocumentMetadata = (environmentId: string) => {
  useEffect(() => {
    const title =
      document.querySelector('title') || document.createElement('title');
    title.innerHTML = environmentId === BELL ? BELL_TITLE : PLUME_TITLE;

    const linkElement =
      (document.querySelector(
        'link[rel="shortcut icon"]'
      ) as HTMLAnchorElement) ||
      (document.createElement(
        'link[rel="shortcut icon"]'
      ) as HTMLAnchorElement);
    linkElement.type = environmentId === BELL ? 'image/png' : 'image/svg+xml';
    linkElement.href =
      environmentId === BELL ? '/bellFavicon.png' : '/favicon.svg';
  }, [environmentId]);
};

const ThemedContainer = memo<PropsWithChildren<{ theme: string }>>(
  ({ children, theme }) => <div color-theme={theme}>{children}</div>
);
ThemedContainer.displayName = 'ThemedContainer';

const AppContainer: FunctionComponent<PropsWithChildren> = memo(
  ({ children }) => {
    useMixPanelRegistration();
    usePubNubRegistration();

    const cloud = useSelector(selectors.auth.cloud);

    const environment = useMemo(() => getConfigurationFromDomain(), []);
    const theme = useMemo(
      () => (environment.id === BELL ? 'bell' : ''),
      [environment.id]
    );

    const auth0Config = useMemo(
      () => ({
        domain: environments[cloud]?.auth0Domain || '',
        clientId: environments[cloud]?.auth0ClientId || '',
        authorizationParams: {
          scope: 'openid profile email offline_access',
          audience: 'http://plume.com/GlobalAuthApi',
          redirect_uri: window.location.origin + '/onboarding/global-auth',
        },
      }),
      [cloud]
    );

    useDocumentMetadata(environment.id);

    const shouldUseAuth0 = useMemo(
      () =>
        environment.authenticationsMethodsPermitted.includes('globalAuth') &&
        Boolean(environments[cloud]?.auth0Domain) &&
        Boolean(environments[cloud]?.auth0ClientId),
      [environment.authenticationsMethodsPermitted, cloud]
    );

    const themedChildren = useMemo(
      () => <ThemedContainer theme={theme}>{children}</ThemedContainer>,
      [theme, children]
    );

    return shouldUseAuth0 ? (
      <Auth0Provider
        {...auth0Config}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        {themedChildren}
      </Auth0Provider>
    ) : (
      themedChildren
    );
  }
);

AppContainer.displayName = 'AppContainer';

export default memo(AppContainer);
