import { Nullable } from 'Consts/types';
import React, { FunctionComponent } from 'react';
import { getPartnerLogoFileName } from 'Utils/partnerLogos';

import { memo } from 'react';

export type PartnerLogoProps = {
  className?: string;
  height?: number;
  partnerId?: Nullable<string>;
};

const PartnerLogo: FunctionComponent<PartnerLogoProps> = ({
  className,
  height = 24,
  partnerId = undefined,
}) => {
  if (!partnerId) return <></>;

  const partnerLogo = getPartnerLogoFileName(partnerId);
  if (!partnerLogo) return <></>;
  return (
    <img
      className={className}
      src={`/partnerLogos/${partnerLogo}`}
      alt={partnerId}
      height={height}
    />
  );
};
export default memo(PartnerLogo);
