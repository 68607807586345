import { Data } from 'State/utils';

// 60000 ms = 1 minutes
const REFRESH_TIME = 60000; // in ms
const THROTTLE_TIME = 3000; // in ms - don't call again for 3seconds

export const checkRefresh = <T>(
  { data, lastUpdated }: Data<T>,
  dataDependencyKey?: string,
  enableRefresh: boolean = true,
  forceUpdate: boolean = false,
  refreshTime: number = REFRESH_TIME
) => {
  return (
    data === null ||
    forceUpdate ||
    (dataDependencyKey &&
      data?.[dataDependencyKey as Extract<keyof T, string>] === undefined &&
      Date.now() - lastUpdated! > THROTTLE_TIME) ||
    (enableRefresh && Date.now() - lastUpdated! > refreshTime)
  );
};
