import { GuestNetworkUsage } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

const slice = createStandardSlice<GuestNetworkUsage>('guestNetworkUsage');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const dataUsage = getState().guestNetworkUsage;

    if (dataUsage.isLoading || !customerId || !token || !activeLocation?.id) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getGuestNetworkUsage({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data?.daily) {
      dispatch(actions.error(error?.message || 'Error fetching data usage'));

      return;
    }

    dispatch(actions.set(data.daily));
  };
};

export const selectors = (state: RootState) => state.guestNetworkUsage;

export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
