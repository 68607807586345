import { Action, RootState } from 'State/store';
import { createStandardSlice } from 'State/utils';
import * as api from 'Api/endpoints';
import * as globalActions from '../../../State/actions';
import { MixpanelService } from 'trackingAnalytics/services/mixPanel.service';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';

export type BlockedEmployeeType = {
  idpUserId: string;
  locationId: string;
  name: string;
  blockedAt: string;
}[];

const slice = createStandardSlice<BlockedEmployeeType>('blockedEmployees');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getEmployeeLoginBlockedEmployees({
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message ?? 'Error fetching blocked employees')
      );
      return;
    }

    dispatch(actions.set(data));
    dispatch(actions.isLoading(false));
  };
};

const unblockEmployee = (idpUserId: string, successMessage: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.unblockEmployeeLoginEmployee({
      locationId: activeLocation.id,
      idpUserId,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error?.message ?? 'Error unblocking employee'));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error?.message,
          },
        })
      );

      return;
    }

    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.EMPLOYEE_UNBLOCK_SUCCESS,
      {
        idpUserId,
      }
    );

    dispatch(
      globalActions.ui.miniBanner.notify({
        label: successMessage,
        state: 'set',
      })
    );

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.settings.blockedEmployees;
export const actions = {
  ...slice.actions,
  fetchData,
  unblockEmployee,
};

export default slice.reducer;
