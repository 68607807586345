import React, { FunctionComponent } from 'react';
import { memo } from 'react';

type TextLoaderProps = {
  visible: boolean;
};

const TextLoader: FunctionComponent<TextLoaderProps> = (props) => {
  if (!props.visible) {
    return null;
  }

  return <span className="TextLoader">Loading</span>;
};
export default memo(TextLoader);
