import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Toggle, { Props as ToggleProps } from 'UI/Elements/Toggle';
import PillSmall from 'UI/Elements/Pill small';
import { Body1, Body2 } from 'UI/Elements/Typography';
import Icon, { Props as IconProps } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { Nullable } from '../../../Consts/types';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type RProps = {
  className?: string;
  icon1Props?: IconProps;
  icon2Props?: IconProps;
  icon3Props?: IconProps;
  label?: Nullable<string>;
  labelOnClick?: React.MouseEventHandler;
  smallLabel?: Nullable<string>;
  smallLabelOnClick?: React.MouseEventHandler;
  smallPillLabel?: Nullable<string>;
  smallPillOnClick?: React.MouseEventHandler;
  toggleProps?: ToggleProps;
  iconTestId?: string;
  ariaLabel?: string;
};

const R: FunctionComponent<RProps> = ({
  icon1Props,
  icon2Props,
  icon3Props,
  toggleProps,
  label,
  labelOnClick,
  smallLabel,
  smallLabelOnClick,
  smallPillLabel,
  smallPillOnClick,
  className,
  iconTestId,
  ariaLabel,
}) => {
  const containerClassName = cn(styles.r, className);

  // icon + icon + icon
  if (
    (icon1Props || icon2Props || icon3Props) &&
    !toggleProps &&
    !label &&
    !smallLabel &&
    !smallPillLabel
  ) {
    return (
      <div
        className={containerClassName}
        data-testid={iconTestId && iconTestId}
      >
        {icon1Props && (
          <Icon
            {...icon1Props}
            shape={
              icon1Props.onClick || icon1Props.tooltipLabel
                ? IconShape.square
                : undefined
            }
            ariaLabel={ariaLabel}
          />
        )}
        {icon2Props && (
          <Icon
            {...icon2Props}
            shape={
              icon2Props.onClick || icon2Props.tooltipLabel
                ? IconShape.square
                : undefined
            }
          />
        )}
        {icon3Props && (
          <Icon
            {...icon3Props}
            shape={
              icon3Props.onClick || icon3Props.tooltipLabel
                ? IconShape.square
                : undefined
            }
          />
        )}
      </div>
    );
  }

  // label
  if (
    label &&
    !icon1Props &&
    !icon2Props &&
    !icon3Props &&
    !toggleProps &&
    !smallLabel &&
    !smallPillLabel
  ) {
    return (
      <div
        className={containerClassName}
        role={labelOnClick ? 'button' : undefined}
        aria-label={`${ariaLabel},${label}`}
        onKeyDown={(e) => onEnterKeydown(e, labelOnClick)}
        tabIndex={0}
      >
        <Body1 className={colorStyles.still400} onClick={labelOnClick}>
          {label}
        </Body1>
      </div>
    );
  }

  // label + icon
  if (
    label &&
    icon1Props &&
    !icon2Props &&
    !icon3Props &&
    !toggleProps &&
    !smallLabel &&
    !smallPillLabel
  ) {
    return (
      <div className={containerClassName}>
        <Body1 className={colorStyles.still400} onClick={labelOnClick}>
          {label}
        </Body1>
        <Icon
          {...icon1Props}
          shape={
            icon1Props.onClick || icon1Props.tooltipLabel
              ? IconShape.square
              : undefined
          }
        />
      </div>
    );
  }

  // small label
  if (
    smallLabel &&
    !icon1Props &&
    !icon2Props &&
    !icon3Props &&
    !toggleProps &&
    !label &&
    !smallPillLabel
  ) {
    return (
      <div
        className={containerClassName}
        role={labelOnClick ? 'button' : undefined}
        aria-label={smallLabel}
        onKeyDown={(e) => onEnterKeydown(e, smallLabelOnClick)}
        tabIndex={smallLabelOnClick ? 0 : undefined}
      >
        <Body2 className={colorStyles.still400} onClick={smallLabelOnClick}>
          {smallLabel}
        </Body2>
      </div>
    );
  }

  // small label + icon
  if (
    smallLabel &&
    icon1Props &&
    !icon2Props &&
    !icon3Props &&
    !toggleProps &&
    !label &&
    !smallPillLabel
  ) {
    return (
      <div className={containerClassName}>
        <Body2 className={colorStyles.still400} onClick={smallLabelOnClick}>
          {smallLabel}
        </Body2>
        <Icon
          {...icon1Props}
          shape={
            icon1Props.onClick || icon1Props.tooltipLabel
              ? IconShape.square
              : undefined
          }
        />
      </div>
    );
  }

  // pill small
  if (
    smallPillLabel &&
    !label &&
    !icon1Props &&
    !icon2Props &&
    !icon3Props &&
    !toggleProps &&
    !smallLabel
  ) {
    return (
      <div className={containerClassName}>
        <PillSmall onClick={smallPillOnClick}>{smallPillLabel}</PillSmall>
      </div>
    );
  }

  // toggle
  if (
    !smallPillLabel &&
    !label &&
    !icon1Props &&
    !icon2Props &&
    !icon3Props &&
    toggleProps &&
    !smallLabel
  ) {
    return (
      <div className={containerClassName} aria-label={ariaLabel}>
        <Toggle {...toggleProps} />
      </div>
    );
  }

  return null;
};

export default R;
