import * as api from 'Api/endpoints';
import { Action, RootState } from 'State/store';
import { createStandardSlice } from 'State/utils';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import * as globalActions from '../../../State/actions';

export type SliceEmployeeLoginData = {
  localData: {
    companyName?: string;
  };
  databaseData: {
    companyName?: string;
    enabled?: boolean;
    liabilityDisclaimer?: string | null;
  };
};

const slice = createStandardSlice<SliceEmployeeLoginData>('employeeLogin');

// NB: we need separate input-related actions and localData value in the store because of the
//     interaction in the side panel
// 1. The side panel and settings content view need to share state for real-time editing of the name, but we can't
//    pass props, and redux seemed a better place than Context to store shared state
// 2. We need a way to determine if the input no longer matches the DB value -> if yes, the cta button needs to appear
//    we can't use local component state to compare the value of the input and the saved value, because the local state
//     cannot be shared with the settings content, i.e above problem, and real-time editing won't work

// local values
const updateInput = ({
  updatedCompanyName,
}: {
  updatedCompanyName: string;
}): Action => {
  return async (dispatch, getState) => {
    const employeeLoginData = getState().settings.employeeLogin;

    const updatedData = {
      localData: { companyName: updatedCompanyName },
      databaseData: {
        companyName: employeeLoginData.data?.databaseData.companyName || '',
        enabled: employeeLoginData.data?.databaseData.enabled,
      },
    };
    dispatch(actions.set(updatedData));
  };
};

// database values
const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    const trackEvent = useTrackEvent();
    if (!activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    let { data, error } = await api.getEmployeeLoginConfig({
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      // attempt to self heal see SIT-9090
      trackEvent({
        eventName: MixPanelEvents.EMPLOYEE_LOGIN_SELFHEAL,
      });
      const { data: dataSelfHeal, error: errorSelfHeal } =
        await api.postEmployeeLoginConfig({
          locationId: activeLocation.id,
          token,
          cloud,
        });
      if (errorSelfHeal || !dataSelfHeal) {
        dispatch(
          actions.error(error?.message ?? 'Error fetching configuration')
        );
        return;
      }
      data = dataSelfHeal;
      error = errorSelfHeal;
    }

    const updatedData = {
      localData: { companyName: data.companyName },
      databaseData: {
        companyName: data.companyName,
        enabled: data.enabled,
        liabilityDisclaimer: data.terms.liabilityDisclaimer,
      },
    };

    dispatch(actions.set(updatedData));
  };
};

const updateCompanyName = ({
  updatedCompanyName,
}: {
  updatedCompanyName: string;
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.updateEmployeeLoginCompanyName({
      locationId: activeLocation.id,
      token,
      cloud,
      companyName: updatedCompanyName,
    });

    if (error) {
      dispatch(actions.error(error?.message ?? 'Error updating company name'));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error?.message || 'Error updating company name',
          },
        })
      );
      return;
    }

    const employeeLoginData = getState().settings.employeeLogin;
    const updatedData = {
      localData: { ...employeeLoginData.data?.localData },
      databaseData: {
        enabled: employeeLoginData.data?.databaseData.enabled,
        companyName: updatedCompanyName,
      },
    };

    dispatch(actions.set(updatedData));
  };
};

const updateEmployeeLoginEnabled = ({
  enabled,
}: {
  enabled: boolean;
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.updateEmployeeLoginEnabled({
      locationId: activeLocation.id,
      token,
      cloud,
      enabled,
    });

    if (error) {
      dispatch(actions.error(error?.message ?? 'Error updating feature'));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error?.message || 'Error updating feature',
          },
        })
      );
      return;
    }

    const employeeLoginData = getState().settings.employeeLogin;

    const updatedData = {
      localData: { ...employeeLoginData.data?.localData },
      databaseData: {
        enabled: employeeLoginData.data?.databaseData.enabled,
        companyName: employeeLoginData.data?.databaseData.companyName,
      },
    };
    dispatch(actions.set(updatedData));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.settings.employeeLogin;
export const actions = {
  ...slice.actions,
  updateInput,
  fetchData,
  updateCompanyName,
  updateEmployeeLoginEnabled,
};

export default slice.reducer;
