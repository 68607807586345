import type { Guest } from 'Consts/types';
import { guestResp } from 'Consts/data examples/guests';

import * as api from 'Api/endpoints';

import { createStandardSlice } from 'State/utils';
import type { RootState, Action } from 'State/store';
import { MixpanelService } from 'trackingAnalytics/services/mixPanel.service';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';

const slice = createStandardSlice<Guest[]>('guests');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const debugEnabled = getState().debug.debugEnabled;
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const guests = getState().guests;

    if (guests.isLoading || !customerId || !activeLocation?.id || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    if (debugEnabled) {
      dispatch(actions.set(guestResp.guests));

      return;
    }

    const { data, error } = await api.getGuests({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message ?? 'Error fetching guests data'));

      return;
    }

    dispatch(actions.set(data?.guests));
  };
};

const downloadGuestDetails = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    let errResp = null;

    const { error, blob } = await api.downloadGuestDetails({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error) {
      errResp = error;
    } else {
      MixpanelService.getInstance().storeEvent(
        MixPanelEvents.GUEST_DOWNLOAD_CSV_SUCCESS
      );
      if (blob) {
        getCSV(blob);
      }
    }

    if (errResp) {
      dispatch(actions.error(errResp.message));
      MixpanelService.getInstance().storeEvent(
        MixPanelEvents.GUEST_DOWNLOAD_CSV_FAILURE,
        {
          errorMessage: errResp.message,
        }
      );
      return;
    }
  };
};

export const getCSV = (blob: Blob): void => {
  const filename = 'guests.csv';
  const link = document.createElement('a');
  const url = (link.href = URL.createObjectURL(blob));

  link.setAttribute('visibility', 'hidden');
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const selectors = (state: RootState) => state.guests;

export const actions = {
  fetchData,
  ...slice.actions,
  downloadGuestDetails,
};

export default slice.reducer;
