import * as actions from '../actions';
import * as selectors from '../selectors';
import useUpdatedData from './utils/useUpdatedData';

const useLocationCapabilities = () =>
  useUpdatedData({
    selector: selectors.locationCapabilities,
    action: actions.locationCapabilities.fetchData(),
  });

export default useLocationCapabilities;
