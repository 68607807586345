import {
  AnyAction,
  configureStore,
  ThunkAction,
  PreloadedState,
} from '@reduxjs/toolkit';

import rootReducer from './reducer';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_APP_ENV !== 'production',
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type Action = ThunkAction<void, RootState, unknown, AnyAction>;
