import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Icon, { IconNames } from 'UI/Elements/Icon';
import { Body2 } from 'UI/Elements/Typography';

import * as selectors from 'State/selectors';

import styles from './style.module.css';

export type Props = {
  state: 'unset' | 'set' | 'default';
  label: string;
  className?: string;
};

export const MiniBanner: FunctionComponent<Props> = ({
  label,
  state = 'default',
  className,
}) => {
  let icon = null;
  let colorClass = '';

  switch (state) {
    case 'default':
      icon = <Icon name={IconNames.Dummy} />;

      break;

    case 'set':
      colorClass = styles.set;
      icon = <Icon name={IconNames.Check} className={styles.set} />;

      break;

    case 'unset':
      colorClass = styles.unset;
      icon = <Icon name={IconNames.X} className={styles.unset} />;

      break;

    default:
      break;
  }

  return (
    <div className={cn(styles.miniBanner, className)}>
      {icon}
      <Body2 className={colorClass}>{label}</Body2>
    </div>
  );
};

export const MiniBannerWrapper: FunctionComponent = () => {
  const notifications = useSelector(selectors.ui.miniBanner);

  if (!notifications.length) {
    return null;
  }

  return (
    <div className={styles.miniBannerWrapper}>
      {notifications.map((notification, idx) => (
        <MiniBanner
          key={`${notification.label}-${idx}`}
          className={cn({ [styles.item]: idx === notifications.length - 1 })}
          {...notification}
        />
      ))}
    </div>
  );
};
