import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { isSettingsRoute } from 'Consts/routes';

import type { AppDispatch } from 'State/store';
import * as actions from 'State/actions';

export default function useNavigateToSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  return useCallback(
    (route: string) => {
      if (!isSettingsRoute(routerLocation.pathname)) {
        dispatch(actions.ui.page.prevSettingsRoute(routerLocation.pathname));
      }

      navigate(route);
    },
    [dispatch, navigate, routerLocation.pathname]
  );
}
