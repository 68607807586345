import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Divider from 'UI/Elements/Divider';
import { Body1Medium, Body3Medium } from 'UI/Elements/Typography';
import Icon, { Props as IconProps } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { Nullable } from '../../../../Consts/types';

import { memo } from 'react';

export type LProps = {
  label?: Nullable<string>;
  smallLabel?: Nullable<string>;
};

const L: FunctionComponent<LProps> = ({ label, smallLabel }) => {
  if (label && !smallLabel) {
    return <Body1Medium className={styles.l1}>{label}</Body1Medium>;
  }

  if (!label && smallLabel) {
    return <Body3Medium className={styles.l1}>{smallLabel}</Body3Medium>;
  }

  return null;
};

export type RProps = {
  withDivider?: boolean;
  label?: Nullable<string>;
  iconProps?: IconProps;
  onClick?: React.MouseEventHandler;
};

const R: FunctionComponent<RProps> = ({
  label,
  withDivider,
  iconProps,
  onClick,
}) => {
  if (label && !withDivider && !iconProps) {
    return (
      <Body3Medium
        onClick={onClick}
        ariaLabel={label}
        className={styles.showHideToggle}
      >
        {label}
      </Body3Medium>
    );
  }

  if (withDivider && !label && !iconProps) {
    return (
      <div className={styles.wrapper}>
        <Divider />
      </div>
    );
  }

  if (iconProps && !label && !withDivider) {
    return <Icon {...iconProps} />;
  }

  return null;
};

type SettingsSectionHeaderProps = {
  className?: string;
  LProps?: LProps;
  RProps?: RProps;
};

const SettingsSectionHeader: FunctionComponent<SettingsSectionHeaderProps> = ({
  className,
  LProps = {},
  RProps = {},
}) => {
  return (
    <div className={cn(styles.settingsSectionHeader, className)}>
      <L {...LProps} />
      <R {...RProps} />
    </div>
  );
};
export default memo(SettingsSectionHeader);
