import type {
  RecursivePartial,
  SecurityPolicy,
  SecurityContentTypes,
  Website,
  WebsiteType,
} from 'Consts/types';
import { SECURITY_CONTENT } from 'Consts/defintions';
import i18n from 'i18next';

import { METHODS, Method } from 'Api/index';

import * as api from 'Api/endpoints';
import * as globalActions from '../../../State/actions';
import type { RootState, Action } from 'State/store';
import { createStandardSlice } from 'State/utils';

export type PolicyItems =
  | 'secureAndProtect'
  | 'iotProtect'
  | 'adBlocking'
  | 'workAppropriate';
export type LocationItems = 'appTime';
export type ShieldItems = PolicyItems | LocationItems;

export type appliesToAllDevicesItems =
  | 'adBlocking'
  | 'adultAndSensitive'
  | 'iotProtect'
  | 'kids'
  | 'secureAndProtect'
  | 'teenagers'
  | 'websitesBlacklist'
  | 'websitesWhitelist'
  | 'workAppropriate';

export type PolicyItemsProps = {
  [T in PolicyItems]: boolean;
};

export type LocationItemsProps = {
  [T in LocationItems]: boolean;
};

export type ShieldSettingsProps = PolicyItemsProps & LocationItemsProps;

const slice = createStandardSlice<SecurityPolicy>('securityPolicy');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().settings.securityPolicy;

    if (
      securityPolicyData.isLoading ||
      !customerId ||
      !activeLocation?.id ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getLocationSecurityPolicy({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error fetching security policy')
      );

      return;
    }

    dispatch(actions.set(data));
    dispatch(globalActions.locationAppTime.fetchData());
    dispatch(actions.isLoading(false));
  };
};

const updateLocationSecurityPolicy = (
  info: Partial<PolicyItemsProps>
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().settings.securityPolicy;
    const securityPolicy = securityPolicyData.data;
    const locationSecurityAppliesToAllDevices =
      getState().settings.locationSecurityAppliesToAllDevices;
    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    let payload: RecursivePartial<SecurityPolicy> = {};
    if (securityPolicy) {
      if (typeof info.adBlocking === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.adBlocking) {
          content = [...securityPolicy.content, SECURITY_CONTENT.adBlocking];
        } else {
          content = securityPolicy.content.filter(
            (el) => el !== SECURITY_CONTENT.adBlocking
          );
        }

        payload = {
          content,
          appliesToAllDevices: { [SECURITY_CONTENT.adBlocking]: true },
        };
      }

      if (typeof info.workAppropriate === 'boolean') {
        let content: SecurityContentTypes[] = [];

        if (info.workAppropriate) {
          content = [
            ...securityPolicy.content,
            SECURITY_CONTENT.workAppropriate,
          ];
        } else {
          content = securityPolicy.content.filter(
            (el) => el !== SECURITY_CONTENT.workAppropriate
          );
        }

        payload = {
          content,
          appliesToAllDevices: {
            [SECURITY_CONTENT.workAppropriate]: true,
            [SECURITY_CONTENT.kids]: true,
            [SECURITY_CONTENT.teenagers]: true,
            [SECURITY_CONTENT.adultAndSensitive]: true,
          },
        };
      }

      if (typeof info.secureAndProtect === 'boolean') {
        if (
          (!info.secureAndProtect &&
            !locationSecurityAppliesToAllDevices.data?.appliesToAllDevices
              .iotProtect) ||
          (!info.secureAndProtect && securityPolicy.iotProtect)
        ) {
          payload = {
            ...info,
            iotProtect: false,
            appliesToAllDevices: { secureAndProtect: true, iotProtect: true },
          };
        } else {
          payload = {
            ...info,
            appliesToAllDevices: { secureAndProtect: true },
          };
        }
      }

      if (typeof info.iotProtect === 'boolean') {
        if (info.iotProtect && !securityPolicy.secureAndProtect) {
          payload = {
            ...info,
            secureAndProtect: true,
            appliesToAllDevices: { secureAndProtect: true, iotProtect: true },
          };
        } else {
          payload = { ...info, appliesToAllDevices: { iotProtect: true } };
        }
      }
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.updateLocationSecurityPolicy({
      customerId,
      locationId: activeLocation.id,
      data: payload,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error updating security policy')
      );

      return;
    }

    dispatch(actions.set(data));
    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const updateLocationPolicyWhitelist = (
  websiteType: WebsiteType,
  website: string,
  method: Method
): Action => {
  return async (dispatch, getState, t) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().settings.securityPolicy;
    const securityPolicy = securityPolicyData.data;

    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    if (
      method === METHODS.POST &&
      securityPolicy?.websites.whitelisted.map((w) => w.value).includes(website)
    ) {
      return;
    }

    if (
      method === METHODS.DELETE &&
      !securityPolicy?.websites.whitelisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    let payload: Website = { type: websiteType, value: website };

    dispatch(actions.isLoading(true));

    const { data, error } = await api.updateLocationPolicyWhitelist({
      customerId,
      locationId: activeLocation.id,
      data: payload,
      method,
      token,
      cloud,
    });

    if (error || (method === METHODS.POST && !data)) {
      dispatch(
        actions.error(error?.message || 'Error updating whitelisted websites')
      );
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error!.message,
          },
        })
      );
      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
    dispatch(globalActions.securityEvents.fetchData());

    if (method === METHODS.DELETE) {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.removed', { website: website }),
          state: 'set',
        })
      );
    } else {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.approvedWebsite', {
            website: website,
          }),
          state: 'set',
        })
      );
    }
  };
};

const updateLocationPolicyBlacklist = (
  websiteType: WebsiteType,
  website: string,
  method: Method
): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const securityPolicyData = getState().settings.securityPolicy;
    const securityPolicy = securityPolicyData.data;

    if (!customerId || !activeLocation?.id || !token) {
      return;
    }

    if (
      method === METHODS.POST &&
      securityPolicy?.websites.blacklisted.map((w) => w.value).includes(website)
    ) {
      return;
    }

    if (
      method === METHODS.DELETE &&
      !securityPolicy?.websites.blacklisted
        .map((w) => w.value)
        .includes(website)
    ) {
      return;
    }

    let payload: Website = { type: websiteType, value: website };

    const { data, error } = await api.updateLocationPolicyBlacklist({
      customerId,
      locationId: activeLocation.id,
      data: payload,
      method,
      token,
      cloud,
    });

    if (error || (method === METHODS.POST && !data)) {
      dispatch(
        actions.error(error?.message || 'Error updating blacklisted websites')
      );
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error!.message,
          },
        })
      );
      return;
    }

    dispatch(actions.fetchData());

    if (method === METHODS.DELETE) {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.removed', { website: website }),
          state: 'set',
        })
      );
    } else {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: i18n.t('shield.eventHandling.blockedWebsite', {
            website: website,
          }),
          state: 'set',
        })
      );
    }
  };
};

export const selectors = {
  securityPolicy: (state: RootState) => state.settings.securityPolicy,

  locationSecurityAppliesToAllDevices: (state: RootState) => {
    const locations = state.locations.ownedLocations;
    const securityData = state.settings.securityPolicy;
    const locationAppTime = state.locationAppTime;
    const appliesToAllDevices = securityData.data?.appliesToAllDevices;

    return {
      isLoading: locations.isLoading || securityData.isLoading,
      errorMessage: locations.errorMessage || securityData.errorMessage,
      data: {
        appTime: locationAppTime.data?.appliesToAllDevices,
        workAppropriate: appliesToAllDevices?.workAppropriate,
        secureAndProtect: appliesToAllDevices?.secureAndProtect,
        iotProtect: appliesToAllDevices?.iotProtect,
        adBlocking: appliesToAllDevices?.adBlocking,
      },
    };
  },

  shieldSettings: (state: RootState) => {
    const locations = state.locations.ownedLocations;
    const securityData = state.settings.securityPolicy;
    const locationAppTime = state.locationAppTime;

    return {
      isLoading: locations.isLoading || securityData.isLoading,
      errorMessage: locations.errorMessage || securityData.errorMessage,
      data: {
        appTime: locationAppTime?.data?.enable || false,
        workAppropriate:
          securityData.data?.content?.includes('workAppropriate') || false,
        secureAndProtect: securityData.data?.secureAndProtect || false,
        iotProtect: securityData.data?.iotProtect || false,
        adBlocking: securityData.data?.content?.includes('adBlocking') || false,
      },
    };
  },
};

export const actions = {
  ...slice.actions,
  fetchData,
  updateLocationSecurityPolicy,
  updateLocationPolicyWhitelist,
  updateLocationPolicyBlacklist,
};

export default slice.reducer;
