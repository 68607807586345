import { ZoneDevice } from 'Consts/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';
import useLocations from './useLocations';
import useNetworkAccess from './useNetworkAccess';

import useConditionalUpdate from './utils/useConditionalUpdate';

type DeviceZones = {
  default: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  };
  employee: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  };
  guest: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  };
};

const useDevices = () => {
  const { activeLocation } = useLocations();
  const networkAccessData = useNetworkAccess();
  const devicesData = useSelector(selectors.zones.devices);

  useConditionalUpdate({
    data: devicesData,
    dependencies: [activeLocation.data?.id, networkAccessData.data],
    action: actions.zones.devices.fetchData(),
  });

  const sortedDevices = useMemo(() => {
    return devicesData.data?.reduce(
      (devicesByZone, d) => {
        if (!networkAccessData.data) {
          return devicesByZone;
        }

        let purgatory = false;

        if (d.networkId === 'default' || d.networkId === 'employee') {
          purgatory = networkAccessData.data[d.networkId].purgatory;
        }

        if (purgatory) {
          devicesByZone?.[d.networkId]?.[d.networkAccessMode].push(d);
        } else {
          if (d.networkAccessMode === 'quarantined') {
            devicesByZone?.[d.networkId]?.quarantined.push(d);
          } else {
            if (d.networkAccessMode === 'blocked') {
              devicesByZone?.[d.networkId]?.blocked.push(d);
            } else {
              // test TODO
              devicesByZone?.[d.networkId]?.approved.push(d);
            }
          }
        }

        return devicesByZone;
      },
      {
        default: {
          auto: [],
          approved: [],
          blocked: [],
          quarantined: [],
        },
        employee: {
          auto: [],
          approved: [],
          blocked: [],
          quarantined: [],
        },
        guest: {
          auto: [],
          approved: [],
          blocked: [],
          quarantined: [],
        },
      } as DeviceZones
    );
  }, [devicesData.data, networkAccessData.data]);

  return {
    allDevices: {
      ...devicesData,
      isLoading:
        devicesData.isLoading ||
        activeLocation.isLoading ||
        networkAccessData.isLoading,
      errorMessage:
        devicesData.errorMessage ||
        activeLocation.errorMessage ||
        networkAccessData.errorMessage,
    },
    defaultDevices: sortedDevices?.default || null,
    employeeDevices: sortedDevices?.employee || null,
    guestDevices: sortedDevices?.guest || null,
  };
};

export default useDevices;
