import mixpanel from 'mixpanel-browser';
import { TrackingUser } from '../types';
import { MixPanelEvents } from '../mixPanelEvents';
import { MIXPANEL_ID } from '../../Consts/config';

export class MixpanelService {
  private readonly enabled: boolean = false;
  private static mixPanelInstance: MixpanelService;

  public static getInstance() {
    return this.mixPanelInstance || (this.mixPanelInstance = new this());
  }

  private constructor() {
    mixpanel.init(MIXPANEL_ID, { persistence: 'localStorage' });
    this.enabled = true;
  }

  public storeEvent(id: MixPanelEvents, action: any = {}): void {
    if (this.enabled) {
      mixpanel.track(id, action);
    }
  }

  public setUser(user: TrackingUser): void {
    if (this.enabled) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: '',
        name: user.id,
        'Cloud Env': user.cloudEnv,
      });
      mixpanel.register({
        CUSTOMER_ID: user.id,
        CLOUD_ENV: user.cloudEnv,
      });
    }
  }

  public setEnvironment(environment: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_ENV: environment });
    }
  }

  public setCustomerId(customerId: string): void {
    if (this.enabled) {
      mixpanel.register({ CUSTOMER_ID: customerId });
    }
  }

  public setPartnerId(partnerId: string): void {
    if (this.enabled) {
      mixpanel.register({
        PARTNER_ID: partnerId,
      });
    }
  }

  public setLocation(locationId: string): void {
    if (this.enabled) {
      mixpanel.register({ LOCATION_ID: locationId });
    }
  }
}
