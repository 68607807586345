import type {
  ConnectionStateTypes,
  FreqBand,
  GroupingTypes,
  NetworkAccessMode,
  Period,
  SecureNetworkZone,
  SecurityContentTypes,
  SpeedDimension,
} from 'Consts/types';

import { IconName, IconNames } from 'UI/Elements/Icon';

import bellColors from 'Styles/bellColors';
import colors from 'Styles/colors';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import type { ActivityTypes } from 'UI/Components/Graphs/types';
const BELL = 'bell';
const environment = getConfigurationFromDomain();

const colorFile = environment.id === BELL ? bellColors : colors;

export enum NETWORK_IDS {
  default = 'default',
  employee = 'employee',
  guest = 'guest',
}

export const SECURE_NETWORK_ZONES: Record<
  SecureNetworkZone,
  SecureNetworkZone
> = {
  default: 'default',
  employee: 'employee',
  // guest: "guest",
};

export const PERIODS: Record<Period, Period> = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
};

export const SPEED_DIMENSIONS: Record<SpeedDimension, SpeedDimension> = {
  download: 'download',
  upload: 'upload',
};

export const SECURITY_CONTENT_ITEMS: SecurityContentTypes[] = [
  'adultAndSensitive',
  'adBlocking',
  'kids',
  'teenagers',
  'workAppropriate',
];

export const SECURITY_CONTENT: Record<
  SecurityContentTypes,
  SecurityContentTypes
> = {
  adultAndSensitive: 'adultAndSensitive',
  adBlocking: 'adBlocking',
  kids: 'kids',
  teenagers: 'teenagers',
  workAppropriate: 'workAppropriate',
};

export const IPV4_REGEX = new RegExp(
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
);
export const IPV6_REGEX = new RegExp(
  /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
);
export const EMAIL_REGEX = new RegExp(/^.+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/);

export const networkAccessModes: Record<NetworkAccessMode, NetworkAccessMode> =
  {
    auto: 'auto',
    approved: 'approved',
    quarantined: 'quarantined',
    blocked: 'blocked',
  };

export const largeProfilePhoto: Record<string, IconName> = {
  PROFILE_MAN: IconNames.Profile80Generic01,
  PROFILE_WOMAN: IconNames.Profile80Generic11,
  PROFILE_NEUTRAL: IconNames.Profile80Generic06,
};

export const profilePhoto: Record<string, IconName> = {
  PROFILE_MAN: IconNames.Profile56Generic01,
  PROFILE_WOMAN: IconNames.Profile56Generic11,
  PROFILE_NEUTRAL: IconNames.Profile56Generic06,
};

export const mediumProfilePhoto: Record<string, IconName> = {
  PROFILE_MAN: IconNames.Profile40Generic01,
  PROFILE_WOMAN: IconNames.Profile40Generic11,
  PROFILE_NEUTRAL: IconNames.Profile40Generic06,
};

export const smallProfilePhoto: Record<string, IconName> = {
  PROFILE_MAN: IconNames.Profile24Generic01,
  PROFILE_WOMAN: IconNames.Profile24Generic11,
  PROFILE_NEUTRAL: IconNames.Profile24Generic06,
};

export const GROUPING: Record<GroupingTypes, GroupingTypes> = {
  overall: 'overall',
  perTimeSlot: 'perTimeSlot',
};

export const CONNECTION_STATE: Record<
  ConnectionStateTypes,
  ConnectionStateTypes
> = {
  connected: 'connected',
  disconnected: 'disconnected',
};

export const ACTIVITY_TYPES: Record<ActivityTypes, ActivityTypes> = {
  usage: 'usage',
  online: 'online',
};

export const HealthColors: Record<string, string> = {
  excellent: colors.good500,
  good: colors.good500,
  fair: colors.warm400,
  poor: colors.sore400,
  calculating: colors.still200,
  unknown: colors.still200,
  undefined: colors.still200,
};

export const HealthStatusFullLabels: Record<string, string> = {
  excellent: 'healthStatusFullLabels.excellent',
  good: 'healthStatusFullLabels.good',
  fair: 'healthStatusFullLabels.fair',
  poor: 'healthStatusFullLabels.poor',
  calculating: 'healthStatusFullLabels.calculating',
  unknown: 'healthStatusFullLabels.unknown',
  undefined: 'healthStatusFullLabels.unknown',
};

export const WiFiInterfaceToGHzLabel: Record<FreqBand, string> = {
  '2.4G': '2.4 GHz',
  '5G': '5 GHz',
  '5GL': '5 GHz',
  '5GU': '5 GHz',
  '6G': '6 GHz',
};

export const DataUsageColors = [
  colorFile.sore700,
  colorFile.warm700,
  colorFile.good500,
];

export const GuestDataUsageColors = [
  colorFile.sore700,
  colorFile.warm300,
  colorFile.good500,
];

export const OnlineActivityColors = [
  colorFile.cold500,
  colorFile.warm300,
  colorFile.hot500,
];

export const OnlineTimeColors = [
  colorFile.cold500,
  colorFile.warm300,
  colorFile.hot500,
];

export const AnalyticsFieldsColors = {
  avgSessionLength: colorFile.sore100,
  avgSessionTxRxBytes: colorFile.cold100,
  deviceCount: colorFile.warm100,
  guestCount: colorFile.good100,
};
export const GuestsByHourGraphBarColors = {
  bar1: colorFile.hot500,
  bar2: colorFile.warm500,
};

export const BarGraphColors = {
  fill: colorFile.still400,
  tickLine: colorFile.still200,
};

export const SimpleBarChartColors = {
  fillOne: colorFile.hot500,
  fillTwo: colorFile.warm500,
  tickLine: colorFile.still200,
};

export const ShieldGraphConfig = {
  all: colorFile.still800,
  onlineProtection: colorFile.freeze500,
  contentAccess: colorFile.freeze400,
  adBlocking: colorFile.warm700,
  remoteAccessProtection: colorFile.hot600,
  advancedIotProtection: colorFile.hot500,
};

export const APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES =
  'appMonitoringFeatureAnnouncementShownTimes';
export const BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES =
  'businessInfoFeatureAnnouncementShownTimes';
export const EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES =
  'employeeLoginFeatureAnnouncementShownTimes';
