import { Action, RootState } from 'State/store';
import * as api from 'Api/endpoints';
import * as globalActions from '../../../State/actions';
import { createStandardSlice } from 'State/utils';
import { Mac, NetworkType } from 'Consts/types';

const slice = createStandardSlice('employeeGroupShareAccess');

const updateEmployeeOrGroupShareAccess = ({
  groups,
  groupId,
  deviceGroupNetworkId,
  devices,
  networkId,
}: {
  networkId?: NetworkType;
  groups: string[];
  groupId?: string;
  deviceGroupNetworkId?: NetworkType;
  devices?: { networkId?: NetworkType; mac?: Mac }[];
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token || !groupId) {
      return;
    }

    dispatch(actions.isLoading(true));

    const params = {
      customerId,
      locationId: activeLocationId,
      data: {
        groups: groups,
        devices: devices,
      },
      token,
      cloud,
    };

    const { data, error } = await api.updateEmployeeGroupShareAccess({
      ...params,
      groupId,
      networkId,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error Sharing access to employees')
      );

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(globalActions.zones.deviceGroups.fetchData(networkId));
    dispatch(globalActions.zones.deviceGroups.fetchData(deviceGroupNetworkId));
  };
};
const updateDeviceShareAccess = ({
  groups,
  networkId,
  deviceGroupNetworkId,
  mac,
  devices,
}: {
  groups: string[];
  networkId?: NetworkType;
  deviceGroupNetworkId?: NetworkType;
  mac: Mac;
  devices?: { networkId?: NetworkType; mac?: Mac }[];
}): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token || !networkId) {
      return;
    }

    dispatch(actions.isLoading(true));

    const params = {
      customerId,
      locationId: activeLocationId,
      data: {
        groups: groups,
        devices: devices,
      },
      token,
      cloud,
    };

    const { data, error } = await api.updateEmployeeShareAccess({
      ...params,
      networkId,
      mac,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message || 'Error Sharing access to employees')
      );

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(globalActions.zones.deviceGroups.fetchData(networkId));
    dispatch(globalActions.zones.deviceGroups.fetchData(deviceGroupNetworkId));
  };
};
export const selectors = (state: RootState) =>
  state.zones.employeeGroupShareAccess;

export const actions = {
  ...slice.actions,
  updateEmployeeOrGroupShareAccess,
  updateDeviceShareAccess,
};

export default slice.reducer;
