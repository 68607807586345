import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Unixtime } from 'Consts/types';

export type Data<T> = {
  data: T | null;
  lastUpdated?: Unixtime;
  isLoading: boolean;
  errorMessage: string;
  lastAttempt?: Unixtime;
};

export const updateData = <T>(data: T | null = null) => ({
  data,
  lastUpdated: Date.now(),
  lastAttempt: Date.now(),
  isLoading: false,
  errorMessage: '',
});

export const createStandardSlice = <T>(name: string) => {
  const initialState: Data<T> = updateData(null);

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      set(state, action: PayloadAction<T>) {
        return updateData(action.payload);
      },
      update(state, action: PayloadAction<Partial<T>>) {
        const payload = action.payload as T;

        return updateData({
          ...state.data,
          ...payload,
        });
      },
      clear() {
        return updateData(null);
      },
      isLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
        state.errorMessage = '';
        state.lastAttempt = Date.now();
      },
      error(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.errorMessage = action.payload;
        state.lastAttempt = Date.now();
      },
    },
  });

  return slice;
};
