import type { Mac, Person } from 'Consts/types';

import * as api from 'Api/endpoints';

import type { Action, RootState } from 'State/store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, updateData } from 'State/utils';
import * as globalActions from '../../State/actions';
import { ROUTES } from 'Consts/routes';
import { MixpanelService } from 'trackingAnalytics/services/mixPanel.service';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';

export type EmployeeState = {
  data: Data<Person>;
};

const initialState: EmployeeState = {
  data: updateData(null),
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    set(state, action: PayloadAction<Person>) {
      return {
        ...state,
        data: updateData(action.payload),
      };
    },
    isLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: action.payload,
          errorMessage: '',
          lastAttempt: Date.now(),
        },
      };
    },
    error(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          errorMessage: action.payload,
        },
      };
    },
  },
});

const fetchData = (personId: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!personId || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: error?.message || 'Error fetching employee data',
            redirectRoute: ROUTES.home.index,
          },
        })
      );

      return;
    }
    if (!data) {
      dispatch(actions.error('no Employee data'));

      return;
    }

    dispatch(actions.set(data));
  };
};

const updateEmployee = (
  personId: string,
  newNickname?: string,
  imageId?: string,
  assignedDevices?: Mac[]
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !personId ||
      !newNickname ||
      !customerId ||
      !activeLocationId ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.updateEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      newNickname,
      imageId,
      assignedDevices,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(personId));
    dispatch(globalActions.zones.devices.fetchData());
    dispatch(globalActions.employees.fetchData());
  };
};

const assignDeviceToEmployee = (
  personId: string,
  assignedDevices: Mac[]
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !customerId ||
      !personId ||
      !assignedDevices ||
      !activeLocationId ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.assignDeviceToEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      assignedDevices,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(personId));
    dispatch(globalActions.zones.devices.fetchData());
  };
};

const assignPrimaryDeviceToEmployee = (
  personId: string,
  primaryDevice: Mac
): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (
      !customerId ||
      !personId ||
      !primaryDevice ||
      !activeLocationId ||
      !token
    ) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.assignPrimaryDeviceToEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      primaryDevice,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData(personId));
    dispatch(globalActions.employees.fetchData());
  };
};

const removeEmployee = (personId: string, successMessage: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !personId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.removeEmployee({
      customerId,
      locationId: activeLocationId,
      personId,
      token,
      cloud,
    });

    if (error) {
      const errorMessage = error?.message;

      dispatch(actions.error(errorMessage));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: errorMessage,
          },
        })
      );

      return;
    }

    MixpanelService.getInstance().storeEvent(
      MixPanelEvents.EMPLOYEE_REMOVE_SUCCESS,
      {
        personId,
      }
    );

    dispatch(
      globalActions.ui.miniBanner.notify({
        label: successMessage,
        state: 'set',
      })
    );
    dispatch(actions.isLoading(false));
    dispatch(globalActions.employees.fetchData());
    dispatch(globalActions.zones.devices.fetchData());
  };
};

export const selectors = (state: RootState) => state.employee.data;

export const actions = {
  fetchData,
  updateEmployee,
  assignDeviceToEmployee,
  assignPrimaryDeviceToEmployee,
  removeEmployee,
  ...slice.actions,
};

export default slice.reducer;
