import * as api from 'Api/endpoints';

import { createStandardSlice } from 'State/utils';
import type { RootState, Action } from 'State/store';
import { GlobalAuthLoginOptionsData } from 'Consts/globalAuthTypes';

const slice = createStandardSlice<GlobalAuthLoginOptionsData>(
  'globalAuthLoginOptions'
);
const loginOptions = (email: string): Action => {
  return async (dispatch, getState) => {
    const cloud = getState().auth.cloud;

    dispatch(actions.clear());
    dispatch(actions.isLoading(true));

    const { data, error } = await api.globalAuthLoginOptions({ email, cloud });

    if (error || !data) {
      dispatch(
        actions.error(
          error?.message || 'Something went wrong. Please try again later.'
        )
      );

      return;
    }

    dispatch(actions.set(data));
  };
};

export const selectors = (state: RootState) => state.globalAuthLoginOptions;

export const actions = {
  loginOptions,
  ...slice.actions,
};

export default slice.reducer;
