export const stringToDots = (text: string) => {
  return text
    .split('')
    .map(() => '●')
    .join('');
};

export const copyToClipboard = async (text: string) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported');

    return false;
  }

  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text);

    return true;
  } catch (error) {
    console.warn('Copy failed', error);

    return false;
  }
};

export const generateId = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};
