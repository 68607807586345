import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useNetworkAccess = () =>
  useUpdatedData({
    selector: selectors.settings.networkAccess,
    action: actions.settings.networkAccess.fetchData(),
  });

export default useNetworkAccess;
