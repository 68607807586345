import { useEffect, useRef } from 'react';

class TriggerHelper {
  static triggerElement: HTMLElement | null | undefined;

  static setTriggerElement(ev: React.MouseEvent<Element, MouseEvent>) {
    if (ev.target instanceof HTMLElement)
      TriggerHelper.triggerElement = ev.target;
  }

  static focus() {
    TriggerHelper.triggerElement?.focus();
    TriggerHelper.triggerElement = null;
  }
}

type UseTrapFocusProps = { focusOnUnmount?: boolean; trapFocus?: boolean };

export const useTrapFocus = <T extends HTMLElement>(
  options: UseTrapFocusProps = {}
) => {
  const { focusOnUnmount, trapFocus } = options;
  const ref = useRef<T>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!ref.current) return;

    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) return;

    const focusableElements = ref.current.querySelectorAll<HTMLElement>(
      'button, [href], input, select, textarea, iframe, [tabindex]:not([tabindex="-1"])'
    );

    const firstFocusableElement = focusableElements[0];
    const focusableContent = focusableElements;
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      e.preventDefault();
    }
  };

  const focusTriggerElement = () => {
    TriggerHelper.focus();
  };

  const handleTriggerEvent = (ev: React.MouseEvent) => {
    if (ev?.target instanceof HTMLElement)
      TriggerHelper.triggerElement = ev.target;
  };

  useEffect(() => {
    return () => {
      if (focusOnUnmount) focusTriggerElement();
    };
  }, [focusOnUnmount]);

  useEffect(() => {
    if (trapFocus) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [ref]);

  return { ref, handleTriggerEvent, focusTriggerElement };
};
