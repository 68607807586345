import { LocationAppTime } from 'Consts/types';
import { createStandardSlice } from 'State/utils';
import type { Action, RootState } from 'State/store';
import * as api from 'Api/endpoints';

const slice = createStandardSlice<LocationAppTime>('locationAppTime');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { customerId } = getState().customer;
    const { activeLocationId } = getState().locations;
    const { data: token } = getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    const { data, error } = await api.getLocationAppTime({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    if (!data) {
      dispatch(actions.error('no Location App time data'));

      return;
    }

    dispatch(actions.set(data));
    dispatch(actions.isLoading(false));
  };
};

export const selectors = (state: RootState) => state.locationAppTime;

export const actions = {
  fetchData,
  ...slice.actions,
};

export default slice.reducer;
