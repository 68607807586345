import { actions as wifi } from './slices/settings/wifiSlice';
import { actions as networkAccess } from './slices/settings/networkAccessSlice';
import { actions as pods } from './slices/settings/podsSlice';
import { actions as securityPolicy } from './slices/settings/securityPolicySlice';
import { actions as employeeLogin } from './slices/settings/employeeLoginSlice';
import { actions as blockedEmployees } from './slices/settings/blockedEmployeesSlice';

import { actions as devices } from './slices/zones/devicesSlice';
import { actions as deviceGroups } from './slices/zones/deviceGroupsSlice';
import { actions as employeeGroupShareAccess } from './slices/zones/shareAccessSlice';
import { actions as devicesAndGroups } from './slices/ui/devicesAndGroupsSlice';
import { actions as page } from './slices/ui/pageSlice';
import { actions as miniBanner } from './slices/ui/miniBanner';
import { actions as errorAlert } from './slices/ui/errorAlertSlice';

import { actions as defaultDailyDataUsage } from './slices/dataUsage/default/dailySlice';
import { actions as employeeDailyDataUsage } from './slices/dataUsage/employee/dailySlice';
import { actions as guestDailyDataUsage } from './slices/dataUsage/guest/dailySlice';

import { actions as defaultMonthlyDataUsage } from './slices/dataUsage/default/monthlySlice';
import { actions as employeeMonthlyDataUsage } from './slices/dataUsage/employee/monthlySlice';
import { actions as guestMonthlyDataUsage } from './slices/dataUsage/guest/monthlySlice';

import { actions as guestDailyOnlineTime } from './slices/onlineTime/guest/dailyOnlineTimeSlice';
import { actions as personsDailyOnlineTime } from './slices/onlineTime/persons/dailyOnlineTimeSlice';
import { actions as personsMonthlyOnlineTime } from './slices/onlineTime/persons/monthlyOnlineTimeSlice';
import { actions as personDailyDataUsage } from './slices/dataUsage/person/dailySlice';
import { actions as personMonthlyDataUsage } from './slices/dataUsage/person/monthlySlice';
import { actions as personsMonthlyAppsOnlineTime } from './slices/onlineTime/persons/monthlyAppsOnlineTimeSlice';
import { actions as personsLast12MonthsAppsOnlineTime } from './slices/onlineTime/persons/last12monthsAppsOnlineTimeSlice';

import { actions as deviceDailyOnlineTime } from './slices/onlineTime/device/dailyDeviceOnlineTimeSlice';
import { actions as deviceMonthlyOnlineTime } from './slices/onlineTime/device/monthlyDeviceOnlineTimeSlice';
import { actions as deviceDailyDataUsage } from './slices/dataUsage/device/dailySlice';
import { actions as deviceMonthlyDataUsage } from './slices/dataUsage/device/monthlySlice';

import { actions as guestDailyAppsOnlineTime } from './slices/appsOnlineTime/guest/dailyAppsOnlineTimeSlice';
import { actions as guestMonthlyAppsOnlineTime } from './slices/appsOnlineTime/guest/monthlyAppsOnlineTimeSlice';
import { actions as employeeMonthlyAppsOnlineTime } from './slices/appsOnlineTime/employee/monthlyAppsOnlineTimeSlice';
import { actions as defaultMonthlyAppsOnlineTime } from './slices/appsOnlineTime/default/monthlyAppsOnlineTimeSlice';
import { actions as guestLast12MonthsAppsOnlineTime } from './slices/appsOnlineTime/guest/last12MonthsAppsOnlineTimeSlice';
import { actions as employeeLast12MonthsAppsOnlineTime } from './slices/appsOnlineTime/employee/last12MonthsAppsOnlineTimeSlice';
import { actions as defaultLast12MonthsAppsOnlineTime } from './slices/appsOnlineTime/default/last12MonthsAppsOnlineTimeSlice';

export const settings = {
  wifi,
  networkAccess,
  pods,
  securityPolicy,
  employeeLogin,
  blockedEmployees,
};
export const zones = { devices, deviceGroups, employeeGroupShareAccess };
export const ui = { devicesAndGroups, page, miniBanner, errorAlert };

export const dataUsage = {
  default: {
    daily: defaultDailyDataUsage,
    monthly: defaultMonthlyDataUsage,
  },
  employee: {
    daily: employeeDailyDataUsage,
    monthly: employeeMonthlyDataUsage,
  },
  guest: {
    daily: guestDailyDataUsage,
    monthly: guestMonthlyDataUsage,
  },
  person: {
    daily: personDailyDataUsage,
    monthly: personMonthlyDataUsage,
  },
  device: {
    daily: deviceDailyDataUsage,
    monthly: deviceMonthlyDataUsage,
  },
};

export const onlineTime = {
  guest: {
    daily: guestDailyOnlineTime,
  },
  persons: {
    daily: personsDailyOnlineTime,
    monthly: personsMonthlyOnlineTime,
  },
  device: {
    daily: deviceDailyOnlineTime,
    monthly: deviceMonthlyOnlineTime,
  },
};

export const appsOnlineTime = {
  guest: {
    daily: guestDailyAppsOnlineTime,
    monthly: guestMonthlyAppsOnlineTime,
    last12months: guestLast12MonthsAppsOnlineTime,
  },
  employee: {
    monthly: employeeMonthlyAppsOnlineTime,
    last12months: employeeLast12MonthsAppsOnlineTime,
  },
  default: {
    monthly: defaultMonthlyAppsOnlineTime,
    last12months: defaultLast12MonthsAppsOnlineTime,
  },
  persons: {
    monthly: personsMonthlyAppsOnlineTime,
    last12months: personsLast12MonthsAppsOnlineTime,
  },
  // device: {
  //   daily: deviceDailyOnlineTime,
  //   monthly: deviceMonthlyOnlineTime,
  // },
};

export { actions as debug } from './slices/debugSlice';
export { actions as build } from './slices/buildSlice';
export { actions as auth } from './slices/authSlice';
export { actions as magicLink } from './slices/magicLinkSlice';
export { actions as customer } from './slices/customerSlice';
export { actions as device } from './slices/deviceSlice';
export { actions as employees } from './slices/employeesSlice';
export { actions as employee } from './slices/employeeSlice';
export { actions as speedTest } from './slices/speedTestSlice';
export { actions as locations } from './slices/locationsSlice';
export { actions as locationAppTime } from './slices/locationAppTimeSlice';
export { actions as locationAtWorkEvents } from './slices/locationAtWorkEventsSlice';
export { actions as guestNetworkUsage } from './slices/guestNetworkUsageSlice';
export { actions as guests } from './slices/guestsSlice';
export { actions as securityEvents } from './slices/securityEvents';
export { actions as deviceSecurityEvents } from './slices/deviceSecurityEventsSlice';
export { actions as employeeSecurityEvents } from './slices/employeeSecurityEventsSlice';
export { actions as topology } from './slices/topologySlice';
export { actions as pubNub } from './slices/pubNubSlice';
export { actions as customerSupportConfigurations } from './slices/customerSupportConfigurations';
export { actions as guestEmailCollection } from './slices/guestEmailCollectionSlice';
export { actions as deviceSecurityPolicy } from './slices/deviceSecurityPolicySlice';
export { actions as personSecurityPolicy } from './slices/personSecurityPolicySlice';
export { actions as globalAuthLoginOptions } from './slices/globalAuthLoginOptionsSlice';
export { actions as applicationInfo } from './slices/applicationInfoSlice';
export { actions as locationCapabilities } from './slices/locationCapabilitiesSlice';
