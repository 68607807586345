import { ENDPOINTS, METHODS, callApi, callApiDirect } from './';
import { BellLoginResponse } from 'Consts/bellTypes';
import { CloudArg } from './endpoints';
import { CloudBackend, ExchangeTokenResponse } from 'Consts/types';

export const FONSE_DATA_STRINGS = {
  loginWithUsername(username: string, password: string) {
    return {
      sections: ['internetAccounts'],
      authMethods: ['credentials'],
      credentials: [
        { organization: 'bell', username: username, password: password },
      ],
      accountType: 'business',
    };
  },
};

export const BELL_FONSE_URLS: Partial<Record<CloudBackend, string>> = {
  ETNA: 'https://ott-api-s2.c.i.klab.f0ns3.ca',
  DELTA: 'https://ott-api.c.i.kprod.f0ns3.ca',
};

const BELL_X_Bell_API_Key = 'plume-workpass-19z0deec';

export const loginWithBellPassword = ({
  cloud,
  username,
  password,
}: CloudArg & { username: string; password: string }) => {
  return callApiDirect<BellLoginResponse>({
    url: ENDPOINTS.bellPasswordLogin(cloud),
    method: METHODS.POST,
    data: FONSE_DATA_STRINGS.loginWithUsername(username, password),
    incomingHeaders: [{ name: 'X-Bell-API-Key', value: BELL_X_Bell_API_Key }],
  });
};

export const exchangeTokenBell = ({
  cloud,
  validationToken,
  b1,
  ttl = 259200,
}: CloudArg & {
  validationToken?: string;
  b1?: { validationToken: string; sections: any };
  ttl?: number;
}) => {
  return callApi<ExchangeTokenResponse>({
    url: ENDPOINTS.iguanaExchangeToken(),
    method: METHODS.POST,
    data: { b1, validationToken, ttl },
    cloud,
    repoBaseUrl: 'provisioning',
  });
};
