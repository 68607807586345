import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import { Body1, Heading3Medium } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';

import { memo } from 'react';

type OnboardingHeaderProps = {
  className?: string;
  label: string;
  subLabel: string;
};

const OnboardingHeader: FunctionComponent<OnboardingHeaderProps> = ({
  className,
  label,
  subLabel,
}) => {
  return (
    <div className={cn(styles.onboardingHeader, className)}>
      <Heading3Medium>{label}</Heading3Medium>
      <Body1 className={colorStyles.still400}>{subLabel}</Body1>
    </div>
  );
};
export default memo(OnboardingHeader);
