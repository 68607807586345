import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePubNubRegistration = () =>
  useUpdatedData({
    selector: selectors.pubNub,
    action: actions.pubNub.fetchData(),
    enableRefresh: false,
  });

export default usePubNubRegistration;
