import { ROUTES } from 'Consts/routes';
import { matchPath } from 'react-router';

export const flattenRoutes = (routes: any) => {
  let allRoutes: string[] = [];

  for (const key in routes) {
    if (typeof routes[key as string] === 'object') {
      const temp = flattenRoutes(routes[key as string]);
      allRoutes.push(...temp);
    } else {
      allRoutes.push(routes[key as any] as string);
    }
  }
  return allRoutes;
};

export const verifyRedirectRoute = (redirectRoute: string) => {
  const allRoutes = flattenRoutes(ROUTES);
  let redirectRouteIsValid = false;

  if (redirectRoute.includes('appTimeInsights')) {
    const redirectRouteParts = redirectRoute.split('/');

    redirectRouteIsValid = ['employee', 'default', 'guest'].includes(
      redirectRouteParts[redirectRouteParts.length - 1]
    );
    return redirectRouteIsValid;
  }

  allRoutes.forEach((route) => {
    const matchedRoute = matchPath({ path: route, end: true }, redirectRoute);
    if (matchedRoute) {
      redirectRouteIsValid = true;
    }
  });

  return redirectRouteIsValid;
};

export const getPostAuthRoute = () => {
  const redirectRoute = localStorage.getItem('redirectRoute');

  if (redirectRoute && verifyRedirectRoute(redirectRoute)) {
    if (redirectRoute.includes('settings')) {
      localStorage.setItem('settingsExpanded', 'true');
    }
    if (redirectRoute.includes('zones')) {
      localStorage.setItem('zonesExpanded', 'true');
    }
    localStorage.removeItem('redirectRoute');
    return redirectRoute;
  } else {
    localStorage.removeItem('redirectRoute');
    return ROUTES.home.index;
  }
};
