import { useEffect, useRef } from 'react';

export type MenuOpenTriggerEventType =
  | MENU_OPEN_TRIGGER_EVENTS.CLICK
  | MENU_OPEN_TRIGGER_EVENTS.KEYDOWN;
export enum MENU_OPEN_TRIGGER_EVENTS {
  CLICK = 'click',
  KEYDOWN = 'keydown',
}

interface UseFocusFirstInteractableProps {
  focusOnMount?: boolean;
  focusWhen?: boolean;
  focusDelay?: number;
  openTriggerEventType?: MenuOpenTriggerEventType;
}

const useFocusFirstInteractable = <T extends HTMLElement>({
  focusOnMount = true,
  focusWhen,
  focusDelay = 300,
  openTriggerEventType,
}: UseFocusFirstInteractableProps) => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (
      focusOnMount &&
      openTriggerEventType === MENU_OPEN_TRIGGER_EVENTS.KEYDOWN
    ) {
      const timer = setTimeout(() => {
        if (ref.current) {
          const firstFocusableElement = ref.current.querySelector('[tabindex]');
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }
      }, focusDelay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [focusOnMount, openTriggerEventType]);

  useEffect(() => {
    if (
      focusWhen &&
      openTriggerEventType === MENU_OPEN_TRIGGER_EVENTS.KEYDOWN
    ) {
      const timer = setTimeout(() => {
        if (ref.current) {
          const firstFocusableElement = ref.current.querySelector('[tabindex]');
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }
      }, focusDelay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [focusWhen, openTriggerEventType]);

  return { containerRef: ref };
};

export default useFocusFirstInteractable;
