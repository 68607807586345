import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';

import Divider from 'UI/Elements/Divider';
import Icon, { IconName, IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Body3, Body3Medium } from 'UI/Elements/Typography';

import bellColorStyles from 'Styles/bellColors';
import colorStyles from 'Styles/colors.module.css';
import typography from 'Styles/typography.module.css';

import { getConfigurationFromDomain } from 'subDomainConfiguration';
import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

import { memo } from 'react';

export type InputListItemProps = {
  value: string;
  inputType?: 'text' | 'password' | 'number' | 'dropdown';
  placeholder?: string;
  smallLabel?: string;
  paragraph?: string;
  className?: string;
  prefixIcon?: IconName;
  suffixIcon?: IconName;
  onDropdownClick?: (ev: React.MouseEvent) => void;
  onChange?: (value: string) => void;
  onSubmit?: () => void;
  hasError?: boolean;
  noIcon?: boolean;
  disabled?: boolean;
  shouldAutoFocus?: boolean;
  dropdownMenuExpanded?: boolean;
  disableAutofill?: boolean;
};

const InputListItem: FunctionComponent<InputListItemProps> = ({
  value,
  placeholder,
  smallLabel,
  paragraph,
  className,
  prefixIcon,
  inputType = 'text',
  hasError,
  onDropdownClick,
  onChange,
  onSubmit,
  noIcon,
  disabled,
  shouldAutoFocus,
  dropdownMenuExpanded,
  disableAutofill,
}) => {
  const { t } = useTranslation();
  const environment = getConfigurationFromDomain();
  const isBell = environment.id === 'bell';
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasError && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasError]);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(ev.target.value);
    },
    [onChange]
  );

  const handleShowPassword = useCallback(() => {
    setShowPassword((prevValue) => !prevValue);
  }, []);

  const handleClear = useCallback(() => {
    if (!disabled) onChange?.('');
  }, [onChange]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleSubmit = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter' && onSubmit) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const passwordEyeIcon = showPassword ? IconNames.EyeSlash : IconNames.Eye;
  const passwordTooltip = showPassword
    ? 'common.hidePassword'
    : 'common.showPassword';

  const errorMessageId = useMemo(
    () =>
      `${(placeholder || smallLabel || uniqueId())
        .toLowerCase()
        .split(' ')
        .join('-')}-msg`,
    [placeholder, smallLabel]
  );

  const inputName = placeholder || smallLabel;

  return (
    <div
      className={cn(
        styles.listInput,
        {
          [styles.danger]: hasError,
          [styles.active]: isFocused,
        },
        className
      )}
      onClick={inputType === 'dropdown' ? onDropdownClick : undefined}
      tabIndex={onDropdownClick ? 0 : undefined}
      onKeyDown={
        inputType === 'dropdown'
          ? (e) => onEnterKeydown(e, onDropdownClick)
          : undefined
      }
    >
      <div
        className={cn(styles.aboveInputLine, {
          [styles.listInputDisabled]: disabled,
        })}
      >
        {prefixIcon && (
          <Icon
            className={cn(styles.prefixIcon, {
              [styles.iconDisabled]: disabled,
            })}
            name={prefixIcon}
            ariaLabel={placeholder}
          />
        )}

        <div
          className={cn(styles.labeledContent, {
            [styles.labeledContentNoIcon]: noIcon,
          })}
        >
          {smallLabel && (
            <label htmlFor={'list-input'}>
              <Body3Medium
                className={
                  isBell ? bellColorStyles.still400 : colorStyles.still400
                }
              >
                {smallLabel}
              </Body3Medium>
            </label>
          )}
          <input
            id="list-input"
            disabled={disabled}
            type={!showPassword ? inputType : 'text'}
            className={cn(typography.text, typography.body1, {
              [styles.inputDisabled]: disabled,
            })}
            value={value}
            autoComplete={disableAutofill || disabled ? 'off' : 'on'}
            placeholder={placeholder}
            aria-label={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleSubmit}
            data-testid={'list-input'}
            autoFocus={shouldAutoFocus}
            title={inputName}
            aria-invalid={hasError}
            aria-describedby={errorMessageId}
            name={inputName}
            ref={inputRef}
            readOnly={disabled || inputType === 'dropdown'}
          />
        </div>

        {inputType === 'password' && value && (
          <Icon
            className={cn(styles.r, {
              [styles.iconDisabled]: disabled,
            })}
            shape={IconShape.square}
            name={passwordEyeIcon}
            tooltipLabel={t(passwordTooltip)}
            onClick={handleShowPassword}
          />
        )}

        {inputType === 'dropdown' && (
          <Icon
            className={cn(styles.r, {
              [styles.iconDisabled]: disabled,
            })}
            name={
              dropdownMenuExpanded ? IconNames.ChevronUp : IconNames.ChevronDown
            }
            shape={IconShape.square}
            testid={'dropdownIcon'}
          />
        )}

        {inputType !== 'password' &&
          inputType !== 'dropdown' &&
          value &&
          !disabled && (
            <Icon
              className={cn(styles.r, {
                [styles.iconDisabled]: disabled,
              })}
              shape={IconShape.square}
              name={IconNames.X}
              tooltipLabel={t('common.clear')}
              onClick={handleClear}
            />
          )}
      </div>

      <Divider
        className={styles.line}
        color={isBell ? bellColorStyles.still400 : undefined}
      />
      <div
        aria-live="polite"
        role="alert"
        id={errorMessageId}
        className={styles.belowInputLine}
      >
        {paragraph && <Body3>{paragraph}</Body3>}
      </div>
    </div>
  );
};
export default memo(InputListItem);
