import { createStandardSlice } from 'State/utils';
import { Action, RootState } from 'State/store';
import * as api from 'Api/endpoints';
import { ConfigAndStateType } from 'Consts/types';

const slice = createStandardSlice<ConfigAndStateType>('locationCapabilities');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocation } = getState().locations;
    const customerId = activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!activeLocation?.id || !token || !customerId) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getLocationCapabilities({
      customerId,
      locationId: activeLocation.id,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(
        actions.error(error?.message ?? 'Error fetching location capabilities')
      );
      return;
    }

    dispatch(actions.set(data));
  };
};

export const selectors = (state: RootState) => state.locationCapabilities;
export const actions = {
  ...slice.actions,
  fetchData,
};

export default slice.reducer;
