import cn from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './style.module.css';

import { memo } from 'react';

type TooltipProps = {
  className?: string;
  style?: React.CSSProperties;
  isPoliteTooltip?: boolean;
};

const Tooltip = forwardRef<HTMLDivElement, PropsWithChildren<TooltipProps>>(
  ({ children, className, isPoliteTooltip = false, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(styles.tooltip, className)}
        {...props}
        role={isPoliteTooltip ? 'tooltip' : 'status'}
        aria-live={!isPoliteTooltip ? 'assertive' : undefined}
      >
        {children}
      </div>
    );
  }
);
export default memo(Tooltip);
