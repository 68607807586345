import * as api from 'Api/endpoints';
import { Mode, Node, Pod } from 'Consts/types';

import type { RootState, Action } from 'State/store';
import * as globalActions from 'State/actions';
import { createStandardSlice } from 'State/utils';

const sanitizeNode = (node: Node): Pod => ({
  id: node.id,
  connectionState: node.connectionState,
  defaultName: node.defaultName,
  nickname: node.nickname,
  model: node.model,
  firmwareVersion: node.firmwareVersion,
  mac: node.mac,
  ethernet1Mac: node.ethernet1Mac,
  radioMac24: node.radioMac24,
  radioMac50L: node.radioMac50L,
  radioMac50U: node.radioMac50U,
  serialNumber: node.serialNumber,
  healthStatus: node.health?.status,
  ethernetLan: node.ethernetLan,
  capabilities: node.capabilities,
});

const slice = createStandardSlice<Record<string, Pod>>('pods');

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const podsData = getState().settings.pods;

    if (podsData.isLoading || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getNodes({
      customerId,
      locationId: activeLocationId,
      token,
      cloud,
    });

    if (error || !data?.nodes?.length) {
      dispatch(actions.error(error?.message || 'Error fetching pods data'));

      return;
    }

    const mappedNodes = data.nodes.reduce((acc, node) => {
      acc[node.id] = sanitizeNode(node);

      return acc;
    }, {} as Record<string, Pod>);

    dispatch(actions.set(mappedNodes));
  };
};

const updatePod = (node: { id: string; nickname: string }): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { id, nickname } = node;

    const { data, error } = await api.updatePod({
      customerId,
      locationId: activeLocationId,
      nodeId: id || '',
      data: { nickname },
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error updating pod'));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const deletePod = (id: string): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const pods = getState().settings.pods.data;

    if (!pods?.[id] || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.deletePod({
      customerId,
      locationId: activeLocationId,
      nodeId: id || '',
      token,
      cloud,
    });

    if (error) {
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: error?.message,
          state: 'unset',
        })
      );
      dispatch(actions.error(error?.message || 'Error deleting pod'));
      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const ethernetTogglePod = (id: string, mode: Mode = 'auto'): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;
    const pods = getState().settings.pods.data;

    if (!pods?.[id] || !customerId || !activeLocationId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { error } = await api.updatePodEthernetLan({
      customerId,
      locationId: activeLocationId,
      nodeId: id,
      data: { default: { mode: mode } },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error?.message));
      dispatch(
        globalActions.ui.miniBanner.notify({
          label: error?.message,
          state: 'unset',
        })
      );
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

export const selectors = (state: RootState) => state.settings.pods;

export const actions = {
  ...slice.actions,
  fetchData,
  updatePod,
  deletePod,
  ethernetTogglePod,
};

export default slice.reducer;
