import cx from 'classnames';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import useIsMobile from '../../../Utils/hooks/useIsMobile';
import Icon from '../../Elements/Icon';
import { IconName, IconShape } from '../../Elements/Icon/icons';
import AutoCompleteResults, {
  AutoCompleteResultsItem,
} from './AutoCompleteResults';
import { AutoCompleteContext } from './context';
import { getDefaultConfiguration } from './helpers/configuration.helper';
import useAutoComplete from './hooks/useAutoComplete';
import styles from './style.module.css';

import { memo } from 'react';

export type AutoCompleteFilter = {
  label: string;
  name: string;
};

export type AutoCompleteParams = {
  selectedFilter?: AutoCompleteFilter;
};

export type AutoCompleteConfiguration = {
  minCharactersToSearch: number;
  placeholderText: string;
  noResultsText: string;
  displayFilters: boolean;
  closeOnSelectItem: boolean;
  icon?: IconName;
  iconAriaLabel?: string;
  clearIcon?: boolean;
  clearQuery?: boolean;
  maxHeight?: number;
  openInPortal?: boolean;
  searchInputSmall?: boolean;
};
export type AutoCompleteEvents = {
  onSearch?: (
    query: string,
    params: AutoCompleteParams
  ) => Promise<AutoCompleteResultsItem[]>;
  onError?: (error: any) => void;
};
export type AutoCompleteProps = {
  availableFilters?: AutoCompleteFilter[];
  configuration?: Partial<AutoCompleteConfiguration>;
  onItemClick?: (item: AutoCompleteResultsItem) => void;
  className?: string;
  resultsClassname?: string;
  isShieldAutoComplete?: boolean;
  autoCompleteInputId?: string;
} & AutoCompleteEvents &
  JSX.IntrinsicElements['div'];

export type AutoCompleteStyles = {
  wrapper: string;
  root: string;
  input: string;
  icon: string;
};
const BELL = 'bell';
const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
  onSearch,
  onError,
  configuration,
  onItemClick,
  availableFilters = [],
  className,
  resultsClassname,
  isShieldAutoComplete = false,
  autoCompleteInputId,
}) => {
  const config = getDefaultConfiguration(configuration);
  const isMobile = useIsMobile();
  const [searchOpen, setSearchOpen] = useState(false);
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  const events = {
    onSearch,
    onError,
  };
  const {
    isExpanded,
    setIsExpanded,
    onInputFieldClick,
    autoCompleteResults,
    isLoading,
    onInput,
    query,
    setQuery,
  } = useAutoComplete(availableFilters, events, config);
  const { t } = useTranslation();

  const elRef = useRef(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [focusFirstItem, setFocusFirstItem] = useState(false);
  const [activeDescendantId, setActiveDescendantId] = useState('');

  const onSelectedItemClick = (item: AutoCompleteResultsItem) => {
    setQuery(item.label);
    onItemClick?.(item);
    if (configuration?.clearQuery) {
      setQuery('');
    }
    if (isMobile) {
      setSearchOpen(false);
    }
  };

  const onClear = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      setIsExpanded(false);
    }
  };

  const containerClassNames = cx({
    [styles.autoCompleteMobileContainer]: isMobile,
    [styles.autoCompleteMobileContainerExpanded]: isMobile && searchOpen,
  });

  const backIconClassNames = cx(styles.iconParent, {
    [styles.autoCompleteBackIcon]: isMobile,
  });

  useEffect(() => {
    if (isMobile && searchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isMobile, searchOpen]);

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && isExpanded) {
      if (!e.shiftKey) {
        e.preventDefault();
        setFocusFirstItem(true);
        setTimeout(() => {
          setFocusFirstItem(false);
        }, 0);
      } else {
        e.preventDefault();
        setFocusFirstItem(false);
        searchInputRef.current?.focus();
      }
    }
  };

  return (
    <div className={containerClassNames}>
      {isMobile && !searchOpen && (
        <Icon
          name={config.icon || t('common.search')}
          shape={IconShape.circle}
          className={styles.iconParent}
          onClick={() => setSearchOpen(true)}
          ariaLabel={config.iconAriaLabel || config.icon || t('common.search')}
        />
      )}
      {isMobile && searchOpen && (
        <Icon
          name="ChevronLeft"
          shape={IconShape.square}
          className={backIconClassNames}
          onClick={() => setSearchOpen(false)}
          ariaLabel={t('common.back')}
        />
      )}

      <div
        className={cx(
          styles.autoCompleteWrapper,
          {
            [styles.bellAutoCompleteWrapper]: isBell,
          },
          className
        )}
        ref={elRef}
        role="search"
      >
        <AutoCompleteContext.Provider
          value={{
            configuration: config,
            isLoading,
            availableFilters,
            closeSearchResults: () => setIsExpanded(false),
          }}
        >
          <Icon
            name={config.icon || t('common.search')}
            className={styles.iconParent}
            ariaLabel={
              config.iconAriaLabel || config.icon || t('common.search')
            }
          />
          <div className={styles.autoCompleteInputContainer}>
            <label
              htmlFor={autoCompleteInputId}
              className={cx(query ? styles.inputLabelHidden : '', {
                [styles.bellAutoCompleteLabel]: isBell,
              })}
            >
              {config.placeholderText || config.placeholderText === ''
                ? config.placeholderText
                : t('search.searchPlaceholder')}
            </label>
            <input
              id={autoCompleteInputId}
              ref={searchInputRef}
              data-testid="autoComplete-input"
              name="search"
              type="text"
              autoComplete="off"
              onClick={onInputFieldClick}
              value={query}
              onInput={(e) => onInput(e.currentTarget.value)}
              onChange={(e) => onClear(e)}
              className={
                isBell ? styles.bellAutoCompleteInput : styles.autoCompleteInput
              }
              onKeyDown={handleInputKeyDown}
              role="combobox"
              aria-autocomplete="both"
              aria-expanded={isExpanded}
              aria-owns="auto-complete-list"
              aria-activedescendant={activeDescendantId}
              aria-controls="auto-complete-list"
            />
          </div>
          {isExpanded && (
            <div ref={listRef}>
              <AutoCompleteResults
                maxHeight={
                  isMobile
                    ? window.innerHeight -
                      (listRef.current?.getBoundingClientRect().y || 0) -
                      30
                    : configuration?.maxHeight
                }
                items={autoCompleteResults}
                onItemClick={onSelectedItemClick}
                isOpen={isExpanded}
                toggle={(state: boolean) => setIsExpanded(state)}
                resultsClassname={resultsClassname}
                isShieldAutoComplete={isShieldAutoComplete}
                focusFirstItem={focusFirstItem}
                setActiveDescendantId={setActiveDescendantId}
              />
            </div>
          )}
        </AutoCompleteContext.Provider>
      </div>
      {isMobile && searchOpen && (
        <div className={styles.autoCompleteSeparator} />
      )}
    </div>
  );
};
export default memo(AutoComplete);
