import { CustomerSupportConfiguration } from 'Consts/types';

export const DEFAULTCUSTOMERSUPPORTCONFIGURATIONS: CustomerSupportConfiguration =
{
  customerFeatureEnablement: {
    faqEnabled: true,
    addPodEnabled: true,
    removePodEnabled: true,
    contactUsEnabled: true,
    callUsEnabled: true,
    multilocationEnabled: true,
    buyPodEnabled: true,
    partnerBuyPodUrlEnabled: false,
    privacyEnabled: true,
    termsEnabled: true,
    removeGatewayEnabled: true,
    manageYourDataEnabled: true,
    autorunSpeedtestToggleEnabled: true,
    manageAccountEnabled: false,
    partnerAccountEnabled: false,
    chatSupportEnabled: true,
    hideIspNameEnabled: false,
    displayCustomLogoEnabled: false,
    mobileCustomDeeplinkEnabled: false,
    employeeOnboardingCaptivePortalEnabled: false,
    hideSpeedTestUiEnabled: true,
    captivePortalGuestEmailDownloadDisabled: false,
    applicationMonitoringPrivacyPromptEnabled: false,
    applicationMonitoringEmployeeNetworkDisabled: false,
  },
  customerConfigurations: {
    privacyUrl: 'https://www.plume.com/workpass/legal-sb',
    termsUrl: 'https://www.plume.com',
    faqUrl: 'https://wpsupport.plume.com/hc/en-us',
    contactUsEmail: 'support@plume.com',
    callUsPhoneNumber: '+448009176318',
    callUsDisplayPhoneNumber: '+44 800 917 6318',
    manageYourDataUrl: 'https://preferences.plume.com/dont_sell',
    plumeAccountUrl: 'https://www.ggg',
    partnerAccountUrl: 'https://www.ggg',
    chatSupportUrl: 'https://www.plume.com/csu',
    mobileCustomDeeplinkUrl: 'plumeworkpass://',
    androidStoreApplicationUrl: 'https://www.google.com',
    iosStoreApplicationUrl: 'https://www.apple.com',
  },
  customerBrandingFiles: {
    webBrandingFiles: {
      logoResource: 'https://', // future use
    },
  },
  updatedAt: '2023-02-14 14:20:00',
};
