// 4px icons
import { ReactComponent as Dot } from 'Assets/Icons/Icon-4/Dot.svg';

// 16px icons
import { ReactComponent as ArrowDownRight } from 'Assets/Icons/Icon-16/Arrow down right.svg';
import { ReactComponent as ArrowUpRight } from 'Assets/Icons/Icon-16/Arrow up right.svg';
import { ReactComponent as Info16 } from 'Assets/Icons/Icon-16/info.svg';

// 24px icons
import { ReactComponent as AddPerson } from 'Assets/Icons/Icon-24/Add Person.svg';
import { ReactComponent as Antenna } from 'Assets/Icons/Icon-24/Antenna.svg';
import { ReactComponent as ArrowDownStop } from 'Assets/Icons/Icon-24/Arrow down stop.svg';
import { ReactComponent as ArrowRightStop } from 'Assets/Icons/Icon-24/Arrow right stop.svg';
import { ReactComponent as Badge1 } from 'Assets/Icons/Icon-24/Badge 1.svg';
import { ReactComponent as Badge2 } from 'Assets/Icons/Icon-24/Badge 2.svg';
import { ReactComponent as Badge3 } from 'Assets/Icons/Icon-24/Badge 3.svg';
import { ReactComponent as Guard } from 'Assets/Icons/Icon-24/Guard.svg';
import { ReactComponent as Bell } from 'Assets/Icons/Icon-24/Bell.svg';
import { ReactComponent as Call } from 'Assets/Icons/Icon-24/Call.svg';
import { ReactComponent as Cart } from 'Assets/Icons/Icon-24/Cart.svg';
import { ReactComponent as Check } from 'Assets/Icons/Icon-24/Check.svg';
import { ReactComponent as CheckListOff } from 'Assets/Icons/Icon-24/Checklist off.svg';
import { ReactComponent as CheckListOn } from 'Assets/Icons/Icon-24/Checklist on.svg';
import { ReactComponent as CheckListOffBell } from 'Assets/Icons/Icon-24/ChecklistOff_Bell.svg';
import { ReactComponent as CheckListOnBell } from 'Assets/Icons/Icon-24/ChecklistOn_Bell.svg';
import { ReactComponent as ChevronDown } from 'Assets/Icons/Icon-24/Chevron down.svg';
import { ReactComponent as ChevronLeftCompact } from 'Assets/Icons/Icon-24/Chevron left compact.svg';
import { ReactComponent as ChevronLeft } from 'Assets/Icons/Icon-24/Chevron left.svg';
import { ReactComponent as ChevronRightCompact } from 'Assets/Icons/Icon-24/Chevron right compact.svg';
import { ReactComponent as ChevronRight } from 'Assets/Icons/Icon-24/Chevron right.svg';
import { ReactComponent as ChevronUp } from 'Assets/Icons/Icon-24/Chevron up.svg';
import { ReactComponent as CirclePlus } from 'Assets/Icons/Icon-24/Circle Plus.svg';
import { ReactComponent as CircleFirmware } from 'Assets/Icons/Icon-24/Circle firmware.svg';
import { ReactComponent as CircleInfo } from 'Assets/Icons/Icon-24/Circle info.svg';
import { ReactComponent as CircleInternet } from 'Assets/Icons/Icon-24/Circle internet.svg';
import { ReactComponent as CircleQuestionMark } from 'Assets/Icons/Icon-24/Circle question mark.svg';
import { ReactComponent as CircleX } from 'Assets/Icons/Icon-24/Circle x.svg';
import { ReactComponent as ClockHistory } from 'Assets/Icons/Icon-24/Clock history.svg';
import { ReactComponent as Clock } from 'Assets/Icons/Icon-24/Clock.svg';
import { ReactComponent as CloseButton } from 'Assets/Icons/Icon-24/Close button.svg';
import { ReactComponent as Copy } from 'Assets/Icons/Icon-24/Copy.svg';
import { ReactComponent as DevicesFill } from 'Assets/Icons/Icon-24/Devices fill.svg';
import { ReactComponent as Devices } from 'Assets/Icons/Icon-24/Devices.svg';
import { ReactComponent as Edit } from 'Assets/Icons/Icon-24/Edit.svg';
import { ReactComponent as EmailIcon } from 'Assets/Icons/Icon-24/Email.svg';
import { ReactComponent as EyeSlash } from 'Assets/Icons/Icon-24/Eye slash.svg';
import { ReactComponent as Eye } from 'Assets/Icons/Icon-24/Eye.svg';
import { ReactComponent as Filter } from 'Assets/Icons/Icon-24/Filter.svg';
import { ReactComponent as Flag } from 'Assets/Icons/Icon-24/Flag.svg';
import { ReactComponent as FullScreenExit } from 'Assets/Icons/Icon-24/Fullscreen exit.svg';
import { ReactComponent as FullScreen } from 'Assets/Icons/Icon-24/Fullscreen.svg';
import { ReactComponent as Globe } from 'Assets/Icons/Icon-24/Globe.svg';
import { ReactComponent as Graph } from 'Assets/Icons/Icon-24/Graph bar.svg';
import { ReactComponent as Hamburger } from 'Assets/Icons/Icon-24/Hamburger.svg';
import { ReactComponent as HandSecurity } from 'Assets/Icons/Icon-24/Hand security.svg';
import { ReactComponent as Hand } from 'Assets/Icons/Icon-24/Hand.svg';
import { ReactComponent as HomeAdd } from 'Assets/Icons/Icon-24/Home add.svg';
import { ReactComponent as HomeFill } from 'Assets/Icons/Icon-24/Home fill.svg';
import { ReactComponent as Home } from 'Assets/Icons/Icon-24/Home.svg';
import { ReactComponent as Info } from 'Assets/Icons/Icon-24/Info.svg';
import { ReactComponent as Key } from 'Assets/Icons/Icon-24/Key.svg';
import { ReactComponent as LaptopAndPhone } from 'Assets/Icons/Icon-24/LaptopAndPhone.svg';
import { ReactComponent as MagicLink } from 'Assets/Icons/Icon-24/Magic link.svg';
import { ReactComponent as MapPin } from 'Assets/Icons/Icon-24/Map pin.svg';
import { ReactComponent as Map } from 'Assets/Icons/Icon-24/Map.svg';
import { ReactComponent as Minus } from 'Assets/Icons/Icon-24/Minus.svg';
import { ReactComponent as OpenBrowser } from 'Assets/Icons/Icon-24/Open browser.svg';
import { ReactComponent as OverflowHorizontal } from 'Assets/Icons/Icon-24/Overflow horizontal.svg';
import { ReactComponent as OverflowVertical } from 'Assets/Icons/Icon-24/Overflow vertical.svg';
import { ReactComponent as PauseOutline } from 'Assets/Icons/Icon-24/Pause-outline.svg';
import { ReactComponent as PeopleTwo } from 'Assets/Icons/Icon-24/People two.svg';
import { ReactComponent as PersonExclamation } from 'Assets/Icons/Icon-24/Person exclamation.svg';
import { ReactComponent as PersonProfile } from 'Assets/Icons/Icon-24/Person profile.svg';
import { ReactComponent as Person } from 'Assets/Icons/Icon-24/Person.svg';
import { ReactComponent as Phone } from 'Assets/Icons/Icon-24/Phone.svg';
import { ReactComponent as Plus } from 'Assets/Icons/Icon-24/Plus.svg';
import { ReactComponent as PodCheck } from 'Assets/Icons/Icon-24/Pod check.svg';
import { ReactComponent as Pod } from 'Assets/Icons/Icon-24/Pod default.svg';
import { ReactComponent as PodIssue } from 'Assets/Icons/Icon-24/Pod issue.svg';
import { ReactComponent as PodWaves } from 'Assets/Icons/Icon-24/Pod waves.svg';
import { ReactComponent as Printer } from 'Assets/Icons/Icon-24/Printer.svg';
import { ReactComponent as QuestionMark } from 'Assets/Icons/Icon-24/Question mark.svg';
import { ReactComponent as Search } from 'Assets/Icons/Icon-24/Search.svg';
import { ReactComponent as SecurityCheckList } from 'Assets/Icons/Icon-24/Security check list.svg';
import { ReactComponent as SecurityMask } from 'Assets/Icons/Icon-24/Security mask.svg';
import { ReactComponent as SettingsFill } from 'Assets/Icons/Icon-24/Settings fill.svg';
import { ReactComponent as Settings } from 'Assets/Icons/Icon-24/Settings.svg';
import { ReactComponent as ShareAccess } from 'Assets/Icons/Icon-24/Share access.svg';
import { ReactComponent as Share } from 'Assets/Icons/Icon-24/Share.svg';
import { ReactComponent as ShieldFill } from 'Assets/Icons/Icon-24/Shield fill.svg';
import { ReactComponent as Shield } from 'Assets/Icons/Icon-24/Shield.svg';
import { ReactComponent as SignOut } from 'Assets/Icons/Icon-24/Sign out.svg';
import { ReactComponent as SocialEmail } from 'Assets/Icons/Icon-24/Social email.svg';
import { ReactComponent as SocialFacebook } from 'Assets/Icons/Icon-24/Social facebook.svg';
import { ReactComponent as SocialPassword } from 'Assets/Icons/Icon-24/Social password.svg';
import { ReactComponent as SocialSMS } from 'Assets/Icons/Icon-24/Social sms.svg';
import { ReactComponent as SocialWifi } from 'Assets/Icons/Icon-24/Social wifi.svg';
import { ReactComponent as SpeedTest } from 'Assets/Icons/Icon-24/Speed test.svg';
import { ReactComponent as Topology } from 'Assets/Icons/Icon-24/Topology.svg';
import { ReactComponent as Trash } from 'Assets/Icons/Icon-24/Trash.svg';
import { ReactComponent as Wifi } from 'Assets/Icons/Icon-24/Wifi.svg';
import { ReactComponent as X } from 'Assets/Icons/Icon-24/X.svg';
import { ReactComponent as PrivacyModeOff } from 'Assets/Icons/Icon-24/privacy-mode off.svg';

// 30px icons
import { ReactComponent as CircleCheck } from 'Assets/Icons/Icon-30/CircleCheck.svg';

// 40px icons
import { ReactComponent as GatewayPod } from 'Assets/Icons/Icon-40/Gateway pod.svg';
import { ReactComponent as NoGatewayPod } from 'Assets/Icons/Icon-40/No Gateway Pod.svg';
import { ReactComponent as AcerLaptop } from 'Assets/Icons/Icon-40/laptop-acer-swift.svg';
import { ReactComponent as AppleLaptop } from 'Assets/Icons/Icon-40/laptop-apple-macbook.svg';
import { ReactComponent as AppleIphone } from 'Assets/Icons/Icon-40/smartphone-apple-iphone.svg';

// 24px profile icons
import { ReactComponent as Profile24Generic01 } from 'Assets/Icons/Profile 24/Generic 01.svg';
import { ReactComponent as Profile24Generic04 } from 'Assets/Icons/Profile 24/Generic 04.svg';
import { ReactComponent as Profile24Generic05 } from 'Assets/Icons/Profile 24/Generic 05.svg';
import { ReactComponent as Profile24Generic06 } from 'Assets/Icons/Profile 24/Generic 06.svg';
import { ReactComponent as Profile24Generic11 } from 'Assets/Icons/Profile 24/Generic 11.svg';
import { ReactComponent as Profile24Generic13 } from 'Assets/Icons/Profile 24/Generic 13.svg';

// 40px profile icons
import { ReactComponent as Profile40Generic01 } from 'Assets/Icons/Profile 40/Generic 01.svg';
import { ReactComponent as Profile40Generic04 } from 'Assets/Icons/Profile 40/Generic 04.svg';
import { ReactComponent as Profile40Generic05 } from 'Assets/Icons/Profile 40/Generic 05.svg';
import { ReactComponent as Profile40Generic06 } from 'Assets/Icons/Profile 40/Generic 06.svg';
import { ReactComponent as Profile40Generic11 } from 'Assets/Icons/Profile 40/Generic 11.svg';
import { ReactComponent as Profile40Generic13 } from 'Assets/Icons/Profile 40/Generic 13.svg';
import { ReactComponent as Profile40OtherAll } from 'Assets/Icons/Profile 40/Other all.svg';

// 56px profile icons
import { ReactComponent as Profile56Generic01 } from 'Assets/Icons/Profile 56/Generic 01.svg';
import { ReactComponent as Profile56Generic04 } from 'Assets/Icons/Profile 56/Generic 04.svg';
import { ReactComponent as Profile56Generic05 } from 'Assets/Icons/Profile 56/Generic 05.svg';
import { ReactComponent as Profile56Generic06 } from 'Assets/Icons/Profile 56/Generic 06.svg';
import { ReactComponent as Profile56Generic11 } from 'Assets/Icons/Profile 56/Generic 11.svg';
import { ReactComponent as Profile56Generic13 } from 'Assets/Icons/Profile 56/Generic 13.svg';

// 64px icons
import { ReactComponent as Briefcase } from 'Assets/Icons/Icon-64/Briefcase.svg';
import { ReactComponent as CommercialProperty } from 'Assets/Icons/Icon-64/CommercialProperty.svg';
import { ReactComponent as Cyber } from 'Assets/Icons/Icon-64/Cyber.svg';
import { ReactComponent as Guard64 } from 'Assets/Icons/Icon-64/Guard.svg';
import { ReactComponent as Shield64 } from 'Assets/Icons/Icon-64/Shield.svg';
import { ReactComponent as Wrench } from 'Assets/Icons/Icon-64/Wrench.svg';

// 80px profile icons
import { ReactComponent as Profile80Generic01 } from 'Assets/Icons/Profile 80/Generic 01.svg';
import { ReactComponent as Profile80Generic04 } from 'Assets/Icons/Profile 80/Generic 04.svg';
import { ReactComponent as Profile80Generic05 } from 'Assets/Icons/Profile 80/Generic 05.svg';
import { ReactComponent as Profile80Generic06 } from 'Assets/Icons/Profile 80/Generic 06.svg';
import { ReactComponent as Profile80Generic11 } from 'Assets/Icons/Profile 80/Generic 11.svg';
import { ReactComponent as Profile80Generic13 } from 'Assets/Icons/Profile 80/Generic 13.svg';

// dummy icons
import { ReactComponent as Dummy } from 'Assets/Icons/Icon-24/dummy.svg';
import { ReactComponent as Dummy40 } from 'Assets/Icons/Icon-40/dummy.svg';
import { ReactComponent as Dummy64 } from 'Assets/Icons/Icon-64/dummy.svg';

// iot icons
import { ReactComponent as IotLarge } from 'Assets/Icons/iot-large/dummy.svg';
import { ReactComponent as IotMedium } from 'Assets/Icons/iot-medium/dummy.svg';
import { ReactComponent as IotSmall } from 'Assets/Icons/iot-small/dummy.svg';

// logo icons
import { ReactComponent as BellCompanyBrandingLogo } from 'Assets/Icons/logo/BellCompanyBrandingLogo.svg';
import { ReactComponent as BellCompanyLogo } from 'Assets/Icons/logo/BellCompanyLogo.svg';
import { ReactComponent as DownloadAppStore } from 'Assets/Icons/logo/Download App Store.svg';
import { ReactComponent as DownloadGooglePlay } from 'Assets/Icons/logo/Download Google Play.svg';
import { ReactComponent as Group } from 'Assets/Icons/logo/Group.svg';
import { ReactComponent as Logo } from 'Assets/Icons/logo/logo.svg';
import { ReactComponent as LogoStacked } from 'Assets/Icons/logo/plume wordmark stacked.svg';

// illustrations
import { ReactComponent as KeycardIllustration } from 'Assets/Icons/Other-sizes/keycardIllustration.svg';
import { ReactComponent as AonAdvancedSetup } from 'Assets/Icons/Other-sizes/AonAdvancedSetup.svg';
import { ReactComponent as AonAdvancedSetupMobile } from 'Assets/Icons/Other-sizes/AonAdvancedSetup_mobile.svg';
import { ReactComponent as AppMonitoringIllustration } from 'Assets/Icons/Other-sizes/appMonitoringIllustration.svg';

export const Icons = {
  Bell,
  Check,
  ChevronLeft,
  ChevronLeftCompact,
  ChevronRight,
  ChevronRightCompact,
  ChevronDown,
  ChevronUp,
  Dummy,
  Hamburger,
  IotSmall,
  IotMedium,
  IotLarge,
  Logo,
  Profile24Generic01,
  Profile24Generic04,
  Profile24Generic05,
  Profile24Generic06,
  Profile24Generic11,
  Profile24Generic13,
  Profile40Generic01,
  Profile40Generic04,
  Profile40Generic05,
  Profile40Generic06,
  Profile40Generic11,
  Profile40Generic13,
  Profile40OtherAll,
  Profile56Generic01,
  Profile56Generic04,
  Profile56Generic05,
  Profile56Generic06,
  Profile56Generic11,
  Profile56Generic13,
  Profile80Generic01,
  Profile80Generic04,
  Profile80Generic05,
  Profile80Generic06,
  Profile80Generic11,
  Profile80Generic13,
  X,
  SocialFacebook,
  SocialEmail,
  SocialPassword,
  SocialSMS,
  Plus,
  Minus,
  Dummy40,
  Dummy64,
  PauseOutline,
  CheckListOff,
  CheckListOn,
  Search,
  GatewayPod,
  NoGatewayPod,
  ShareAccess,
  OverflowVertical,
  Dot,
  Home,
  HomeFill,
  Devices,
  DevicesFill,
  Shield,
  ShieldFill,
  SignOut,
  Topology,
  Settings,
  SettingsFill,
  Eye,
  CircleX,
  CirclePlus,
  AddPerson,
  HandSecurity,
  Hand,
  Printer,
  CircleInternet,
  SecurityMask,
  Graph,
  Info,
  Edit,
  CircleInfo,
  Trash,
  Clock,
  EyeSlash,
  ArrowDownRight,
  ArrowUpRight,
  AppleIphone,
  AppleLaptop,
  AcerLaptop,
  Wifi,
  Pod,
  Call,
  Antenna,
  OverflowHorizontal,
  Person,
  ClockHistory,
  SecurityCheckList,
  CircleFirmware,
  Key,
  Share,
  Copy,
  Globe,
  OpenBrowser,
  Info16,
  ArrowRightStop,
  ArrowDownStop,
  FullScreen,
  FullScreenExit,
  BellCompanyLogo,
  LogoStacked,
  LaptopAndPhone,
  DownloadGooglePlay,
  DownloadAppStore,
  EmailIcon,
  MagicLink,
  Group,
  PodCheck,
  PodIssue,
  Cart,
  PodWaves,
  CircleQuestionMark,
  PrivacyModeOff,
  SpeedTest,
  PeopleTwo,
  Flag,
  Map,
  MapPin,
  HomeAdd,
  SocialWifi,
  QuestionMark,
  CloseButton,
  BellCompanyBrandingLogo,
  PersonProfile,
  PersonExclamation,
  Phone,
  Filter,
  KeycardIllustration,
  Badge1,
  Badge2,
  Badge3,
  CheckListOnBell,
  CheckListOffBell,
  Guard,
  AonAdvancedSetup,
  Briefcase,
  CommercialProperty,
  Guard64,
  Shield64,
  Wrench,
  CircleCheck,
  AonAdvancedSetupMobile,
  Cyber,
  AppMonitoringIllustration,
};

export type IconName = keyof typeof Icons;

export const IconNames = (Object.keys(Icons) as IconName[]).reduce(
  (acc, key: IconName) => {
    acc[key] = key;

    return acc;
  },
  {} as Record<IconName, IconName>
);

export enum IconShape {
  auto = 'auto',
  circle = 'circle',
  rectangle = 'rectangle',
  smallCircle = 'smallCircle',
  square = 'square',
}
