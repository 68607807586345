import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import typography from 'Styles/typography.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

type TypographyProps = {
  className?: string;
  id?: string;
  onClick?: React.MouseEventHandler;
  style?: React.StyleHTMLAttributes<HTMLElement>;
  noWrap?: Boolean;
  ariaLabel?: string;
};

export const Body1: FunctionComponent<PropsWithChildren<TypographyProps>> = ({
  className,
  children,
  style,
  noWrap = false,
  id,
  onClick,
}) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.body1, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </p>
  );
};

export const Body2: FunctionComponent<PropsWithChildren<TypographyProps>> = ({
  className,
  children,
  style,
  noWrap = false,
  id,
  onClick,
}) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.body2, className, {
        [typography.noWrap]: noWrap,
      })}
      data-testid="paragraph-body-2"
      id={id}
    >
      {children}
    </p>
  );
};

export const Body3: FunctionComponent<PropsWithChildren<TypographyProps>> = ({
  className,
  children,
  style,
  noWrap = false,
  id,
  onClick,
}) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.body3, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </p>
  );
};

export const Body1Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h4
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.body1,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      data-testid="paragraph-body-1-medium"
      id={id}
    >
      {children}
    </h4>
  );
};

export const Body2Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.body2,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
    >
      {children}
    </p>
  );
};

export const Body3Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({
  className,
  children,
  style,
  noWrap = false,
  id,
  onClick,
  ariaLabel,
}) => {
  return (
    <p
      style={style}
      onClick={onClick}
      tabIndex={onClick ? 0 : -1}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
      className={cn(
        typography.text,
        typography.medium,
        typography.body3,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
      role={onClick ? 'button' : undefined}
      aria-label={ariaLabel}
    >
      {children}
    </p>
  );
};

export const FinePrint1: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.fineprint1, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </p>
  );
};

export const FinePrint2: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <p
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.fineprint2, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </p>
  );
};

export const Heading1: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h1
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.heading1, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </h1>
  );
};

export const Heading2: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h2
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.heading2, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </h2>
  );
};

export const Heading3: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h3
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.heading3, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </h3>
  );
};

export const Heading4: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h4
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.heading4, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </h4>
  );
};

export const Heading5: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h5
      style={style}
      onClick={onClick}
      className={cn(typography.text, typography.heading5, className, {
        [typography.noWrap]: noWrap,
      })}
      id={id}
    >
      {children}
    </h5>
  );
};

export const Heading1Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h1
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.heading1,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
    >
      {children}
    </h1>
  );
};

export const Heading2Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h2
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.heading2,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
    >
      {children}
    </h2>
  );
};

export const Heading3Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h3
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.heading3,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      data-testid="heading-3-medium"
      id={id}
    >
      {children}
    </h3>
  );
};

export const Heading4Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h4
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.heading4,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
    >
      {children}
    </h4>
  );
};

export const Heading5Medium: FunctionComponent<
  PropsWithChildren<TypographyProps>
> = ({ className, children, style, noWrap = false, id, onClick }) => {
  return (
    <h5
      style={style}
      onClick={onClick}
      className={cn(
        typography.text,
        typography.medium,
        typography.heading5,
        className,
        {
          [typography.noWrap]: noWrap,
        }
      )}
      id={id}
    >
      {children}
    </h5>
  );
};
