import HeaderOnboarding from 'UI/Components/Headers/Header onboarding';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import StandardListItem from 'UI/Components/Lists/List standard';
import InputListItem from 'UI/Components/Lists/List input';
import { IconNames } from 'UI/Elements/Icon';
import Button from 'UI/Elements/Button';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'Consts/routes';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import useLocations from 'State/hooks/useLocations';
import { useSsidConfiguration } from 'State/hooks/useSsidConfiguration';
import { WifiNetwork } from 'Consts/types';

import { memo } from 'react';

export type SsidSetupProps = {
  ssidType: 'secure' | 'employee' | 'guest';
  existingConfiguration:
    | undefined
    | WifiNetwork
    | { ssid: string; encryptionKey?: string };
  locationName: string | undefined;
};

const SsidSetup: FunctionComponent<SsidSetupProps> = ({
  ssidType,
  existingConfiguration,
  locationName,
}) => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  let currentRoute = routerLocation.pathname;
  const [ssidName, setSsidName] = useState('');
  const [ssidPassword, setSsidPassword] = useState('');
  const [guestWiFiEnabled, setGuestWiFiEnabled] = useState(false);
  const [nameInputError, setNameInputError] = useState('');
  const [passwordInputError, setPasswordInputError] = useState('');
  const [ctaDisabled, setCtaDisabled] = useState(false);
  const {
    updateSecureWifiConfiguration,
    updateEmployeeWifiConfiguration,
    updateGuestWifiConfiguration,
  } = useSsidConfiguration({
    setSsidName,
    setSsidPassword,
    setCtaDisabled,
    setPasswordError: setPasswordInputError,
  });
  const customerId = useSelector(selectors.locations.activeLocation)?.data
    ?.customerId;
  const { activeLocation } = useLocations();
  const { data: token } = useSelector(selectors.auth.locationToken);
  const cloud = useSelector(selectors.auth.cloud);

  useEffect(() => {
    setSsidName(
      existingConfiguration
        ? existingConfiguration.ssid
        : `${locationName}-${ssidType.charAt(0).toUpperCase()}${ssidType.slice(
            1
          )}`
    );
    setSsidPassword(
      existingConfiguration && existingConfiguration.encryptionKey
        ? existingConfiguration.encryptionKey
        : ''
    );
  }, [
    existingConfiguration?.ssid,
    existingConfiguration?.encryptionKey,
    locationName,
    ssidType,
  ]);

  const renderHeaderLabel = () => {
    switch (ssidType) {
      case 'secure':
        return t('ssidConfiguration.setup.secureWifiLabel');
      case 'employee':
        return t('ssidConfiguration.setup.employeeWifiLabel');
      case 'guest':
        return t('ssidConfiguration.setup.guestWifiLabel');
    }
  };

  const renderSubLabel = () => {
    switch (ssidType) {
      case 'secure':
        return t('ssidConfiguration.setup.secureWifiSubLabel');
      case 'employee':
        return t('ssidConfiguration.setup.employeeWifiSubLabel');
      case 'guest':
        return t('ssidConfiguration.setup.guestWifiSubLabel');
    }
  };

  const handleSsidNameChange = useCallback(
    (value: string) => {
      setSsidName(value);
      setNameInputError('');
    },
    [ssidName]
  );

  const handleSsidPasswordChange = useCallback(
    (value: string) => {
      setSsidPassword(value);
      setPasswordInputError('');
    },
    [ssidPassword]
  );

  const handleNextClick = async () => {
    if (!ssidName) {
      const error =
        t('settings.validationNotEmpty', { field: t('settings.name') }) || '';
      setNameInputError(error);
      return;
    }

    if (currentRoute !== ROUTES.ssidConfiguration.guest && !ssidPassword) {
      const error =
        t('settings.validationNotEmpty', { field: t('settings.password') }) ||
        '';
      setPasswordInputError(error);
      return;
    }

    if (!customerId || !activeLocation?.data?.id || !token || !cloud) {
      return;
    }

    switch (currentRoute) {
      case ROUTES.ssidConfiguration.secure:
        updateSecureWifiConfiguration(
          customerId,
          activeLocation?.data?.id,
          ssidName,
          ssidPassword,
          token,
          cloud
        );
        break;
      case ROUTES.ssidConfiguration.employee:
        updateEmployeeWifiConfiguration(
          customerId,
          activeLocation?.data?.id,
          ssidName,
          ssidPassword,
          token,
          cloud
        );
        break;
      case ROUTES.ssidConfiguration.guest:
        updateGuestWifiConfiguration(
          customerId,
          activeLocation?.data?.id,
          ssidName,
          guestWiFiEnabled,
          token,
          cloud
        );
        break;
    }
  };

  const onGuestWiFiEnabledChange = () => {
    setGuestWiFiEnabled((prevValue) => !prevValue);
  };

  return (
    <div className={styles.ssidSetup}>
      <HeaderOnboarding
        label={renderHeaderLabel()}
        subLabel={renderSubLabel()}
      />
      <div className={styles.setupLowerContainer}>
        <div className={styles.credentialInputs}>
          {currentRoute === ROUTES.ssidConfiguration.guest && (
            <StandardListItem
              className={styles.guestSsidToggle}
              L2Props={{
                label: t('ssidConfiguration.enableGuestWiFi'),
              }}
              RProps={{
                toggleProps: {
                  onChange: onGuestWiFiEnabledChange,
                  checked: guestWiFiEnabled,
                  ariaLabel: t('ssidConfiguration.enableGuestWiFi'),
                },
              }}
              ariaLabel={t('ssidConfiguration.enableGuestWiFi')}
            />
          )}
          <InputListItem
            value={ssidName}
            inputType="text"
            smallLabel={`${t(`ssidConfiguration.${ssidType}`)} WiFi Name`}
            prefixIcon={IconNames.Wifi}
            onChange={handleSsidNameChange}
            hasError={!!nameInputError}
            paragraph={nameInputError}
          />
          {currentRoute !== ROUTES.ssidConfiguration.guest && (
            <InputListItem
              value={ssidPassword}
              inputType="password"
              smallLabel={`${t(`ssidConfiguration.${ssidType}`)} WiFi Password`}
              prefixIcon={IconNames.Key}
              onChange={handleSsidPasswordChange}
              hasError={!!passwordInputError}
              paragraph={passwordInputError}
            />
          )}
        </div>
        <Button
          className={styles.setupCtaBtn}
          label={t('common.next')}
          onClick={handleNextClick}
          disabled={ctaDisabled}
        />
      </div>
    </div>
  );
};
export default memo(SsidSetup);
