import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeviceGroup, ZoneDevice, Person } from 'Consts/types';

import * as globalActions from 'State/actions';

import type { RootState, Action } from 'State/store';

import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

type UIDevicesState = {
  changeMenuOpen: boolean;
  headerMenuOpen: boolean;
  deviceMenuOpen: boolean;
  selectedDevices: ZoneDevice[] | null;
  selectedGroup: DeviceGroup | null;
  selectedEmployee: Person[];
  selectedQuarantineDomain: string | null;
  devicesAndGroupsMenuOpenTrigger: MenuOpenTriggerEventType | undefined;
};

const initialState: UIDevicesState = {
  changeMenuOpen: false,
  headerMenuOpen: false,
  deviceMenuOpen: false,
  selectedDevices: [],
  selectedGroup: null,
  selectedEmployee: [],
  selectedQuarantineDomain: null,
  devicesAndGroupsMenuOpenTrigger: undefined,
};

const slice = createSlice({
  name: 'devicesAndGroups',
  initialState,
  reducers: {
    selectDevices: (state, action: PayloadAction<ZoneDevice[] | null>) => {
      state.selectedDevices = action.payload;
    },
    selectGroup: (state, action: PayloadAction<DeviceGroup | null>) => {
      state.selectedGroup = action.payload;
    },
    setChangeMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.changeMenuOpen = action.payload;
    },
    setHeaderMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.headerMenuOpen = action.payload;
    },
    setDeviceMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.deviceMenuOpen = action.payload;
    },
    setDevicesAndGroupsMenuOpenTrigger: (
      state,
      action: PayloadAction<MenuOpenTriggerEventType | undefined>
    ) => {
      state.devicesAndGroupsMenuOpenTrigger = action.payload;
    },
    selectEmployee: (state, action: PayloadAction<Person[]>) => {
      state.selectedEmployee = action.payload;
    },
    selectQuarantineDomain: (state, action: PayloadAction<string | null>) => {
      state.selectedQuarantineDomain = action.payload;
    },
    closeMenus: (state) => {
      state.changeMenuOpen = false;
      state.headerMenuOpen = false;
      state.deviceMenuOpen = false;
    },
  },
});

const approveDevices = (): Action => {
  return async (dispatch, getState) => {
    const { selectedDevices } = getState().ui.devicesAndGroups;

    if (!selectedDevices?.length) {
      return;
    }

    const currentNetworkId = selectedDevices[0].networkId;

    dispatch(
      globalActions.zones.devices.approveDevices(
        selectedDevices.map(({ mac }) => mac),
        currentNetworkId
      )
    );
  };
};

const blockDevices = (): Action => {
  return async (dispatch, getState) => {
    const { selectedDevices } = getState().ui.devicesAndGroups;

    if (!selectedDevices?.length) {
      return;
    }

    dispatch(
      globalActions.zones.devices.blockDevices(
        selectedDevices.map(({ mac }) => mac)
      )
    );
  };
};

const unQuarantineDevices = (duration?: number): Action => {
  return async (dispatch, getState) => {
    const { selectedDevices, selectedQuarantineDomain } =
      getState().ui.devicesAndGroups;

    if (!selectedDevices?.length || !selectedQuarantineDomain) {
      return;
    }

    selectedDevices.forEach((device) => {
      dispatch(
        globalActions.zones.devices.unQuarantineDevice(
          device.mac,
          selectedQuarantineDomain,
          duration
        )
      );
    });
  };
};

export const selectors = {
  selectedDevices: (state: RootState) =>
    state.ui.devicesAndGroups.selectedDevices,
  selectedGroup: (state: RootState) => state.ui.devicesAndGroups.selectedGroup,
  changeMenuOpen: (state: RootState) =>
    state.ui.devicesAndGroups.changeMenuOpen,
  headerMenuOpen: (state: RootState) =>
    state.ui.devicesAndGroups.headerMenuOpen,
  deviceMenuOpen: (state: RootState) =>
    state.ui.devicesAndGroups.deviceMenuOpen,
  selectEmployee: (state: RootState) =>
    state.ui.devicesAndGroups.selectedEmployee,
  selectedQuarantineDomain: (state: RootState) =>
    state.ui.devicesAndGroups.selectedQuarantineDomain,
  devicesAndGroupsMenuOpenTrigger: (state: RootState) =>
    state.ui.devicesAndGroups.devicesAndGroupsMenuOpenTrigger,
};

export const actions = {
  ...slice.actions,
  approveDevices,
  blockDevices,
  unQuarantineDevices,
};

export default slice.reducer;
