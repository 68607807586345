import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'State/store';

export type DebugState = {
  debugEnabled: boolean;
};

const initialState: DebugState = {
  debugEnabled: false,
};

const slice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebug(state, action: PayloadAction<boolean>) {
      if (process.env.REACT_APP_APP_ENV === 'production') return;

      state.debugEnabled = action.payload;
    },
  },
});

export const selectors = (state: RootState) => state.debug.debugEnabled;

export const actions = slice.actions;

export default slice.reducer;
